<template>
    <div class="favorites__heading">
        <h1>Избранное</h1>
        <a v-if="(checkout && checkout.items && checkout.items.length > 0)"
           href="javascript:void(0);" title="Очистить список"
           class="favorites__heading-clear-button link-icon"
           @click="clean"
        >
            <SmallCloserIcon/>
            Очистить список
        </a>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    import SmallCloserIcon from "../icons/SmallCloserIcon";

    export default {
        name: "TheFavoritesHeading",
        components: {SmallCloserIcon},
        methods: {
            ...mapActions('favorites', {
                loadCheckout: 'loadCheckout',
                clean: 'clean'
            }),
        },
        computed: {
            ...mapState('favorites', {
                checkout: 'checkout'
            })
        }
    }
</script>

<style lang="scss">

</style>