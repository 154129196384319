<template>
  <div class="price-competitor-main" :ref="'main'">
    <div class="price-competitor-main__heading-title">
      Шина
    </div>

    <!--  Первый строчка в таблице -->
    <div class="price-competitor-main__heading-columns">
      <div class="price-competitor-main__heading-column">Цена на new.rezina43.ru</div>
      <div class="price-competitor-main__heading-column"
           v-for="competitor in competitors"
      >
        {{ competitor.name }}
      </div>
    </div>

    <!--  Первый столбик с названиями шин  -->
    <div class="price-competitor-main__heading-rows">
      <div class="price-competitor-main__heading-row"
           v-for="tyre in sortedTyres"
      >
        {{ tyre.name }}
      </div>
    </div>

    <!--  Таблица с шинами  -->
    <ul class="price-competitor-main__data">
      <li class="price-competitor-main__item"
          v-for="tyre in sortedTyres"
      >
        <!--  Первый столбик  -->
        <div class="price-competitor-main__item-column">
          <a :href="tyre.url"
             class="price-competitor-main__tyre-link"
             target="_blank"
          >
            <span class="price-competitor-main__tyre-link-price">
              Цена: {{ tyre.price }} ₽
            </span>
          </a>
        </div>

        <!--  Подбор порядка цен конкурентов в зависимости от порядка конкурентов в первой строке таблицы  -->
        <div class="price-competitor-main__item-column"
             v-for="competitor in competitors"
             :class="{
               '_green': getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).price > tyre.price,
               '_red': getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).price < tyre.price
             }"
        >
          <a :href="getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).url"
             class="price-competitor-main__tyre-link"
             target="_blank"
             v-if="getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id)"
          >
            <span class="price-competitor-main__tyre-link-price">
              Цена: {{ getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).price }} ₽
            </span>
            <span class="price-competitor-main__tyre-link-date">
              Дата обновления: {{ getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).updatedAt }}
            </span>
          </a>

          <a href="javascript:void(0);"
             class="price-competitor-main__stats-link"
             :data-tyre-id="tyre.id"
             :data-competitor-id="competitor.id"
             :data-tyre-name="tyre.name"
             v-if="getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id)"
             @click="openHistoryPricesModal"
          >
            Статистика
          </a>
        </div>
      </li>
    </ul>

    <ThePriceCompetitorHistoryPrices
        :chart-id="'history_prices_modal'"
        :title="modalTitle"
        :prices="historyPrices"
        :is-loading="isLoading"
        :ref="'historyPricesModal'"
        v-if="showHistoryPricesModal"
        @close="closeHistoryPricesModal"
    />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ThePriceCompetitorHistoryPrices from "./ThePriceCompetitorHistoryPrices";

export default {
  name: "ThePriceCompetitorMain",
  components: {ThePriceCompetitorHistoryPrices},
  data() {
    return {
      isLoading: false,
      showHistoryPricesModal: false,
      modalTitle: '',
      historyPricesData: [],
    }
  },
  computed: {
    ...mapState('priceCompetitorItems', [
        'competitors',
        'tyres',
    ]),
    ...mapState('priceCompetitorFilter', [
        'settings',
        'isLastPage',
    ]),
    sortedTyres() {
      this.tyres.forEach((tyre) => {
        tyre.competitorPrices = this.sortCompetitorPricesByCompetitors(tyre.competitorPrices)
      })
      return this.tyres;
    },
    historyPrices() {
      return this.historyPricesData;
    },
  },
  methods: {
    ...mapMutations('priceCompetitorFilter', [
        'setPage'
    ]),
    ...mapActions('priceCompetitorItems', [
        'fetchTyres',
        'debounceFetchTyres',
        'fetchHistoryPrices',
    ]),
    sortCompetitorPricesByCompetitors(competitorPrices) {
      // Формируем вспомогательный массив из `id` первого массива.
      const order = this.competitors.map(item => item.id);

      // Сортируем второй массив согласно индексам вспомогательного.
      competitorPrices.sort((a, b) => order.indexOf(a.competitorId) - order.indexOf(b.competitorId));

      return competitorPrices;
    },
    // Получаем цену поставщика по id поставщика.
    getTyreCompetitorPriceObjByCompetitorId(tyreId, competitorId) {
      const priceObj = this.tyres
          .find((item) => item.id === tyreId).competitorPrices
          .find((price) => price.competitorId === competitorId);

      return priceObj ? priceObj : false;
    },
    autoPagination() {
      window.addEventListener('scroll', (event) => {
        const bounds = this.$refs.main.getBoundingClientRect();
        if (bounds.bottom < window.innerHeight + window.innerHeight / 2) {
          if (!this.isLastPage) {
            this.debounceFetchTyres();
          }
        }
      });
    },
    openHistoryPricesModal(e) {
      this.isLoading = true;
      this.showHistoryPricesModal = true;
      this.modalTitle = e.target.dataset.tyreName;

      this.fetchHistoryPrices({
        'tyreId': parseInt(e.target.dataset.tyreId, 10),
        'competitorId': parseInt(e.target.dataset.competitorId, 10)
      })
        .then((r) => {
          this.isLoading = false;
          this.historyPricesData = r;
        })
    },
    closeHistoryPricesModal() {
      this.showHistoryPricesModal = false;
      this.modalTitle = '';
      this.historyPricesData = [];
    },
  },
  mounted() {
    this.autoPagination();
  }
}
</script>

<style scoped>

</style>