<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00823 12.9918L1 8L6 3L7.42824 4.42824L3.85882 7.99765L7.43411 11.5612L6.00823 12.9918Z" fill="#E12727"/>
    <rect x="15" y="9" width="12" height="2" transform="rotate(180 15 9)" fill="#E12727"/>
  </svg>

</template>

<script>
export default {
  name: "ArrowLeft"
}
</script>

<style lang="scss">

</style>