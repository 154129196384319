import axios from 'axios';

const topFilter = {
  fetchPossibleValues(url, params) {
    return axios.get(url, { params });
  },
  searchProducts(url, params) {
    return axios.get(url, { params })
      .then(function (response) {
        if (response.data.redirect) {
          window.location = response.data.redirect
        } else if (response.data.error) {
          console.log(response.data.error);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

export default topFilter;