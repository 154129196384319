<template>
    <div class="cart-product">
        <div class="cart-product__image-container">
            <img :src="productImage" :alt="productTitle" class="cart-product__image">
        </div>
        <div class="cart-product__container">
            <div class="cart-product__info">
                <div class="cart-product__name-container">
                    <div class="cart-product__name">
                        {{ `${producerTitle} ${modelTitle} ${productTitle}` }}
                    </div>
                    <!--TODO Тут не понятно, что нужно-->
                    <!--<div class="cart-product__description">
                        Литой, Черный
                    </div>-->
                    <div class="cart-product__price-container">
                        <div class="cart-product__price-current">
                            <span class="cart-product__price">{{ formatPrice(item.item_price) }}</span>
                            <span class="cart-product__postfix">₽</span>
                            x<span class="cart-product__count">{{ quantity }}</span>
                        </div>
                        <div class="cart-product__price-old" v-if="priceOld > 0">
                            <span class="cart-product__price">{{ formatPrice(priceOld) }} ₽</span>
                            <span class="cart-product__postfix"></span>
                        </div>
                    </div>
                    <!--
                    <div class="cart-product__availability-container"
                         :style="{color: availResult.color, borderBottom: '1px dashed ' + availResult.color}">
                        {{ availResult.text }}
                    </div>
                    -->
                    <div class="cart-product__availability-container"
                         :style="{color: availResult.color}">
                        {{ availResult.text }}
                    </div>
                </div>
                <div class="cart-product__interactive-container">
                    <div class="cart-product__interactive">
                        <div class="cart-product__interaction cart-product__interaction_large">
                            <div class="cart-product__count-container">
                                <button class="cart-product__interaction-button cart-product__interaction-button_minus"
                                        @click="decrease">
                                    <MinusIcon/>
                                </button>
                                <input type="number" id="product-count" v-model.number="quantity"
                                       class="cart-product__interaction-count" @input="setQuantity">
                                <button class="cart-product__interaction-button cart-product__interaction-button_plus"
                                        @click="increase">
                                    <PlusIcon/>
                                </button>
                            </div>
                            <div class="cart-product__sum-container">
                                <span class="cart-product__sum">{{ formatPrice(resultPrice) }} </span>
                                <span class="cart-product__sum-postfix">₽</span>
                            </div>
                        </div>
                        <ul class="cart-product__button-list">
                            <li class="cart-product__button-item">
                                <button class="cart-product__button-icon">
                                    <CartFavouritesIcon/>
                                </button>
                            </li>
                            <li class="cart-product__button-item">
                                <button class="cart-product__button-icon" @click="remove">
                                    <CartCloserIcon/>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!--TODO Вернуть функционирующим-->
                    <!--<div class="cart-product__bonus-container">
                        <span class="cart-product__bonus bonus bonus_small">
                            prize+223
                        </span>
                    </div>-->
                </div>
            </div>
            <div class="cart-product__interaction cart-product__interaction_small">
                <div class="cart-product__count-container">
                    <button class="cart-product__interaction-button cart-product__interaction-button_minus"
                            @click="decrease">
                        <MinusIcon/>
                    </button>
                    <input type="number" v-model.number="quantity"
                           class="cart-product__interaction-count" @input="setQuantity">
                    <button class="cart-product__interaction-button cart-product__interaction-button_plus"
                            @click="increase">
                        <PlusIcon/>
                    </button>
                </div>
                <div class="cart-product__sum-container">
                    <span class="cart-product__sum">{{ formatPrice(resultPrice) }} </span>
                    <span class="cart-product__sum-postfix">₽</span>
                </div>
            </div>
            <!--TODO Вернуть функционирующим-->
            <!--<ul class="cart-product__actions">
                <li class="cart-product__action-container">
                    <div class="cart-product__action">
                        <div class="cart-product__action-name-container">
                            <div class="cart-product__action-icon">
                                gift
                            </div>
                            <div class="cart-product__action-name">
                                Шиномонтаж в подарок
                            </div>
                        </div>
                        <div class="cart-product__condition">
                            Бесплатно
                        </div>
                    </div>
                </li>
            </ul>-->
        </div>
    </div>
</template>

<script>
  import MinusIcon from "../icons/MinusIcon";
  import PlusIcon from "../icons/PlusIcon";
  import CartFavouritesIcon from "../icons/CartFavouritesIcon";
  import CartCloserIcon from "../icons/CartCloserIcon";

  export default {
    name: "CartPageItem",
    components: {CartCloserIcon, CartFavouritesIcon, PlusIcon, MinusIcon},
    props: {
      item: {
        type: Object
      }
    },
    data() {
      return {
        quantity: this.item.quantity,
        model: this.item.buyable.model ?? null,
        producer: this.model ? this.model.producer ?? null : null,
        product: this.item.buyable,
        moreCount: 20
      }
    },
    methods: {
      increase() {
        this.$emit('increase', {
          key: this.item.key,
          amount: 1
        });
      },
      decrease() {
        this.$emit('decrease', {
          key: this.item.key,
          amount: 1
        });
      },
      setQuantity() {
        let quantity = parseInt(this.quantity, 10);
        if((quantity ^ 0) !== quantity) {
          this.quantity = 1;
          quantity = 1;
        }

        this.$emit('setQuantity', {
          key: this.item.key,
          quantity: quantity
        });
      },
      remove() {
        this.$emit('remove', {
          key: this.item.key
        });
      },
      updateQuantity() {
        this.quantity = this.item.quantity;
      },
      formatPrice(rawValue) {
        return this.$root.formatPrice(rawValue);
      },
      createImagePath(fileName) {
        let imageDir = fileName.substring(0, 2);
        imageDir = 'uploads/' + imageDir + '/';
        return imageDir + fileName;
      }
    },
    computed: {
      resultPrice: function () {
        return (this.item.item_price * this.item.quantity);
      },
      availResult: function () {
        const moreCount = this.moreCount;
        const quantity = this.product.quantity;
        const availType = this.product.availType;

        let text = '';
        let color = '';

        switch (availType) {
          case 10:
            if (quantity <= 0) {
              text = 'Под заказ';
              color = '#798297';
            } else if (quantity > 0 && quantity <= 4) {
              text = `В наличии ${quantity} шт.`;
              color = '#39AC26';
            } else {
              text = `В наличии более 4 шт.`;
              color = '#39AC26';
            }
            break;
          case 20:
            text = 'Под заказ';
            color = '#798297';
            break;
          case 30:
            text = 'Недоступно';
            color = '#798297';
            break;
        }

        return {text: text, color: color}
      },
      priceOld: function () {
        return (this.product.oldPrice ? this.product.oldPrice.value : 0)
      },
      productTitle() {
        return this.product.title ?? this.product.name ?? '';
      },
      producerTitle() {
        return this.producer ? this.producer.title : '';
      },
      modelTitle() {
        return this.model ? this.model.title : '';
      },
      productImage() {
        if (this.product.hasOwnProperty('imageFile')) {
          return this.product.imageFile ? this.createImagePath(this.product.imageFile) : '/static/images/base/noimage-product.jpg';
        }

        if (this.product.hasOwnProperty('model')) {
          return this.product.model.image ? this.createImagePath(this.product.model.image) : '/static/images/base/noimage.jpg';
        }

        return '/static/images/base/noimage-product.jpg';
      }
    },
    watch: {
      item: {
        deep: true,
        handler() {
          this.updateQuantity();
        }
      }
    }
  }
</script>

<style lang="scss">

</style>