import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import {singleBind} from "../../components/dynamic/bind";

function toggleToFavorites(e) {
    const button = this;

    if (!button.dataset.selected) {
        button.classList.add('to-favorite_selected');

        window.goalManager.proceedYandexGoal('click_izbranoe');
        window.goalManager.proceedVkGoal('click_izbranoe#' +parseInt(button.dataset.price)  + '|' + parseInt(button.dataset.id));

        document.dispatchEvent(new CustomEvent('favoritesAdd', {
            detail: {
                chosen: button.dataset.chosen,
                id: parseInt(button.dataset.id),
            }
        }));
    } else {
        button.classList.remove('to-favorite_selected');

        let removingItem = document.querySelector(`[data-selected="${button.dataset.selected}"]`);
        if (removingItem.closest('.favorites__item')) {
            removingItem.closest('.favorites__item').remove();
        }

        document.dispatchEvent(new CustomEvent('favoritesRemove', {
            detail: {
                key: button.dataset.selected
            }
        }));
    }
}

const init = () => {
    document.querySelectorAll('[data-favorites-toggle]').forEach((link) => {
        singleBind(link, 'initialized', 'click', toggleToFavorites);
    });
};

onDomReady(init);
onDomChanges(init);