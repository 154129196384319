import Vue from 'vue';
import CartService from "../../services/cart.service";

const cartModule = {
  namespaced: true,
  state: {
    loading: false,
    checkout: null,
    successAddMessage: 'Товар успешно добавлен в корзину'
  },
  getters: {
    total(state) {
      let total = 0;
      if (state.checkout) {
        total = parseFloat(state.checkout.total);
      }
      return total;
    },
    count(state) {
      let count = 0;
      if (state.checkout) {
        count = state.checkout.items.length;
      }
      return count;
    }
  },
  actions: {
    async add({ state, dispatch, commit }, { buyable, id, quantity }) {
      try {
        const result = await CartService.add(buyable, id, quantity);
        dispatch('fetchCheckout');
        dispatch('notification/showSuccessNotification', state.successAddMessage, {root:true});
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async increase({ state, dispatch, commit }, { key, amount }) {
      try {
        const result = await CartService.increase(key, amount);
        dispatch('fetchCheckout');
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async decrease({ state, dispatch, commit }, { key, amount }) {
      try {
        const result = await CartService.decrease(key, amount);
        dispatch('fetchCheckout');
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async remove({ state, dispatch, commit }, { key }) {
      try {
        const result = await CartService.remove(key);
        dispatch('fetchCheckout');
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async setQuantity({ state, dispatch, commit }, { key, quantity }) {
      try {
        const result = await CartService.setQuantity(key, quantity);
        dispatch('fetchCheckout');
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async loadCheckout({ state, dispatch, commit }) {
      if (!state.checkout && !state.loading) {
        commit('setLoading', true);
        await dispatch('fetchCheckout')
        commit('setLoading', false);
      }
    },
    async fetchCheckout({ commit }) {
      try {
        const result = await CartService.fetchCheckout();
        commit('setCheckout', result.data);
      } catch (e) {
        console.log(e);
      }
    },
    notifyError({ state, dispatch, commit }, error) {
      const errorMessage = error.response.data.detail ? error.response.data.detail : error.response.data.message;
      dispatch('notification/showErrorNotification', errorMessage, {root:true});
    },
    orderGoals({ state }) {
      let items = state.checkout.items;
      let offersId = [];

      items.forEach((item) => {
        let product = item.buyable.product;
        let offerId = product.id;
        offersId.push(offerId)
      })

      window.goalManager.proceedVkGoal('submit_shopping_cart_success#' + this.total + '/' + offersId);
    }
  },
  mutations: {
    setCheckout(state, data) {
      Vue.set(state, 'checkout', data);
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  },
};

export default cartModule;