class filterFieldClass {
  currentValue = [];
  possibleValues = [];
  currentPossibleValues = [];

  constructor(config) {
    Object.entries(config).forEach(item => this[item[0]] = item[1]);
  }
}

export default filterFieldClass;