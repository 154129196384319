import topFilterAutoWheelService from '../../../services/topFilter/topFilterAutoWheel.service'
import topFilterModule from './topFilter.module';
import filterFieldClass from "../../../classes/filterFieldClass";

// Делаем копию модуля с общей функциональностью
const TopFilterAutoWheelModule = Object.assign({}, topFilterModule);

const fields = {
  types: new filterFieldClass({
    label: 'Тип',
    type: 'checkboxes',
    requestFieldName: 'types',
    orderFill: 5,
  }),
  diameters: new filterFieldClass({
    label: 'Диаметр',
    type: 'select',
    requestFieldName: 'diameter',
    currentValue: 'all',
    orderFill: 4,
  }),
  boltCountPcd: new filterFieldClass({
    label: 'PCD',
    type: 'select',
    requestFieldName: 'boltCountPcd',
    currentValue: 'all',
    orderFill: 3,
  }),
  dia: new filterFieldClass({
    label: 'Ступица (DIA)',
    type: 'select',
    requestFieldName: 'dia',
    currentValue: 'all',
    orderFill: 2,
  }),
  et: new filterFieldClass({
    label: 'Вылет (ET)',
    type: 'select',
    requestFieldName: 'et',
    currentValue: 'all',
    orderFill: 1,
  }),
  colors: new filterFieldClass({
    label: 'Цвет',
    type: 'slug-select',
    requestFieldName: 'color',
    currentValue: 'all',
    orderFill: 0,
  })
};

const state = {
  // Назначаем сервис для апи
  service: topFilterAutoWheelService,
  // Передаем название модуля связанного сайдбара
  sidebarModuleName: 'sidebarAutoWheelModule',
  paramInfo: fields
};

TopFilterAutoWheelModule.state = {...TopFilterAutoWheelModule.state, ...state};

export default TopFilterAutoWheelModule;