<template>
  <div class="cart-receiving">
    <div class="cart-receiving__title cart-block__head">
      <div class="cart-block__num">
        2
      </div>
      <div class="cart-block__title">
        Способ получения
      </div>
    </div>
    <div class="cart-receiving__tabs-container tabs-mini tabs-mini_gray">
      <ul class="cart-receiving__tabs">
        <li :class="{'_active': isPickup}">
          <a href="javascript:void(0);" title="Самовывоз" @click="setPickup">
            Самовывоз
          </a>
        </li>
        <li :class="{'_active': isDelivery}">
          <a href="javascript:void(0);" title="Доставка" @click="setDelivery">
            Доставка
          </a>
        </li>
      </ul>
    </div>
    <div class="cart-receiving__content-container">
      <div class="cart-receiving__content" v-if="isPickup">
        <TheCartComponentPickup/>
      </div>
      <div class="cart-receiving__content" v-if="isDelivery">
        <TheCartComponentDelivery/>
      </div>
    </div>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors";
import { TheMask } from "vue-the-mask";
import { mapGetters } from 'vuex';
import TheCartComponentPickup from "./TheCartComponentPickup";
import TheCartComponentDelivery from "./TheCartComponentDelivery";

export default {
  name: "TheCartReceiving",
  components: {TheCartComponentDelivery, TheCartComponentPickup, ItemErrors, TheMask},
  props: {},
  computed: {
    receiving: {
      get () {
        return this.$store.state.order.receiving
      },
      set (value) {
        this.$store.commit('order/setReceiving', value)
      }
    },
    ...mapGetters('order', {
      'isPickup': 'isPickup',
      'isDelivery': 'isDelivery'
    })
  },
  methods: {
    setPickup() {
      this.receiving = 10;
    },
    setDelivery() {
      this.receiving = 20;
    }
  }
}
</script>

<style lang="scss">

</style>