<template>
    <div class="cart-result">
        <div class="cart-result__top-container">
            <div class="cart-result__result-wrapper">
                <div class="cart-result__result-container">
            <span class="cart-result__result-text">
                Итого
                <span class="cart-result__result-count">
                    {{ productCounts }}
                </span>
                <span class="cart-result__result-items">
                    товара
                </span>
                на сумму
            </span>
                    <div class="cart-result__sum-container">
                    <span class="cart-result__sum">
                        {{ formatPrice(total) }}
                    </span>
                        <span class="cart-result__postfix">
                        ₽
                    </span>
                    </div>
                </div>
                <div class="cart-result__delivery">
                    <div class="cart-result__delivery-title">
                        Стоимость доставки
                    </div>
                    <div class="cart-result__delivery-text">
                        Рассчитывается менеджером
                    </div>
                </div>
            </div>
            <div class="cart-result__buttons-wrapper">
                <!--TODO Вернуть функционирующим-->
                <!--<a href="javascript:void(0)" class="cart-result__button button button_wide button_transparent button_gray">
                    Заказ в 1 клик
                </a>-->
                <!--TODO Вернуть функционирующим-->
<!--                <span class="cart-result__bonus bonus">-->
<!--                    &lt;!&ndash;TODO Как-то вставить svg&ndash;&gt;-->
<!--                &lt;!&ndash;{{ icon('prize-yellow')|raw }}&ndash;&gt;-->
<!--                +<span class="cart-result__bonus-sum">225</span> -->
<!--                <span class="cart-result__bonus-name">бонусов</span>-->
<!--                <span class="cart-result__bonus-info">-->
<!--                    &lt;!&ndash;TODO Как-то вставить svg&ndash;&gt;-->
<!--                    &lt;!&ndash;{{ source("_parts/icons/question-fill.svg") }}&ndash;&gt;-->
<!--                </span>-->
<!--                </span>-->
            </div>
        </div>
        <div class="cart-result__bottom-container">
            <!--TODO Вернуть функционирующим-->
            <!--<div class="cart-result__authorize-container">
                <a href="javascript:void(0)" class="cart-result__authorize">Авторизуйтесь</a>,
                чтобы получать бонусы
            </div>-->
            <a href="/modal-information-consultation" title="Помощь с заказом" class="cart-result__help link-icon link-icon_big" @click="openModal($event)">
                <QuestionIcon/> Помощь с заказом
            </a>
        </div>
    </div>
</template>

<script>

import QuestionIcon from "../icons/QuestionIcon";
import Modal from "../../../components/modal/modal";
export default {
  name: "TheCartResult",
  components: {QuestionIcon},
  props: {
    productCounts: Number,
    total: Number
  },
  methods: {
    formatPrice: function (price) {
      return this.$root.formatPrice(price);
    },
    openModal: (e) => {
      e.preventDefault();
      const link = e.target;
      const modal = new Modal(link, {
        closerText: '',
        onSubmit: () => {}
      });
      return false;
    }
  },
  computed: {

  }
}

</script>

<style lang="scss">

</style>