<template>
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.7 11.63L6.67 11.75C6.67 11.89 6.79 12 6.92 12L18 12V14L6.5 14C5.4 14 4.5 13.1 4.5 12C4.5 11.65 4.59 11.32 4.75 11.04L6.1 8.58996L2.5 2H0V0H3.77L4.71 2L6.1 4.57809L8.03 7.99996L14.55 8L16.3 4.40625L17.0013 2.99996H8V0.999961H17C18.1046 0.999961 19 1.89539 19 2.99996L16.3 8.97C15.96 9.59 15.3 10 14.55 10L7.6 9.99996L6.7 11.63ZM8 16.5C8 17.3284 7.32843 18 6.5 18C5.67157 18 5 17.3284 5 16.5C5 15.6716 5.67157 15 6.5 15C7.32843 15 8 15.6716 8 16.5ZM15.5 18C16.3284 18 17 17.3284 17 16.5C17 15.6716 16.3284 15 15.5 15C14.6716 15 14 15.6716 14 16.5C14 17.3284 14.6716 18 15.5 18Z" fill="#B6BCCA"/>
    </svg>
</template>

<script>
export default {
  name: "CartGrayIcon"
}
</script>