const topFilterModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    service: null, // REQUIRED OVERLAP
    paramInfo: {}, // REQUIRED OVERLAP
    sidebarModuleName: null,// REQUIRED OVERLAP
    isChain: false
  },
  getters: {
    /**
     * Перед тем, как отправлять данные на бэк, следует подобающе их формализовать
     * Эти данные могут быть нужны и для бокового фильтра, поэтому метод возвращает каллбэк с аргументом списка параметров
     */
    prepareParamsForRequest: () => paramInfo => {
      const params = {};

      Object.values(paramInfo).forEach((paramElement) => {
        if (['checkboxes'].includes(paramElement.type)) {

          // Отсеиваем отмеченные дисейбл. Да, они могут появиться
          // 1. Собираем текущие возможные значения
          const possibleValues = [];
          Object.values(paramElement.currentPossibleValues).forEach((elem) => {
            possibleValues.push(elem.value);
          });

          // 2. Отфильтровали выбранные, которые возможны в текущий момент
          const currentValue = paramElement.currentValue ? paramElement.currentValue : [];
          let selectedPossible = Array.from(currentValue);
          selectedPossible = selectedPossible.filter((value) => possibleValues.includes(value));

          // Считаем количество отмеченных тычек
          const possibleLength = Object.keys(paramElement.possibleValues).length;
          if (possibleLength === selectedPossible.length) {
            // Чистим, если выбраны вообще все существующие, ведь это значит нам не нужно задействовать этот параметр
            params[paramElement.requestFieldName] = [];
          } else {
            params[paramElement.requestFieldName] = selectedPossible;
          }
        } else {
          // Пока что тут попадают только селекты, помним об этом
          params[paramElement.requestFieldName] = paramElement.currentValue
        }
      });

      return params;
    },
    getFields: (state) => Object.entries(state.paramInfo),

    // Собрать поля типа select
    getSelectFields: (state, getters) => getters.getFields.filter(field => field[1].type === 'select'),

    // Собрать поля типа slug-select
    getSlugSelectFields: (state, getters) => getters.getFields.filter(field => field[1].type === 'slug-select'),

    // Собрать поля типа select, включая slug-select
    getSelectAndSlugSelectFields: (state, getters) => getters.getFields.filter(field => ['slug-select', 'select'].includes(field[1].type)),

    // Собрать все поля типа селект, исключить те, где меньше 2ух значений
    getCheckboxFields: (state, getters) => getters.getFields.filter(field => field[1].type === 'checkboxes' && Object.keys(field[1].possibleValues).length > 1),

    // Получаем список дисейбл значений
    getDisabledValues: (state, getters) => {
      // Будем хранить эти значения в этом объекте с разбивкой по названиям полей
      const disabledFields = {};

      // Перебираем поля. [0] - название поля, [1] - полное содержимое поля
      getters.getFields.forEach((field) => {

        // Запишем для этого поля все возможные значения
        const possible = Object.values(field[1].possibleValues);
        // И все доступные значения
        const currentPossible = Object.values(field[1].currentPossibleValues);

        // Я расскажу целую лекцию, если кто то спросит зачем тут эти переменные
        let values = [];
        let currentValues = [];

        // обработаем в зависимости от типа поля
        switch (field[1].type) {
          case 'select':
            // Значения селектов хранятся в простом виде, поэтому просто отфильтровываем значения, которые доступны
            disabledFields[field[0]] =  possible.filter(value => !currentPossible.includes(value));
            break;
          case 'checkboxes':
            // У чекбоксов значения - это объекты с полями name и value
            // Поэтому создаем массив из value у всех возможных значений
            values = possible.map(item => item.value);
            // И массив из value из всех доступных значений
            currentValues = currentPossible.map(item => item.value);
            // Отфильтровываем из всех возможных значений все доступные
            disabledFields[field[0]] = values.filter(value => !currentValues.includes(value));
            break;
          case 'slug-select':
            // То же самое, только slug вместо value
            values = possible.map(item => item.slug);
            currentValues = currentPossible.map(item => item.slug);
            disabledFields[field[0]] = values.filter(value => !currentValues.includes(value));
            break;
        }
      });

      return disabledFields;
    },
  },
  actions: {
    async initializationFilter({ state, rootState, dispatch, getters }) {
      // Если модуль еще не загружен и не осуществляется его загрузка
      if (!state.loaded && !state.loading ) {
        // Сперва стягиваем все возможные значения фильтра
        await dispatch('fetch');

        try {
          const filled = await dispatch('setParamsFromUrl');
          const isChain = rootState.parameters.isChain;

          if (filled && isChain) {
            // Если хоть одна тычка или селект задействованы, нужно отфильтровать доступные значения для текущего фильтра
            await dispatch('fetch', getters.prepareParamsForRequest(state.paramInfo));
          }
          // Если мы тут, значит не вышло исключения => мы на странице, где есть боковой фильтр
          // Этот фильтр в полной готовности, инициализируем боковой, а это уже совсем другая история...
          dispatch(`${state.sidebarModuleName}/initializationFilter`, {}, {root: true})
        } catch (e) {
          // А если URIError, значит мы на стартовой странице, где нет параметров в урлах
          if (e.name !== 'URIError') {
            console.log(e);
          }
        }
      }
    },

    /**
     * Если запрос с параметрами (params), то это фильтрация 'текущих возможных значений'
     * Иначе это запрос на 'все возможные значения' == 'текущие возможные значения' т.е. первая итерация
     */
    async fetch({ commit, state, dispatch }, params) {
      // Устанавливаем статус, что фильтр сейчас занят
      commit('setLoading', true);
      try {
        // Получаем список доступных параметров
        const response = await state.service.fetchPossibleValues(params);

        // Отправляем список параметров на заполнение
        await dispatch('fillPossibleValues', {
          possibleValues: response.data,
          isTotalPossibleValues: params === undefined
        });
        // Если все прошло без проблем то фильтр загружен и готов к новым свершениям
        commit('setLoaded', true);
      } catch (e) {
        console.log(e);
      }
      // Фильтр свободен
      commit('setLoading', false);
    },

    /**
     * Заполнение возможных параметров
     * possibleValues: возможные значения
     * isTotalPossibleValues: true - возможность менять все отображаемые значения, включая не активные
     */
    async fillPossibleValues({ state, commit }, {possibleValues, isTotalPossibleValues}) {
      // Работаем с копией paramInfo из модуля
      const newParamInfo = Object.assign({}, state.paramInfo);
      Object.keys(possibleValues).forEach((fieldName) => {
        // Только указанные в модуле параметры имеют право на существование
        if (newParamInfo[fieldName]){
          // Записываем те значения, которые можно выбрать т.е. активные
          newParamInfo[fieldName].currentPossibleValues = possibleValues[fieldName];

          if (isTotalPossibleValues) {
            // Записываем те значения, которые будут отображаться, как активные, так и не активные
            newParamInfo[fieldName].possibleValues = possibleValues[fieldName];
          }
        }
      });
      // Отправляем весь скоуп на перезапись
      commit('setParamInfo', newParamInfo);
    },

    /**
     * Установка значений из URL
     * getParamsFromUrl может выкинуть исключение URIError - отправляем тому, кто вызвал
     */
    async setParamsFromUrl({ state, commit, dispatch }) {
      const urlParams = await dispatch('getParamsFromUrl');

      // Если выбрана хоть одна тычка у нас будет urlParams
      if (urlParams) {
        // Будем работать с копией paramInfo из модуля
        const newParamInfo = Object.assign({}, state.paramInfo);
        // Переназначаем в копии текущие значения
        Object.keys(urlParams).forEach((key) => {
          if (newParamInfo[key]) {
            newParamInfo[key].currentValue = urlParams[key];
          }
        });
        // Отправляем весь скоуп на перезапись
        commit('setParamInfo', newParamInfo);
        return true;
      }

      return false;
    },

    /**
     * Получение значений из URL
     * Определяется объектом параметров имеющих очередность заполнения и тип инпута
     * Бросаем исключение URIError, если в урле нет параметров
     * Возращаем null если параметры не выбраны (все параметры = all)
     */
    async getParamsFromUrl({ state }, paramList) {
      paramList = paramList || state.paramInfo;
      // Тянем строку урла, делаем перевернутый массив
      const slugParams = window.location.pathname.split('/').reverse();

      // 4 параметра статичны для каталога например <домент>/catalog/auto/tyre
      // Если кроме их ни чего нет, то тянуть параметры не требуется
      if (slugParams.length < 5) {
        throw URIError('No have slug params');
      }

      const newParams = {};
      // Перебираем параметры paramInfo, указанные в модуле или переданные аргументом в метод
      Object.keys(paramList).forEach((key) => {
        const paramInfo = paramList[key];

        // В описании параметров, которые связаны с урлом, должен присутствовать orderFill
        // orderFill определит какой по счету аргумент урла от конца относится к этому параметру
        const order = paramInfo.orderFill;
        if (!Number.isInteger(order)) {
          // Параметры верхнего фильтра должны быть связаны с урлом, покидаем итерацию цикла
          return;
        }

        const paramValue = slugParams[order];
        if (paramValue === 'all') {
          // all - значит параметр не задействован, покидаем итерацию цикла
          return;
        }

        if (['select', 'slug-select'].includes(paramInfo.type)) {
          // Привязываем значение от урла к названию параметра, если это селект элемент
          newParams[key] = paramValue;
        } else if (['checkboxes'].includes(paramInfo.type)) {
          // Если это чекбоксный параметр, нам понаобится массив
          newParams[key] = [];
          // Множественный выбор разделяется дефисом, собираем все значения и пихаем в массив
          const values = paramValue.split('-');
          values.forEach((value) => {
            newParams[key].push(value);
          });
        }
      });

      return Object.keys(newParams).length === 0 ? null : newParams;
    },

    async filterValues({ state, dispatch, getters, rootState }) {
      //Запускать или нет в зависимости от состояния чекбокса
      const isChain = rootState.parameters.isChain;
      if (!state.loading && isChain) {
        await dispatch('fetch', getters.prepareParamsForRequest(state.paramInfo));
      }
    },

    async searchProducts({ commit, state, getters }) {
      commit('setLoading', true);
      try {
        await state.service.searchProducts(getters.prepareParamsForRequest(state.paramInfo));
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setParamInfo(state, newParamInfo) {
      // В целях оптимизации сюда отправляется полностью весь paramInfo, а не гоняем циклы на каждый параметр
      state.paramInfo = newParamInfo;
    }
  },
};

export default topFilterModule;