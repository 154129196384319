function checkProgress() {
    const inputValue = document.getElementsByName('rating');
    let prev = null;
    for (let i = 0; i < inputValue.length; i++) {
        inputValue[i].addEventListener('change', function() {
            if (this !== prev) {
                prev = this;
            }
            console.log(this.value)

        });
    }
}
checkProgress();
