<template>
  <TheStepsBaseContent class-name="steps-date-time">
    <template v-slot:title>
      Выберите дату и время
    </template>

    <template v-slot:content>
      <div class="steps-date-time__main">
        <div class="steps-date-time__calendar">
          <TheDatePicker :disabled-dates="getDisableDates" @changeMonth="onChangeMonth"/>
        </div>
        <ul class="steps-date-time__time-list">
          <li class="steps-date-time__item"
              v-for="(slot, key) in currentSlots"
              :class="{'_disabled' : !slot.freedom || slotTimeIsPast(slot.dateTime)}"
          >
            <input type="radio"
                   name="times"
                   :id="`slot-`+key"
                   :value="slot.verbose"
                   :checked="isCheckedSlot(slot)"
            >
            <label class="steps-date-time__item-link"
                   :for="`slot-`+key"
                   @click="setCurrentSlot(slot)"
            >
              {{ slot.verbose }}
            </label>
          </li>
        </ul>
      </div>

      <TheServiceResult class="steps-base-content__result"/>
    </template>
  </TheStepsBaseContent>
</template>

<script>
import TheServiceResult from "../TheServiceResult.vue";
import TheDatePicker from "../TheDatePicker.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {scrollToTarget} from "../../../../components/scroll/scroll";
import TheStepsBaseContent from "./TheStepsBaseContent.vue";

export default {
  name: "TheStepsDateTime",
  components: {TheStepsBaseContent, TheDatePicker, TheServiceResult},
  data() {
    return {
      now: 0,
      disableDatesLoading: false
    }
  },
  computed: {
    ...mapState('schedule', [
        'loading',
        'monthDate',
        'disableDates',
        'selectedServiceCenterId'
    ]),
    ...mapGetters('schedule', {
      selectedServiceCenter: 'selectedServiceCenter',
      currentSlots: "currentSlots"
    }),
    getDisableDatesLoading() {
      return this.disableDatesLoading;
    },
    getDisableDates() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const data = {
        to: yesterday,
        days: [],
        dates: []
      };

      const serviceCenterDisableDates = this.disableDates[this.selectedServiceCenterId];
      if (serviceCenterDisableDates) {
        for (const [timestamp, value] of Object.entries(serviceCenterDisableDates)) {
            if (value) {
              data.dates.push(new Date(timestamp * 1000));
            }
        }
      }

      if (this.getDisableDatesLoading) {
        data.days = [0, 1, 2, 3, 4, 5, 6];
      }

      return data;
    }
  },
  methods: {
    ...mapActions('schedule', [
        'loadDisableDates',
    ]),
    ...mapMutations('schedule', [
        'setMonthDate',
    ]),
    setCurrentSlot(slot) {
      if(slot !== this.selectedSlot) {
        this.$store.commit('schedule/setSelectedSlot', slot);
      }
    },
    isCheckedSlot(slot) {
      if (slot === this.selectedSlot) {
        return true
      }

      return false;
    },
    slotTimeIsPast(slotDate) {
      const [dateValues, timeWithGmt] = slotDate.split('T');
      const [year, month, day] = dateValues.split('-'); // год, месяц, день
      const [time, gmt] = timeWithGmt.split('+'); // время, gmt
      const [hours, minutes, seconds] = time.split(':'); // часы, минуты, секунды
      const date = new Date(+year, month - 1, +day, +hours - 1, +minutes, +seconds).getTime();

      return this.now > date;
    },
    updateNow() {
      this.now = Date.now();
    },
    onChangeMonth(monthDate) {
      this.disableDatesLoading = true;
      this.setMonthDate(monthDate);
      this.loadDisableDates().then(() => {
        this.disableDatesLoading = false;
      });
    }
  },
  mounted() {
    this.updateNow();
    setInterval(this.updateNow.bind(this) , (1000 * 60));
  }
}
</script>

<style lang="scss">

</style>