import axios from 'axios';

import {cleanFormErrors, showErrors} from "../../components/forms/validation";
import {fillUrlWithFormData} from "../../components/forms/form-data";

/**
 * Привет! :)
 *
 * Повесить это добро на форму можно таким образом:
 *
 * {{ form(form, {'action': path('request:recall'), 'attr': {'data-ajax-form': 'true'}}) }}
 *
 * Ну и на обратном конце (по 'request:recall') должно ждать что-то подобное:
 *

 public function recall(Request $request)
 {
        $form = $this->createForm(RecallType::class);
        $form->handleRequest($request);

        if (!$form->isSubmitted()) {
            throw new BadRequestException();
        }

        if (!$form->isValid()) {
            $errors = [];
            foreach ($form as $child) {
                if (!$child->isValid()) {
                    foreach ($child->getErrors() as $error) {
                        $errors[$child->getName()][] = $error->getMessage();
                    }
                }
            }
            $data = [
                'state' => 'error',
                'errors' => [
                    $form->getName() => $errors
                ]
            ];
        } else {
            $data = [
                'state' => 'success'
            ];
        }
        return new JsonResponse($data);
    }

 */

function onSuccess(form) {
    const successSelector = form.dataset.successSelector;
    const success = successSelector ? document.querySelector(successSelector) : form;
    const successTrigger = form.dataset.successTrigger;
    const eventDetail = {'detail': {'form': form}};

    document.dispatchEvent(new CustomEvent('ajax-form:success', eventDetail));
    if (successTrigger) {
        document.dispatchEvent(new CustomEvent(successTrigger, eventDetail));
    }

    if (form.dataset.goal && window.goal) {
        let action = 'click';
        if(form.dataset.goalAction) {
            action = form.dataset.goalAction;
        }
        window.goal(form.dataset.goal, action);
    }

    success.classList.add('success');
    setTimeout(() => {
        form.reset();
        document.dispatchEvent(new CustomEvent('ajax-form:success-close', eventDetail));
        success.classList.remove('success');
    }, 3000);
}

async function makeRequest(form) {
    const formData = new FormData(form);

    const action = form.getAttribute('action') ? form.getAttribute('action') : window.location.href;
    const method = form.getAttribute('method') ? form.getAttribute('method').toLowerCase() : 'post';

    const options = {
        method: method
    };
    if (method === 'get') {
        options.url = fillUrlWithFormData(action, formData);
    } else {
        options.url = action;
        options.data = formData;
        options.headers = {'Content-Type': 'multipart/form-data' };
    }
    return axios(options);
}

function processResponse(form, response) {
    const formName = form.getAttribute('name');
    if (!response.data) {
        return;
    }
    cleanFormErrors(form, formName);
    if (response.data.errors) {
        showErrors(form, formName, response.data.errors[formName]);
    }
    if (response.data.state === 'success') {
        onSuccess(form);
        window.goalManager.proceedYandexGoal('success_order_allForm');
    }
}

async function onSubmit(e) {
    e.preventDefault();
    try {
        const form = e.target;
        const button = e.target.querySelector("button[type='submit']");
        button.disabled = true;
        const response = await makeRequest(this);
        processResponse(this, response);
        button.disabled = false;
    } catch (e) {
        console.log(e);
    }
}

document.querySelectorAll('[data-ajax-form]').forEach((form) => {
    form.addEventListener('submit', onSubmit)
});

document.addEventListener('DOMContentMutated', (e) => {
    document.querySelectorAll('[data-ajax-form]').forEach((form) => {
        console.log(13);
        form.addEventListener('submit', onSubmit);
    });
})