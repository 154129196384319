<template>
    <div class="type-switcher">
        <ul class="type-switcher__list">
            <li class="type-switcher__item" v-for="(typeItem, key) of types">
                <a href="javascript:void(0);" class="type-switcher__link"
                    :class="{ active: isCurrent(key)}"
                    v-on:click="setCurrent(key)">
                    <span class="type-switcher__link-icon">
                        <component :is="typeItem.icon"/>
                    </span>
                    <span class="type-switcher__link-text">
                        {{ typeItem.name }}
                    </span>
                </a>
            </li>
        </ul>

    </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from "vuex";
  import SwitchTyreIcon from '../../../icons/SwitchTyreIcon';
  import SwitchWheelIcon from '../../../icons/SwitchWheelIcon';
  import Preloader from '../../../Preloader/Preloader'

  export default {
    name: "TheTypeSwitcher",
    components: {Preloader, SwitchTyreIcon, SwitchWheelIcon},
    computed: {
      ...mapState('typeSwitcherModule', {
        types: 'types',
        loading: 'loading',
      }),
      ...mapGetters('typeSwitcherModule', {
        isCurrent: 'isCurrent',
        current: 'current',
      })
    },
    methods: {
      ...mapActions('typeSwitcherModule', {
        setCurrent: 'setCurrent'
      }),
    }
  }
</script>
