import axios from 'axios';

const FavoritesService = {
    async fetchCheckout() {
        return axios.get('/favorites/api/index');
    },
    async add(chosen, id) {
        return axios.post('/favorites/api/add', {
            chosen,
            id
        });
    },
    async remove(key) {
        return axios.post('/favorites/api/remove', {
            key
        });
    },
    async clean() {
        return axios.post('/favorites/api/clean');
    },
    async updateFavoritesList(params) {
        return axios.get('/favorites', { params })
            .then(({ data }) => {
                const page = document.createElement('div');
                page.innerHTML = data;

                const newData = page.querySelector('[data-favorites-list]');
                const currentData = document.querySelector('[data-favorites-list]');

                currentData.parentNode.replaceChild(newData, currentData);

                let event = new Event('DOMContentMutated');
                document.dispatchEvent(event);
            })
            .catch((e) => {
               console.log(e);
            });
    }
};

export default FavoritesService;