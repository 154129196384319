<template>
  <TheStepsBaseContent class-name="steps-contacts">
    <template v-slot:title>
      Укажите контактные данные
    </template>

    <template v-slot:content>
      <div class="steps-contacts__main">
        <TheServiceForm @submitSuccess="$emit('submitSuccess')"/>

        <div class="steps-contacts__description">
          Стоимость услуги определяется специалистом в зависимости от объема и срочности работы
        </div>
      </div>

      <TheServiceResult class="steps-base-content__result"/>
    </template>
  </TheStepsBaseContent>
</template>

<script>
import TheStepsBaseContent from "./TheStepsBaseContent.vue";
import TheServiceResult from "../TheServiceResult.vue";
import TheServiceForm from "../TheServiceForm.vue";

export default {
  name: "TheStepsContacts",
  components: {TheServiceForm, TheServiceResult, TheStepsBaseContent}
}
</script>

<style lang="scss">

</style>