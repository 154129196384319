import Inputmask from "inputmask/dist/inputmask";
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import Jax from "../../components/jax/jax";

const init = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";
    let inputmask = new Inputmask({
      mask: '+7 (999) 999-99-99',
      clearIncomplete: true,
      oncomplete: function () {
        if (this.dataset.leadUrl) {
          let jax = new Jax(this.dataset.leadUrl, 'POST');
          jax.send({
            phone: this.value
          });
        }
      }
    });
    inputmask.mask(input);
  });
}

onDomReady(init);
onDomChanges(init);