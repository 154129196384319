<template>
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#B6BCCA" d="M5 0h11v2H5zM0 0h3v2H0zM0 7h3v2H0zM0 14h3v2H0zM5 7h11v2H5zM5 14h11v2H5z"/>
    </svg>
</template>

<script>
    export default {
        name: "SortListIcon"
    }
</script>