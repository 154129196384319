function bind() {
    const modalWindow = document.querySelector('[data-modal-information]');
    if (!modalWindow) {
        return false;
    }
    const scrollElement = document.querySelector('.modal__bg');
    const windowHeight = window.innerHeight;
    const elements = document.querySelectorAll('[data-detected-scroll]');
    const triggers = document.querySelectorAll('[data-select-category]');
    scrollElement.addEventListener('scroll', () => {
        elements.forEach((element) => {
            if (element.classList.contains('_active')) {
                return;
            }
            const bounds = element.getBoundingClientRect();
            const ratio = element.dataset.ratio || 2;
            let animate = false;
            if ((bounds.top) <= (windowHeight / ratio) && (bounds.bottom) >= (windowHeight / ratio)) {
                animate = true;
            }
            if (animate) {
                Array.from(triggers).filter(trigger => trigger.classList.contains('_active')).forEach(trigger => trigger.classList.remove('_active'));
                Array.from(elements).filter(element => element.classList.contains('_active')).forEach(element => element.classList.remove('_active'));
                const trigger = Array.from(triggers).find(trigger => trigger.dataset.selectCategory === element.dataset.detectedScroll);
                if (!trigger) {
                    return;
                }
                trigger.classList.add('_active');
                element.classList.add('_active');
            }
        });
    })
}

document.addEventListener('DOMContentMutated', bind);

