<template>
    <form action="" class="selection-filter__form" @submit.prevent="search">
        <div class="selection-filter__form-main selection-filter__form-main_car">
            <ul class="selection-filter__fields">
                <li class="selection-filter__field">
                    <base-field-select
                        :field-id="'car-pick_manufacturer'"
                        :values="possibleValues.manufacturers"
                        :label="'Марка'"
                        :current-value="params.manufacturer"
                        v-model="params.manufacturer"
                        :default-value="{name: 'Выберите марку', value: ''}"
                        @input="updateCarModels"
                    />
                </li>
                <li class="selection-filter__field">
                    <base-field-select
                        :field-id="'car-pick_manufacturer'"
                        :values="possibleValues.carModels"
                        :label="'Модель'"
                        :current-value="params.carModel"
                        v-model="params.carModel"
                        :disabled="!params.manufacturer"
                        :default-value="{name: 'Выберите модель', value: ''}"
                        @input="updateGenerations"
                    />
                </li>
                <li class="selection-filter__field">
                    <base-field-select
                        :field-id="'car-pick_manufacturer'"
                        :values="possibleValues.generations"
                        :label="'Поколение'"
                        :current-value="params.generation"
                        v-model="params.generation"
                        :disabled="!params.carModel"
                        :default-value="{name: 'Выберите поколение', value: ''}"
                        @input="updateModifications"
                    />
                </li>
                <li class="selection-filter__field">
                    <base-field-select
                        :field-id="'car-pick_manufacturer'"
                        :values="possibleValues.modifications"
                        :label="'Модификация'"
                        :current-value="params.modification"
                        v-model="params.modification"
                        :disabled="!params.generation"
                        :default-value="{name: 'Выберите модификацию', value: ''}"
                        @input="updateModificationInfo"
                    />
                </li>
            </ul>

            <div class="selection-filter__button-wrapper">

                <button class="selection-filter__button button"
                        type="submit"
                        :disabled="!params.modification">
                    Подобрать
                </button>

                <a href="/catalog/auto/car-tyre" v-if="isModificationInfo"
                   class="button button_light selection-filter__button selection-filter__clear selection-filter__clear-mobile"
                > Сбросить фильтр </a>

            </div>
        </div>

        <div class="selection-filter__form-footer" v-if="isModificationInfo">
          <ul class="selection-filter__info">
                <li class="selection-filter__info-item" v-if="modificationInfo.car.pcd">
                    <div class="selection-filter__info-key">
                        PCD (разболтовка)
                    </div>
                    <div class="selection-filter__info-value">
                        {{ modificationInfo.car.holes }} x {{ modificationInfo.car.pcd }}
                    </div>
                </li>
                <li class="selection-filter__info-item" v-if="modificationInfo.car.dia">
                    <div class="selection-filter__info-key">
                        DIA (ступица)
                    </div>
                    <div class="selection-filter__info-value">
                        {{ modificationInfo.car.dia }} мм
                    </div>
                </li>
                <li class="selection-filter__info-item" v-if="modificationInfo.car.lockText">
                    <div class="selection-filter__info-key">
                        Болт
                    </div>
                    <div class="selection-filter__info-value">
                        {{ modificationInfo.car.lockText }}
                    </div>
                </li>
            </ul>

          <a href="/catalog/auto/car-tyre"
             class="button button_light selection-filter__clear selection-filter__clear-desktop selection-filter__car-pick"
          > Сбросить фильтр </a>

        </div>

        <preloader v-if="loading"/>
    </form>
</template>

<script>
  import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
  import Preloader from "../../../Preloader/Preloader";
  import BaseFieldSelect from "../parts/BaseFieldSelect";

  export default {
    name: "TheFilterCarPick",
    components: {
      Preloader,
      BaseFieldSelect
    },
    computed: {
      ...mapState('carPick', {
        loading: "loading",
        params: 'params',
        possibleValues: 'possibleValues',
        modificationInfo: 'modificationInfo'
      }),
      ...mapGetters('carPick', {
        isModificationInfo: 'isModificationInfo'
      })
    },
    methods: {
      ...mapActions('carPick', {
        loadManufacturers: 'loadManufacturers',
        updateCarModels: 'loadCarModels',
        updateGenerations: 'loadGenerations',
        updateModifications: 'loadModifications',
        // updateModificationInfo: 'loadModificationInfo',
        // search: 'search',
      }),
      async updateModificationInfo() {
        await this.$store.dispatch('carPick/loadModificationInfo');
        await this.$store.dispatch('carPick/search');
      }
    },
    mounted() {
      this.loadManufacturers();
    }
  }
</script>

<style lang="scss">

</style>