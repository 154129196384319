<template>
    <transition name="vue-preloader">
        <div class="vue-preloader" v-if="loading">
            <div class="vue-preloader__content" >
                <div class="vue-preloader__text">
                    Загрузка
                </div>
                <div class="vue-preloader__icon loader">

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: "ThePreloader",
  props: {
    loading: Boolean
  }
}
</script>

<style lang="scss">

</style>