import Vue from "vue";
import { map } from "./map";
import { FilterManagerPool } from "./manager";
import { pinia } from '../app.js';

export const initFilters = function () {
  let createdFieldSets = false;

  document.querySelectorAll('[data-filter-fieldset]').forEach((element) => {
    const filterKey = element.dataset.filterKey;
    if (!filterKey) {
      console.error('Fieldset`s data-filter-key is empty', element);
      return;
    }
    const fieldsetType = element.dataset.filterFieldsetType;
    if (!fieldsetType) {
      console.error('Fieldset`s data-filter-fieldset-type is empty', element);
      return;
    }

    const fieldset = element.dataset.filterFieldset;
    if (!fieldset) {
      console.error('Fieldset`s data-filter-fieldset is empty', element);
      return;
    }

    const fieldsetComponent = `${fieldsetType}Fieldset`;
    if (!map[fieldsetComponent]) {
      console.error(`Unknown fieldset component ${fieldsetComponent}`, element);
      return;
    }

    new Vue({
      el: element,
      pinia,
      render: h => h(map[fieldsetComponent], {
        props: {
          filterKey,
          fieldsetKey: fieldset
        },
      }),
    });
    for (const name in map) {
      Vue.component(name, map[name]);
    }
    createdFieldSets = true;
  });

  // Инициализация filterManager и установка данных
  if (createdFieldSets) {
    document.querySelectorAll('[data-filter-metadata]').forEach((script) => {
      const filterKey = script.dataset.filterKey;
      if (!filterKey) {
        console.error('Script`s data-filter-key is empty', script);
        return;
      }

      const filterManager = FilterManagerPool.getManager(filterKey, script.dataset);
      filterManager.readDataFromDocument(document, true);
    });
  }

  document.dispatchEvent(new CustomEvent('filter:after-init'));
}

// Загружаем данные в стор, обходим филдсеты и кастуем аппки