/*
только для мобильного разрешения:
* при переходе в личный кабинет снаружи должно открываться меню
* при переходе в любой раздел меню оно должно закрываться, открываться контент, но должно открываться по кнопке «назад», при этом скрывается контент страницы

реализуем следующим образом:

раздел «профиль» доступен по двум ссылкам
/personal
/personal/profile

отличаются тем, что /personal по умолчанию на мобилке открыто меню
/personal/profile — открывает контент
*/

document.querySelectorAll('[data-personal-toggle-link]').forEach(function (link) {
    link.addEventListener('click', function (e) {
        e.preventDefault();
        let target = document.querySelector(this.dataset.selector);
        target.classList.toggle(this.dataset.toggle);
    })
});

