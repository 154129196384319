const CurrentFilterModule = {
  namespaced: true,
  state: {

  },
  getters: {
    getCurrentSidebarName(state, getters, rootState) {
      if (rootState['sidebarAutoTyreModule'] && rootState['sidebarAutoTyreModule'].loaded) {
        return 'sidebarAutoTyreModule';
      } else if (rootState['sidebarAutoWheelModule'] && rootState['sidebarAutoWheelModule'].loaded) {
        return 'sidebarAutoWheelModule';
      }
      return null;
    },
    getCurrentSidebar(state, getters, rootState) {
      if (getters.getCurrentSidebarName && rootState[getters.getCurrentSidebarName].loaded) {
        return rootState[getters.getCurrentSidebarName];
      }
      return null;
    },
    getSortBy(state, getters) {
      return getters.getCurrentSidebar ? getters.getCurrentSidebar.sortBy : null;
    },
    getSortParams(state, getters) {
      return getters.getCurrentSidebar ? getters.getCurrentSidebar.sortParams : null;
    }
  },
  actions: {
    updateProductList({ commit, state, getters, dispatch }, value) {
      commit(`${getters.getCurrentSidebarName}/setSortBy`, value, {root: true});
      dispatch(`${getters.getCurrentSidebarName}/updateProductList`, '', {root: true});
    },
  }
}

export default CurrentFilterModule;