<template>
  <div class="form-field" :class="blockClasses">
    <label v-if="field.label" :class="elementClasses('label')">
      {{ field.label }}
    </label>

    <div class="custom-select custom-select_single"
         :class="{_open: isOpened, _disabled: field.disabled}"
         ref="customSelect"
    >
      <div class="custom-select__inner">
        <div class="custom-select__field-wrapper">
          <input type="text"
                 class="custom-select__field"
                 :value="searchInputValue"
                 @click="open"
                 :readonly="!isOpened"
                 v-show="!hasCheckedValues || isOpened"
                 @input="search"
                 ref="searchInput"
          >
          <input type="text"
                 class="custom-select__field"
                 :value="selectedChoiceLabel"
                 @click="open"
                 readonly
                 v-show="hasCheckedValues && !isOpened"
          >
          <div class="custom-select__arrow-icon custom-select__arrow-icon_absolute">
<!--            <ArrowDown/>-->
          </div>
        </div>

        <div class="custom-select__content-wrap">
          <ul class="custom-select__list">
            <li v-show="searchNoResult">
              <div class="custom-select__no-choices">
                Нет результатов
              </div>
            </li>
            <li class="custom-select__item" v-show="!this.searchValue && !noDefault">
              <input
                  type="radio"
                  :id="`no-choice-'${field.key}'`"
                  :value="defaultValue"
                  class="custom-select__input"
                  v-model="valueItem.value"
                  @click="close"
              >
              <label :for="`no-choice-'${field.key}'`" class="custom-select__label">
                <span class="custom-select__choice-name">
                  Все
                </span>
              </label>
            </li>
            <li v-for="(choice, index) in choices"
                class="custom-select__item"
                ref="radioChoice"
                :data-choice-label="choice.label"
            >
              <input
                  type="radio"
                  :id="id(choice, index)"
                  :value="choice.key"
                  class="custom-select__input"
                  v-model="valueItem.value"
                  @click="close"
              >
              <label :for="id(choice, index)" class="custom-select__label">
                <span class="custom-select__choice-name">
                  {{ choice.label }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div class="custom-select__preloader" v-show="isPreloaderVisible">
<!--        <Preloader/>-->
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  // import ArrowDown from "../../components/icons/ArrowDown.vue";
  // import CheckMarkBlack from "../../components/icons/CheckMarkBlack.vue";
  // import Preloader from "../../components/parts/common/Preloader.vue";

  export default {
    name: "SelectMultiField",
    components: {
      // Preloader,
      // CheckMarkBlack,
      // ArrowDown
    },
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        defaultValue: 0,
        isOpen: false,
        searchValue: '',
        searchNoResult: false
      }
    },
    computed: {
      hasCheckedValues() {
        return this.valueItem.value ? this.valueItem.value : false;
      },
      choices() {
        let choices = this.field.choices;
        const orderedChoices = this.field.data.orderedChoices;
        if (orderedChoices && orderedChoices.length) {
          choices.sort((a, b) => {
            if (!orderedChoices.includes(a.key) || !orderedChoices.includes(b.key)) {
              return;
            }
            return orderedChoices.indexOf(a.key) - orderedChoices.indexOf(b.key);
          });
        }
        return choices;
      },
      isOpened() {
        return this.isOpen;
      },
      searchInputValue() {
        if (!this.isOpen) {
          if (this.noDefault) {
            return this.selectedChoiceLabel;
          }
          return 'Все';
        } else {
          return this.searchValue;
        }
      },
      selectedChoiceLabel() {
        const selectedChoice = this.choices.find((choice) => choice.key === this.valueItem.value);

        if (selectedChoice) {
          return selectedChoice.label;
        } else if (this.noDefault && !selectedChoice) {
          return 'Не выбрано';
        } else {
          return 'Все';
        }
      },
      isFieldChoicesHasCurrentValue() {
        return this.field.choices.some((choice) => choice.key === this.valueItem.value);
      },
      getSearchValue() {
        return this.searchValue;
      },
      isPreloaderVisible() {
        return this.isLoading[this.field.key] === true;
      },
      noDefault() {
        return this.field.data.noDefault;
      }
    },
    watch: {
      isOpened: {
        handler(value) {
          if (value) {
            setTimeout(() => {
              this.$refs.searchInput.focus();
            }, 100);
          } else {
            this.searchValue = '';
          }
        }
      },
      getSearchValue: {
        handler(searchValue) {
          const radioChoiceItems = this.$refs.radioChoice;
          const formatedSearchValue = this.formatStringToSearch(searchValue);
          let validItems = [];

          radioChoiceItems.map((item) => {
            const formatedChoiceLabel = this.formatStringToSearch(item.dataset.choiceLabel);
            if (!formatedChoiceLabel.includes(formatedSearchValue)) {
              item.style.display = 'none';
            } else {
              item.style.display = 'block';
              validItems.push(item);
            }
          });

          this.searchNoResult = !validItems.length;
        }
      }
    },
    methods: {
      checkValue() {
        if (this.field.disabled) {
          this.valueItem.value = this.defaultValue;
          return;
        }
        if (!this.valueItem.value || !this.field.choices.length || !this.isFieldChoicesHasCurrentValue) {
          this.valueItem.value = this.defaultValue;
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
      search(e) {
        this.searchValue = e.target.value;
      },
      formatStringToSearch(string) {
        return string.toLowerCase().trim();
      }
    },
    mounted() {
      this.checkValue();

      document.addEventListener('click', (e) => {
        const el = this.$refs.customSelect;
        if (this.isOpen && !(el === e.target || el.contains(e.target))) {
          this.close();
        }
      });
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>