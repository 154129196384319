<template>
  <div class="sidebar-fieldset" :class="blockClasses" data-side-filter>
    <div class="sidebar-fieldset__heading">
      <a href="javascript:void(0);"
         class="sidebar-fieldset__closer"
         data-toggle-link
         data-selector="[data-side-filter]"
         data-toggle="_open"
      >
        <close-icon/>
      </a>
      <div class="sidebar-fieldset__title">
        Фильтры
      </div>
    </div>

    <div class="sidebar-fieldset__main">
      <div class="sidebar-fieldset__fields-wrap">
        <div class="sidebar-fieldset__fields" :class="elementClasses('fields')">
          <div :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]"
               v-for="field in fields"
          >
            <Field
                v-if="field.visible"
                :fieldKey="field.key"
                :filterKey="filterKey"
                @input="showSubmitButton"
            />
          </div>
        </div>

        <div class="sidebar-fieldset__buttons">
          <button href="javascript:void(0);"
                  class="side-filter__button side-filter__button_desktop button button_wide"
                  data-filter-submit
                  :data-filter-key="filterKey"
          >
            Применить
          </button>
          <a href="javascript:void(0);"
             class="side-filter__button side-filter__button_mobile button button_wide"
             data-toggle-link
             data-selector="[data-side-filter]"
             data-toggle="_open"
          >
            Применить
          </a>
          <a :href="currentUrl"
             title="Сбросить фильтр"
             class="side-filter__button button button_transparent button_wide"
          >
            Сбросить
          </a>
        </div>

        <button type="submit"
                class="sidebar-fieldset__ninja-button"
                ref="ninjaSubmitButton"
                data-filter-submit
                :data-filter-key="filterKey"
        >
          <span class="sidebar-fieldset__ninja-title">
            Подобрать
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldsetMixin} from "./fieldsetMixin";
  import Field from "../field/Field.vue";
  import Preloader from "../../components/Preloader/Preloader.vue";
  import CloseIcon from "../../components/icons/CloseIcon.vue";

  export default {
    name: "SidebarFieldset",
    components: {
      CloseIcon,
      Preloader,
      Field
    },
    mixins: [
      fieldsetMixin
    ],
    computed: {
      currentUrl() {
        return window.location.href;
      },
    },
    methods: {
      showSubmitButton(e) {
        const target = e.target;
        const parent = target.parentNode;
        const ninjaSubmitButton = this.$refs.ninjaSubmitButton;
        const ninjaParent = ninjaSubmitButton.parentElement;

        const ninjaParentTop = ninjaParent.getBoundingClientRect().top;
        const parentTop = parent.getBoundingClientRect().top;
        let diff = 0;

        if (ninjaParentTop < 0) {
          diff = Math.abs(ninjaParentTop) + Math.abs(parentTop);
        } else {
          diff = Math.abs(parentTop) - Math.abs(ninjaParentTop);
        }

        const offset = (parent.clientHeight / 3) + diff;
        ninjaSubmitButton.style.top = `${offset}px`;
        ninjaSubmitButton.classList.add('_show');

        this.ninjaButtonEventListeners(ninjaSubmitButton);

        clearTimeout(this.hideNinjaSubmitButton);
        this.hideNinjaSubmitButton = setTimeout(() => {
          ninjaSubmitButton.classList.remove('_show');
        }, 6000);
      },
      ninjaButtonEventListeners(ninjaSubmitButton) {
        if (this.ninjaButtonInit) {
          return;
        }

        ninjaSubmitButton.addEventListener('click', () => {
          setTimeout(() => {
            ninjaSubmitButton.classList.remove('_show');
          }, 1000)
        });
        this.ninjaButtonInit = true;
      }
    }
  }
</script>

<style lang="scss">

</style>