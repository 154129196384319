<template>
  <div class="sort-field" :class="blockClasses">
    <div class="sort-field__icon" :class="elementClasses('icon')">
      <SortIcon/>
    </div>

    <select v-model="valueItem.value"
            :class="elementClasses('select')"
            @input="change"
    >
      <option v-for="choice in field.choices"
              :key="`${field.key}_${choice.key}`"
              :value="choice.key"
              :disabled="choice.disabled"
      >
        {{ choice.label }}
      </option>
    </select>
  </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  import SortIcon from "../../components/icons/SortIcon.vue";

  export default {
    name: "SelectField",
    components: {
      SortIcon
    },
    mixins: [
      fieldMixin
    ],
    methods: {
      checkValue() {
        if (!this.valueItem.value && this.field.choices.length) {
          this.valueItem.value = this.field.choices[0].key;
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      change() {
        if (window.innerWidth > 1279) {
          setTimeout(() => {
            this.manager.submit();
          }, 100);
        }
      }
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>