<template>
    <div class="record-for-service" :class="{ _loading: loading }">
        <Notifications/>
        <ThePreloader :loading="loading"/>
        <div class="record-for-service__wrapper">
            <div class="record-for-service__box record-for-service__record-for-services">
                <div class="record-for-service__head">
                    <div class="record-for-service__heading">
                        <span class="record-for-service__heading-number">
                            1
                        </span>
                        <span class="record-for-service__heading-title title">
                            Услуга
                        </span>
                    </div>
                </div>
                <div class="record-for-service__content">
                    <TheServices/>
                </div>
            </div>
            <div class="record-for-service__box record-for-service__record-for-service-point">
                <div class="record-for-service__head">
                    <div class="record-for-service__heading record-for-service__head-item">
                        <span class="record-for-service__heading-number">
                            2
                        </span>
                        <span class="record-for-service__heading-title title">
                            Сервисный центр
                        </span>
                    </div>
                    <!--TODO город-->
<!--                    <div class="record-for-service__link record-for-service__head-item">-->
<!--                        <a href="#" class="record-for-service__heading-link link-city">-->
<!--                            <span class="record-for-service__city-icon link-city__icon">-->
<!--                                <GeoIcon/>-->
<!--                            </span>-->
<!--                            <span class="link-city__name">-->
<!--                            Санкт-Петербург-->
<!--                        </span>-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
                <div class="record-for-service__content">
                    <TheServicePoint/>
                </div>
            </div>
            <div class="record-for-service__box record-for-service__contacts" id="record-for-service-contacts">
                <div class="record-for-service__head">
                    <div class="record-for-service__heading">
                        <span class="record-for-service__heading-number">
                          3
                        </span>
                        <span class="record-for-service__heading-title title">
                            Контактная информация
                        </span>
                    </div>
                </div>
                <div class="record-for-service__content">
                    <TheServiceForm/>
                </div>
            </div>
            <div class="record-for-service__result">
                <TheServiceResult/>
            </div>
            <div class="record-for-service__confirm">
                <div class="record-for-service-confirm">
                    <button class="record-for-service-confirm__button" @click="submit">
                        Записаться
                    </button>
                    <span class="policy record-for-service-confirm__policy">
                        Подтверждая запись вы соглашаетесь с&nbsp;<a href="javascript:void(0)" class="policy__link">политикой конфиденциальности</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
  import GeoIcon from "../icons/GeoIcon.vue";
  import TheServices from "./TheServices.vue";
  import TheServicePoint from "./TheServicePoint.vue";
  import TheServiceForm from "./TheServiceForm.vue";
  import TheServiceResult from "./TheServiceResult.vue";
  import Notifications from "../Notification/Notifications.vue";
  import ThePreloader from "../Preloader/ThePreloader.vue";

  export default {
    name: "TheScheduleUserService",
    components: {
      ThePreloader,
      Notifications,
      TheServiceResult,
      TheServiceForm,
      TheServices,
      GeoIcon,
      TheServicePoint
    },
    props: {
      currentId: Number,
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapState('schedule', [
        'loading',
        'currentServiceId'
      ])
    },
    watch: {

    },
    methods: {
      ...mapActions('schedule', {
        'init': 'init',
      }),
      ...mapMutations('schedule', [
          'setCurrentServiceId'
      ]),
      submit() {
        this.$root.$emit('submitRecord')
      }
    },
    beforeMount() {
      if(this.currentId) {
        this.setCurrentServiceId(this.currentId);
      }
    },
    mounted() {
      this.init();
    },
  }
</script>

<style lang="scss">

</style>