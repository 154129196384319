<template>
  <div :class="blockClasses">
    <div :class="elementClasses('fields')">
      <div :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]" v-for="field in fields">
        <Field v-if="field.visible" :fieldKey="field.key" :filterKey="filterKey"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldsetMixin} from "./fieldsetMixin";
  import Field from "../field/Field.vue";
  import { FilterManagerPool } from "../manager";

  export default {
    name: "DefaultFieldset",
    components: {Field},
    mixins: [
      fieldsetMixin
    ],
    methods: {
      // change() {
      //   if (this.fieldset.data.instantСhangeable) {
      //     this.manager.submit();
      //   }
      // }
    },
    mounted() {
      this.manager = FilterManagerPool.getManager(this.filterKey);
    }
  }
</script>

<style lang="scss">

</style>