import topFilterTruckWheelService from '../../../services/topFilter/topFilterTruckWheel.service'
import topFilterModule from './topFilter.module';
import filterFieldClass from "../../../classes/filterFieldClass";
import TopFilterTruckTyreModule from "./topFilterTruckTyre.module";

// Делаем копию модуля с общей функциональностью
const TopFilterTruckWheelModule = Object.assign({}, topFilterModule);

const fields = {
  diameters: new filterFieldClass({
    label: 'Диаметр',
    type: 'select',
    requestFieldName: 'diameter',
    currentValue: 'all',
    orderFill: 4,
  }),
  boltCountPcd: new filterFieldClass({
    label: 'PCD',
    type: 'select',
    requestFieldName: 'boltCountPcd',
    currentValue: 'all',
    orderFill: 3,
  }),
  dia: new filterFieldClass({
    label: 'Ступица (DIA)',
    type: 'select',
    requestFieldName: 'dia',
    currentValue: 'all',
    orderFill: 2,
  }),
  et: new filterFieldClass({
    label: 'Вылет (ET)',
    type: 'select',
    requestFieldName: 'et',
    currentValue: 'all',
    orderFill: 1,
  }),
  colors: new filterFieldClass({
    label: 'Цвет',
    type: 'slug-select',
    requestFieldName: 'color',
    currentValue: 'all',
    orderFill: 0,
  })
};

const state = {
  // Назначаем сервис для апи
  service: topFilterTruckWheelService,
  // Передаем название модуля связанного сайдбара
  sidebarModuleName: 'sidebarTruckWheelModule',
  paramInfo: fields
};

TopFilterTruckWheelModule.state = {...TopFilterTruckWheelModule.state, ...state};

export default TopFilterTruckWheelModule;