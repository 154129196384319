// import { mapState } from "vuex";

const SizeFilterModule = {
  namespaced: true,
  state: {
    loaded: false,
    loading: true,
    availSizes: {
      rims: {
        standard: [],
        compatible: []
      },
      tires: {
        standard: [],
        compatible: []
      }
    },
    selectedSizes: {
      rims: [],
      tires: []
    },
  },
  getters: {
    currentType: (state, getters, rootState) => rootState.typeSwitcherModule.current,

    modificationInfo: (state, getters, rootState) => rootState.carPick.modificationInfo,

    standardSizeList: (state, getters) => state.availSizes[getters.currentType].standard,

    compatibleSizeList: (state, getters) => state.availSizes[getters.currentType].compatible,

    currentSelected: (state, getters) => state.selectedSizes[getters.currentType],

    getAllSizes: (state, getters) => Array.from(getters.standardSizeList).concat(Array.from(getters.compatibleSizeList)),

    getSelectedSizes: (state, getters) => {
      if (getters.currentSelected.length) {
        return getters.getAllSizes.filter((size) => getters.currentSelected.includes(size.value));
      } else {
        return getters.getAllSizes;
      }
    },
  },
  actions: {
    // Инициализация запускается от переключателя шин и дисков в его собственной инициализации
    async init({state, rootState, dispatch, commit}) {
      if (!state.loading) {
        commit('setLoading', true);
      }
      // Берем информацию по модификации от фильтра подбора по авто и отправляем на сохранение в текущий модуль
      await dispatch('setAvailSizes', rootState.carPick.modificationInfo);
      await commit('setLoaded', true);

      //  Инициализируем мультисайдбар
      await dispatch('autoMultiSidebarModule/init', {}, {root: true});
      commit('setLoading', false)
    },
    async setSelected({ state, commit, getters, dispatch }, selected) {
      commit('setLoading', true);
      await commit('setSelectSizes', {
        type: getters.currentType,
        selected: selected
      });
      await dispatch('autoMultiSidebarModule/changeSizesEventHandler', null, {root: true});
      commit('setLoading', false);
    },

    async changeTypeEvent({dispatch, commit}) {
      commit('setLoading', true);
      await dispatch('autoMultiSidebarModule/changeTypeEvent', null, {root: true});
      commit('setLoading', false);
    },

    // Сохранение доступных размеров от модификации
    async setAvailSizes({state, commit}, modificationInfo) {
      // Тут мы будем складиваровать стандартные и совместимые размеры для шин и дисков перед отправкой в стейт
      const availSizes = {
        rims: {
          standard: [],
          compatible: []
        },
        tires: {
          standard: [],
          compatible: []
        }
      };

      // Помещаем копию названия, что бы не добавлять повторы. Да, можно было придумать что нибудь получше
      let alreadySize = [];

      console.log(modificationInfo);

      // Перебираем размеры для шин
      Object.values(modificationInfo.tires).forEach((tyreInfo) => {
        // Смотрим по обеим осям
        ['front', 'back'].forEach(axis => {
          // Обрабатываем, если по этой оси есть данные
          if (tyreInfo[axis]) {
            if (!tyreInfo[axis].diameter || !tyreInfo[axis].width || !tyreInfo[axis].height) {
              // Посылаем размер нахрен, если нет одного из этих параметров
              return;
            }

            // Определяем уникальный идентификатор, толкаясь от суммарного количества стандартных и совместимых размеров шин
            const uniqueValue = `tire-size-${availSizes.tires.standard.length + availSizes.tires.compatible.length}`;
            if (alreadySize.includes(uniqueValue)) {
              // Посылаем размер нахрен, если такой уже есть
              return;
            }

            // Помещаем идентификатор в список для проверки на повтор
            alreadySize.push(uniqueValue);

            // Конвертируем каждый размер в нечто подобное
            const tyreState = {
              diameter: tyreInfo[axis].diameter,
              width: tyreInfo[axis].width,
              height: tyreInfo[axis].height,
              name: tyreInfo[axis].name,
              value: uniqueValue
            };

            // Помещаем размер в список стандартных или совместимых размеров соответсвенно
            tyreInfo.standard ? availSizes.tires.standard.push(tyreState) : availSizes.tires.compatible.push(tyreState);
          }
        });
      });

      alreadySize = [];
      // Перебираем размеры для дисков
      Object.values(modificationInfo.rims).forEach((wheelInfo) => {
        // Смотрим по обеим осям
        ['front', 'back'].forEach(axis => {
          // Обрабатываем, если по этой оси есть данные
          if (wheelInfo[axis]) {
            const size = wheelInfo[axis];
            // TODO Утвердить список необходимых размеров
            if (!size.diameter || !size.dia || !size.width || !size.pcd || !size.offset || !size.holes) {
              // Посылаем размер нахрен, если нет одного из этих параметров
              return;
            }
            // Определяем уникальный идентификатор, толкаясь от суммарного количества стандартных и совместимых размеров дисков
            const uniqueValue = `wheel-size-${availSizes.rims.standard.length + availSizes.rims.compatible.length}`;

            if (alreadySize.includes(uniqueValue)) {
              // Посылаем размер нахрен, если такой уже есть
              return;
            }

            // Помещаем идентификатор в список для проверки на повтор
            alreadySize.push(uniqueValue);

            // Конвертируем каждый размер в нечто подобное
            const wheelState = {
              diameter: wheelInfo[axis].diameter,
              dia: wheelInfo[axis].dia,
              width: wheelInfo[axis].width,
              pcd: wheelInfo[axis].pcd,
              et: wheelInfo[axis].offset,
              name: wheelInfo[axis].name,
              boltCount: wheelInfo[axis].holes,
              value: uniqueValue
            };

            // Помещаем размер в список стандартных или совместимых размеров соответсвенно
            wheelInfo.standard ? availSizes.rims.standard.push(wheelState) : availSizes.rims.compatible.push(wheelState);
          }
        });
      });
      // Сохраняем этот список в стейт
      commit('setAvailSizes', availSizes);
    }
  },
  mutations: {
    setAvailSizes(state, availSizes) {
      state.availSizes = availSizes;
    },
    setSelectSizes(state, object) {
      state.selectedSizes[object.type] = object.selected;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  }
};

export default SizeFilterModule;