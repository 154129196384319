import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import {singleBind} from "../../components/dynamic/bind";
import _ from "lodash";

function addToCart(e) {
  const button = this;
  let quantity = 1;

  const block = button.closest('[data-cart-block]');
  if (block) {
    quantity = parseInt(block.querySelector('[data-cart-quantity]').value);
  }
  if (!quantity) {
    quantity = 1;
  }

  document.dispatchEvent(new CustomEvent('cartAdd', {
    detail: {
      buyable: button.dataset.buyable,
      id: parseInt(button.dataset.id),
      quantity: quantity,
      element: button
    }
  }));
}

const bindQuantityElements = () => {
  const cartBlocks = document.querySelectorAll('[data-cart-block]');
  cartBlocks.forEach((cartBlock) => {
    const quantity = cartBlock.querySelector('[data-cart-quantity]');
    const increaseButton = cartBlock.querySelector('[data-cart-quantity-increase]');
    const decreaseButton = cartBlock.querySelector('[data-cart-quantity-decrease]');

    quantity.addEventListener('input', _.debounce((e) => {
      const input = e.target;
      const value = parseInt(input.value);
      if (value < 1 || isNaN(value)) {
        input.value = 1;
      }
    }, 500));

    increaseButton.addEventListener('click', (e) => {
      const count = parseInt(quantity.value, 10);
      quantity.value = count + 1;
    });

    decreaseButton.addEventListener('click', (e) => {
      const count = parseInt(quantity.value, 10);
      if(count > 1) {
        quantity.value = count - 1;
      }
    });
  })
};

const init = () => {
  document.querySelectorAll('[data-cart-add]').forEach((link) => {
    singleBind(link, 'initialized', 'click', addToCart);
  });

  bindQuantityElements();
};

onDomReady(init);
onDomChanges(init);