import Chart from "chart.js/auto";
import axios from 'axios';

const chart = document.querySelector('#main-chart');
const chartQuantity = document.querySelector('#quantity-chart');
const chartTime = document.querySelector('#time-chart');
const chartWeek = document.querySelector('#week-chart');
const chartAvg = document.querySelector('#avg-chart');
const chartSeller = document.querySelector('#seller-chart');
const chartTimeLimit = document.querySelector('#time-limit-chart');

const COLORS = [
    '#4dc9f6',
    '#f67019',
    '#537bc4',
    '#f53794',
    '#ffcc00',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
];

function color(index) {
    return COLORS[index % COLORS.length];
}


function initChart(chartEl)
{
    const data = JSON.parse(chartEl.dataset.chartData);
    const labels = JSON.parse(chartEl.dataset.chartLabels);
    const datasets = [];
    let col = 1;
    for (const [key, value] of Object.entries(data)) {
        datasets.push({
            data : value,
            label : key,
            fill : false,
            borderColor: color(col)
        })
        col++;
    }
    new Chart(chartEl, {
        type : 'line',
        data : {
            labels : labels,
            datasets : datasets
        },
    });
}

if (chart && chartQuantity) {
    initChart(chart);
    initChart(chartQuantity);
}
if (chartTime) {
    initChart(chartTime);
}
if (chartWeek) {
    initChart(chartWeek);
}
if (chartAvg) {
    initChart(chartAvg);
}
if (chartSeller) {
    initChart(chartSeller);
}
if (chartTimeLimit) {
    initChart(chartTimeLimit);
}

function toggleClassForm(form, className) {
    form.classList.add(className);
    setTimeout(() => {
        form.classList.remove(className);
    }, 1000);
}

function initForms() {
    document.querySelectorAll('form[data-markup-form]').forEach((form) => {
        form.addEventListener('submit', async (e) => {
            e.preventDefault();

            const formData = new FormData(form);
            try {
                const response = await axios({
                    url: '/marketplace/dashbaord/markups/set/value',
                    method: 'post',
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
                toggleClassForm(form, '_success');
            } catch (e) {
                alert('Ошибка при сохранении!');
                toggleClassForm(form, '_error');
            }
        });
    });
}
initForms();

function initChartItem(chartEl) {
    if (chartEl.dataset.inited) {
        return false;
    }
    chartEl.dataset.inited = 'true';
    const data = JSON.parse(chartEl.dataset.chartData);
    const labels = JSON.parse(chartEl.dataset.chartLabels);
    const datasets = [];
    let col = 1;
    for (const [key, value] of Object.entries(data)) {
        datasets.push({
            data : value,
            label : key,
            fill : false,
            borderColor: color(col)
        })
        col++;
    }
    new Chart(chartEl, {
        type : 'line',
        data : {
            labels : labels,
            datasets : datasets
        },
        options: {
            interaction: {
                intersect: false,
                mode: 'index',
            },
        }
    });
}

function toggleChartLink(linkEl) {
    const chartWrapperEls = linkEl.parentElement.querySelectorAll('[data-chart-wrapper]');

    chartWrapperEls.forEach((chartWrapperEl) => {
        const chartEl = chartWrapperEl.querySelector('[data-chart-item]');

        chartWrapperEl.classList.toggle('_opened');
        if (chartWrapperEl.classList.contains('_opened')) {
            initChartItem(chartEl);
        }
    })
}

document.querySelectorAll('[data-popular-offer-chart-link]').forEach((linkEl) => {
    linkEl.addEventListener('click', () => {
        toggleChartLink(linkEl);
    })
})