<template>
    <div>
        <filter-main-layout
                filter-key="filter-main-wheel-truck"
                :select-fields="getSelectAndSlugSelectFields"
                :disabledValues="getDisabledValues"
                :checkboxes-fields="getCheckboxFields"
                :loading="loading"
                @change="filterValues"
                @submit="searchProducts"
                :reset-link="'/catalog/truck/wheel/all/all/all/all/all'"
        >
        </filter-main-layout>
    </div>
</template>

<script>
    import FilterMainLayout from './FilterMainLayout';
    import {mapActions, mapState, mapGetters} from "vuex";

    export default {
        name: "TheFilterMainWheelTruck",
        components: {FilterMainLayout},
        computed: {
          ...mapState('topParamTruckWheelModule', {
            paramInfo: 'paramInfo',
            loading: 'loading'
          }),
          ...mapGetters('topParamTruckWheelModule', {
            getSelectFields: 'getSelectFields',
            getSelectAndSlugSelectFields: 'getSelectAndSlugSelectFields',
            getCheckboxFields: 'getCheckboxFields',
            isCurrentPossible: 'isCurrentPossible',
            getDisabledValues: 'getDisabledValues',
          })
        },
        methods: {
          ...mapActions('topParamTruckWheelModule', {
            filterValues: 'filterValues',
            initializationFilter: 'initializationFilter',
            searchProducts: 'searchProducts'
          }),
        },
        mounted() {
          this.initializationFilter();
        }
    }
</script>

<style lang="scss">

</style>