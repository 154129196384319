<template>
    <a href="/cart" title="Перейти в корзину" class="personal-area__link">
        <span class="personal-area__icon">
            <CartGrayIcon/>
            <span class="personal-area__counter">
                {{ count }}
            </span>
        </span>
        <!-- навешиваем модификатор _sum, когда выводим сумму, а не наименование (корзина) -->
        <span class="personal-area__name personal-area__name_sum" v-if="total > 0">
            {{ total|price }} ₽
        </span>
        <span class="personal-area__name" v-else>
            Корзина
        </span>
    </a>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import CartGrayIcon from '../icons/CartGrayIcon.vue';

export default {
  name: "TheCartWidget",
  components: {
    CartGrayIcon
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout'
    })
  },
  computed: {
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'count'
    })
  },
  mounted() {
    this.loadCheckout();
  }
}
</script>

<style lang="scss">

</style>