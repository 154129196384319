<template>
  <form action="" class="price-competitor-filters" @submit.prevent>
    <div class="price-competitor-filters__top">
      <ul class="price-competitor-filters__fields">
        <li class="price-competitor-filters__field"
            v-for="(field, name) in this.fields"
        >
          <base-field-select
              v-if="name === 'width'"
              :field-id="name"
              :values="field"
              :label="'Ширина'"
              :current-value="settings.width"
              :default-value="{ name: 'Любая', value: null }"
              v-model="settings.width"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'height'"
              :field-id="name"
              :values="field"
              :label="'Высота'"
              :current-value="settings.height"
              :default-value="{ name: 'Любая', value: null }"
              v-model="settings.height"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'diameter'"
              :field-id="name"
              :values="field"
              :label="'Диаметр'"
              :current-value="settings.diameter"
              :default-value="{ name: 'Любая', value: null }"
              v-model="settings.diameter"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'producer'"
              :field-id="name"
              :values="field"
              :label="'Производитель'"
              :current-value="settings.producer"
              :default-value="{ name: 'Любой', value: null }"
              v-model="settings.producer"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'season'"
              :field-id="name"
              :values="field"
              :label="'Сезон'"
              :current-value="settings.season"
              :default-value="{ name: 'Любой', value: null }"
              v-model="settings.season"
              @input="updateTyres"
          />
        </li>
      </ul>
    </div>

    <div class="price-competitor-filters__bottom">
      <base-field-checkbox
          class="price-competitor-filters__low-prices"
          :input-id="'lowPrices'"
          :label="'Есть цены конкурентов ниже'"
          :value="settings.lowPrices"
          v-model="settings.lowPrices"
          @change="updateTyres"
      />

      <base-field-checkbox
          class="price-competitor-filters__available"
          :input-id="'available'"
          :label="'В наличии'"
          :value="settings.available"
          v-model="settings.available"
          @change="updateTyres"
      />
    </div>

  </form>
</template>

<script>
import BaseFieldSelect from "../catalog/filters/parts/BaseFieldSelect";
import BaseFieldCheckbox from "../catalog/filters/parts/BaseFieldCheckbox";
import BaseFieldInput from "../catalog/filters/parts/BaseFieldInput";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "ThePriceCompetitorFilters",
  components: {BaseFieldInput, BaseFieldCheckbox, BaseFieldSelect},
  data() {
    return {
    }
  },
  computed: {
    ...mapState('priceCompetitorFilter', [
        'settings',
        'fields'
    ]),
    seasonFieldValues() {
      return this.fields.season.map(item => item.id);
    },
  },
  methods: {
    ...mapMutations('priceCompetitorFilter', [
        'setPage',
        'setFalseIsLastPage'
    ]),
    ...mapActions('priceCompetitorItems', [
        'fetchTyres'
    ]),
    updateTyres() {
      this.setPage(1);
      this.setFalseIsLastPage();
      this.fetchTyres();
    },
  },
}
</script>

<style scoped>

</style>