<template>
    <div class="cart-come-back">
        <div class="cart-come-back__wrapper">
            <div class="cart-come-back__text">
                Уже покупали у нас?
            </div>
            <a href="javascript:void(0)" class="cart-come-back__button button button_transparent">
                Войти
            </a>
        </div>
        <a href="javascript:void(0)" type="button" class="cart-come-back__closer">
        <span class="cart-come-back__closer-icon">
            <SmallCloserIcon></SmallCloserIcon>
        </span>
        </a>
    </div>
</template>

<script>

import SmallCloserIcon from "../icons/SmallCloserIcon";
export default {
  name: "TheCartComeBack",
    components: {SmallCloserIcon},
    props: {

  },
  methods: {

  },
  computed: {

  }
}

</script>

<style lang="scss">

</style>