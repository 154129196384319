const Sidebar = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    service: null, // REQUIRED OVERLAP
    topFilterModuleName: null, // REQUIRED OVERLAP
    paramInfo: {}, // REQUIRED OVERLAP
    topFilterParams: {}, // Хранение данных с верхнего фильтра при наличии
    sortBy: 'popularity',
    sortParams: [
      {
        name: 'По популярности',
        value: 'popularity'
      },
      {
        name: 'По возрастанию цены',
        value: 'priceASC'
      },
      {
        name: 'По убыванию цены',
        value: 'priceDESC'
      },
    ]
  },

  getters: {
    isCurrentPossible: state => object => {
      const paramInfo = state.paramInfo[object.field];
      const currentPossible = paramInfo.currentPossibleValues;

      let possibleArray = [];
      switch (paramInfo.type) {
        case 'slug-select':
          Object.values(currentPossible).forEach( item => possibleArray.push(item.slug));
          break;
        case 'slug-checkboxes':
          Object.values(currentPossible).forEach( item => possibleArray.push(item.slug));
          break;
        case 'bool-checkboxes':
          Object.values(currentPossible).forEach( item => possibleArray.push(item.value));
          break;
        case 'checkboxes':
          Object.values(currentPossible).forEach( item => possibleArray.push(item.value));
          break;
        case 'select':
          possibleArray = Object.values(currentPossible);
          break;
      }

      return possibleArray ? possibleArray.includes(object.value) : false;
    },

    getFields: (state) => Object.entries(state.paramInfo),

    // getCheckboxFields: (state, getters) => getters.getFields.filter(field => field[1].type === 'checkboxes'),

    getDisabledValues: (state, getters) => {
      // Будем хранить эти значения в этом объекте с разбивкой по названиям полей
      const disabledFields = {};
      // Перебираем поля. [0] - название поля, [1] - полное содержимое поля
      getters.getFields.forEach((field) => {
        // Запишем для этого поля все возможные значения
        const possible = Object.values(field[1].possibleValues);
        // И все доступные значения
        const currentPossible = Object.values(field[1].currentPossibleValues);

        switch (field[1].type) {
          case 'checkboxes':
            disabledFields[field[0]] =  possible.filter(value => !currentPossible.includes(value));
            break;
          case 'slug-checkboxes':{
            let values = possible.map(item => item.slug);
            let currentValues = currentPossible.map(item => item.slug);
            disabledFields[field[0]] = values.filter(value => !currentValues.includes(value));
            break;
          }
          case 'bool-checkboxes': {
            let values = possible.map(item => item.value);
            let currentValues = currentPossible.map(item => item.value);
            disabledFields[field[0]] = values.filter(value => !currentValues.includes(value));
            break;
          }
        }
      });

      return disabledFields;
    },
  },


  actions: {
    /**
     * Заполнение и обработка в зависимости от того, какой фильтр работает в связке с сайдбаром
     */
    async initializationFilter ({ state, dispatch, rootState, commit }) {
      if (!state.loaded ) {
        commit('priceSliderModule/setSidebarName', state.name, {root: true});
        // Загружаем все возможные значения
        await dispatch('fetch');
        // В связке верхний фильтр по параметрам
        if (rootState[state.topFilterModuleName].loaded) {
          // Тянем параметры с верхнего фильтра (например производитель)
          await dispatch('setParamsFromTopFilter');
        }
        // Готовим список параметров для запроса на фильтрацию текущих значений
        const params = await dispatch('prepareParamsForRequest');

        //Запускать или нет в зависимости от состояния чекбокса
        const isChain = rootState.parameters.isChain;
        if (params && isChain) {
          // Фильтруем значения, если есть почему фильтровать
          await dispatch('fetch', params);
        }
        dispatch('updateProductList');
      }
    },

    /**
     * Если запрос с параметрами (params), то это фильтрация 'текущих возможных значений'
     * Иначе это запрос на 'все возможные значения' == 'текущие возможные значения' (isTotalPossibleValues)
     */
    async fetch({ commit, state, dispatch }, params) {
      // Устанавливаем статус, что фильтр сейчас занят
      if (!state.loading) {
        commit('setLoading', true);
      }

      try {
        // Получаем список доступных параметров
        const response = await state.service.fetchPossibleValues(params);

        // Отправляем список параметров на заполнение
        await dispatch('fillPossibleValues', {
          possibleValues: response.data,
          isTotalPossibleValues: params === undefined
        });

        // Если все прошло без проблем то фильтр загружен и готов к новым свершениям
        commit('setLoaded', true);
      } catch (e) {
        console.log(e);
      }

      commit('setLoading', false);
    },

    /**
     * Заполнение возможных параметров
     * possibleValues: возможные значения
     * isTotalPossibleValues: true - возможность менять все отображаемые значения, включая не активные
     */
    async fillPossibleValues({ state, commit, dispatch }, {possibleValues, isTotalPossibleValues}) {
      // Работаем с копией paramInfo из модуля
      const newParamInfo = Object.assign({}, state.paramInfo);
      Object.keys(possibleValues).forEach((fieldName) => {
        // Только указанные в модуле параметры имеют право на существование
        if (newParamInfo[fieldName]) {
          // Записываем те значения, которые можно выбрать т.е. активные
          newParamInfo[fieldName].currentPossibleValues = possibleValues[fieldName];

          if (isTotalPossibleValues) {
            // Записываем те значения, которые будут отображаться, как активные, так и не активные
            newParamInfo[fieldName].possibleValues = possibleValues[fieldName];
          }
        }
      });

      // Передача цен в компонент слайдера цены
      console.log(possibleValues.priceFrom, possibleValues.priceTo);
      if (possibleValues.priceFrom !== undefined && possibleValues.priceTo !== undefined ) {
        dispatch('priceSliderModule/setMinMax', {
          min: possibleValues.priceFrom,
          max: possibleValues.priceTo
        }, {root: true});
      }
      // Отправляем весь скоуп на перезапись
      commit('setParamInfo', newParamInfo);
    },

    /**
     * Если поиск по параметрам, то берутся параметры с URL
     * Записываются один раз и остаются до конца!
     *
     * Если есть общие поля (н-р 'producers') производится трансфер параметров
     * TODO: сейчас определятор "надо ли тащить параметры с урла" где то на верхних уровнях
     */
    async setParamsFromTopFilter({ state, commit, dispatch,  }) {
      try {
        // Хватаем параметры с урла
        const urlParams = await dispatch('getParamsFromUrl');
        if (urlParams) {
          // Если хоть одна тычка или селект задействованы, то сохраняем значения с верхнего фильтра в сайдбар
          await commit('setTopFilterParams', urlParams);
          // При совпадении полей верхнего фильтра и сайдабара, нужно осуществить трансфер значений
          await dispatch('transferTopToSidebar');
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },

    /**
     * Перенос параметров от верхнего фильтра в сайдбар, при совпадении полей по названию
     */
    async transferTopToSidebar({ state, commit, rootState }) {
      // Параметры, которые находятся в верхнем фильтре - пришли с урла
      const urlParams = state.topFilterParams;
      //  Работаем с копией параметров сайдабра
      const newParamInfo = Object.assign({}, state.paramInfo);
      let needRewrite = false;

      Object.keys(urlParams).forEach( (key) => {
        // Только совпадающие параметры по названию у сайдбара и верхнего фильтра идут дальше
        // Ну и если в верхнем фильтре нет значения all, ведь оно означает не участие в фильтрации
        if (!newParamInfo[key] || urlParams[key] === 'all') {
          //  Покидаем итерацию цикла
          return;
        }

        //TODO дописать все возможные случаи связок типов полей (сейчас только 'producers' (т.е. (slug-select => slug-checkboxes)))
        switch (rootState[state.topFilterModuleName].paramInfo[key].type) {
          case 'slug-select':
            if (newParamInfo[key].type === 'slug-checkboxes') {
              // Одиночное значение селекта переходит в одно из значений чекбоксов
              newParamInfo[key].currentValue = [urlParams[key]];
              needRewrite = true;
            }
        }
      });

      // Просто что бы лишний раз не перезаписывать
      if (needRewrite) {
        commit('setParamInfo', newParamInfo)
      }
    },

    async filterValues({ state, dispatch, rootState }) {
      //Запускать или нет в зависимости от состояния чекбокса
      const isChain = rootState.parameters.isChain;

      if (!state.loading && isChain) {
        const params = await dispatch('prepareParamsForRequest');
        // TODO Без параметров не обязательно делать запрос, это может работать быстрее
        await dispatch('fetch', params ? params : undefined);
      }
    },

    async prepareParamsForRequest({ state, rootState, rootGetters }) {
      // Итоговый список параметров будет туть
      const requestParams = {
        'sortBy': state.sortBy
      };
      // Для начала обработаем собственные параметры сайдабра
      Object.values(state.paramInfo).forEach((paramElement) => {
        if (!paramElement.possibleValues) {
          // Пока не знаю как это возможно но пусть будет
          console.log('Impossible #8345');
          return;
        }

        // Тут соберем текущие возможные значения, исключая дисейбл
        const possibleValues = [];

        // Тут соберем выбранные активные значения
        let selectedPossible = [];

        // Считаем количество всех возможных значений по тукущему параметру включая дисейбл
        const possibleLength = Object.keys(paramElement.possibleValues).length;

        // TODO ну не красиво же
        switch (paramElement.type) {
          case 'bool-checkboxes':
            // bool-checkboxes подразумевает 2 чекбокса для булева параметра, например: шипованность
            // Отсеиваем отмеченные дисейбл
            // 1. Собираем текущие возможные значения
            Object.values(paramElement.currentPossibleValues).forEach((elem) => {
              possibleValues.push(elem.value);
            });

            // 2. Отфильтровали выбранные, которые возможны в текущий момент
            selectedPossible = Array.from(paramElement.currentValue);
            selectedPossible = selectedPossible.filter((value) => possibleValues.includes(value));

            // Если выбрано все или ничего то этот параметр нам не пригодится, иначе отправляем первый (и единственный)
            if (selectedPossible.length === 1) {
              requestParams[paramElement.requestFieldName] = selectedPossible[0];
            }
            break;
          case 'slug-checkboxes':
            // Отсеиваем отмеченные дисейбл
            // 1. Собираем текущие возможные значения
            Object.values(paramElement.currentPossibleValues).forEach((elem) => {
              possibleValues.push(elem.slug);
            });

            // 2. Отфильтровали выбранные, которые возможны в текущий момент
            selectedPossible = Array.from(paramElement.currentValue);
            selectedPossible = selectedPossible.filter((value) => possibleValues.includes(value));

            // Записываем только если что то есть и это что то - не всё
            if (selectedPossible.length && possibleLength !== selectedPossible.length) {
              requestParams[paramElement.requestFieldName] = selectedPossible;
            }
            break;
          case 'checkboxes':
            // Отсеиваем отмеченные дисейбл
            // 1. Собираем текущие возможные значения
            Object.values(paramElement.currentPossibleValues).forEach((elem) => {
              possibleValues.push(elem.value);
            });

            // 2. Отфильтровали выбранные, которые возможны в текущий момент
            selectedPossible = Array.from(paramElement.currentValue);
            selectedPossible = selectedPossible.filter((value) => possibleValues.includes(value));

            // Записываем только если что то есть и это что то - не всё
            if (selectedPossible.length && possibleLength !== selectedPossible.length) {
              requestParams[paramElement.requestFieldName] = selectedPossible;
            }
            break;
          default:
            // Пока сюда попадают лишь селекты
            requestParams[paramElement.requestFieldName] = paramElement.currentValue;
        }
      });


      // Подхватываем текущие ценники в слайдере цены и записываем их, если они отличны от границ
      const priceFrom = rootGetters['priceSliderModule/getValueFrom'];
      const priceTo = rootGetters['priceSliderModule/getValueTo'];
      if (priceFrom !== rootGetters['priceSliderModule/getMin']) {
        requestParams.priceFrom = priceFrom;
      }
      if (priceTo !== rootGetters['priceSliderModule/getMax']) {
        requestParams.priceTo = priceTo;
      }

      // Обработка верхнего фильтра
      // Если верхний фильтр на странице есть, то это он запустил сайдбар, значит он загружен.
      if (rootState[state.topFilterModuleName].loaded) {
        /*
          Ранее мы сохранили значения верхнего фильтра в сайдбар отдельно, что бы последущие изменения
          верхнего фильтра больше не влияли на выборку. Но что бы задействовать оригинальную формализацию параметров
          верхнего фильтра, данные для него должны быть в том виде, которые он хранит у себя. Поэтому мы создаем копию
          и подставляем ранее сохраненные значения, исключая совпадающие с сайдбаром поля
         */
        // Собираем данные в этой переменной
        const topFilterParamInfo = {};
        // Переберем оригинальные поля верхнего фильтра
        Object.keys(rootState[state.topFilterModuleName].paramInfo).forEach( (key) => {
          // Берем только в том случае, если в сайдбаре такого поля нет (например не 'producers')
          // И в то же время, если изначально что то пришло из этого поля при загрузке
          if (state.paramInfo[key] === undefined && state.topFilterParams[key] !== undefined) {
            // Хватаем копию этого поля и записываем ранее сохраненное значение
            topFilterParamInfo[key] = Object.assign({}, rootState[state.topFilterModuleName].paramInfo[key]);
            topFilterParamInfo[key].currentValue = state.topFilterParams[key]
          }
        });
        // Если хоть что то перебралось с верхнего фильтра, то подготавливаем их и мержим параметры
        if (Object.keys(topFilterParamInfo).length !== 0) {
          // Используем подготовку запроса верхнего фильтра для параметров верхнего фильтра
          const topFilterParamRequest = await rootGetters[`${[state.topFilterModuleName]}/prepareParamsForRequest`](topFilterParamInfo);
          Object.assign(requestParams, topFilterParamRequest);
        }
      }

      // Обработка данных с фильтра подбора по авто
      // Если он на странице, то это он вызвал сайдбар, а значит он загружен
      if (rootState.sizesFilterModule.loaded) {
        requestParams.groups = rootGetters['sizesFilterModule/getSelectedSizes'];
      }

      // Возвращаем null если ни чего не заполненно
      return Object.keys(requestParams).length ? requestParams : null;
    },

    /**
     * Прокси подтягивания параметров из URL от верхнего фильтра
     */
    async getParamsFromUrl({ state, dispatch, rootState }) {
      const action = `${state.topFilterModuleName}/getParamsFromUrl`;
      // Передаем верхнему фильтру свои же данные, не помню почему
      const paramInfo = rootState[state.topFilterModuleName].paramInfo;
      return await dispatch(action, paramInfo, {root:true});
    },

    async updateProductList({ commit, state, dispatch }) {
      // commit('setLoading', true);
      try {
        const params = await dispatch('prepareParamsForRequest');
        await state.service.updateProductList(params);
      } catch (e) {
        console.log(e);
      }
      // commit('setLoading', false);
    },
    async setLoaded({ commit }, loaded) {
      await commit('setLoaded', loaded);
    },
    inputId: (field, id) => `auto-tyre-sidebar-${field}-${id}`,
    fieldId: field => `auto-tyre-sidebar-${field}`,
    showHui: () => console.log('hui'),
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    loadPossibleValues(state, possibleValues) {
      const newParamInfo = Object.assign({}, state.paramInfo);
      Object.keys(possibleValues).forEach((fieldName) => {
        if (!newParamInfo[fieldName]){
          return false;
        }
        newParamInfo[fieldName].possibleValues = possibleValues[fieldName];
      });

      state.paramInfo = newParamInfo;
    },
    setTopFilterParams(state, params) {
      state.topFilterParams = params;
    },
    setParamInfo(state, newParamInfo) {
      state.paramInfo = newParamInfo;
    },
    setIsActive(state, isActive) {
      state.isActive = isActive;
    },
    setSortBy(state, value) {
      state.sortBy = value;
    }
  }
};

export default Sidebar;