import axios from 'axios';

const PriceCompetitorService = {
  async fetchCompetitors() {
    const url = '/price_competitor/api/competitor_list';
    return axios.get(url, {});
  },
  async fetchTyres(params) {
    const url = '/price_competitor/api/tyre_list';
    return axios.post(url, params);
  },
  async fetchWidths() {
    const url = '/price_competitor/api/widths';
    return axios.get(url, {});
  },
  async fetchHeights() {
    const url = '/price_competitor/api/heights';
    return axios.get(url, {});
  },
  async fetchDiameters() {
    const url = '/price_competitor/api/diameters';
    return axios.get(url, {});
  },
  async fetchSeasons() {
    const url = '/price_competitor/api/seasons';
    return axios.get(url, {});
  },
  async fetchProducers() {
    const url = '/price_competitor/api/producers';
    return axios.get(url, {});
  },
  async fetchHistoryPrices(params) {
    const url = '/price_competitor/api/history_prices';
    return axios.post(url, params);
  },
};

export default PriceCompetitorService;