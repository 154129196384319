<template>
  <div class="custom-checkbox selection-filter__switcher">
    <input
        @change='onChange'
        :checked="isChain"
        type="checkbox" class="selection-filter__checkbox" id="parameters" name="parameters" value="1" checked>
    <label for="parameters">Сопоставление параметров</label>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "TheParametersCheckbox",
  computed: {
    ...mapState('parameters', {
      isChain: 'isChain'
    }),
  },
  methods: {
    ...mapActions('parameters', {
      init: 'init',
      change: 'change'
    }),
    onChange(e){
      this.change(!this.isChain);
    },
  },
  mounted(){
    this.init();
  }
}
</script>