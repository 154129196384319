<template>
    <div class="checkbox-list">
        <label v-if="label" class="checkbox-list__main-label">{{ label }}</label>
        <div class="checkbox-list__item" v-for="param in possibleValues">
            <input type="checkbox"
                   class="checkbox-list__item"
                   :id="uniqueId(inputId, valueAsString(param))"
                   :value="valueAsString(param)"
                   :disabled="checkDisabled(valueAsString(param))"
                   :checked="shouldBeChecked(valueAsString(param))"
                   @change="updateInput"
            >
            <label class="checkbox-list__label" :for="uniqueId(inputId, valueAsString(param))">
                {{ nameAsString(param) }}
                <span class="checkbox-list__icon">
                    <season-summer v-if="param.value === 'summer'"/>
                    <season-winter v-else-if="param.value === 'winter'"/>
                    <season-multi v-else-if="param.value === 'multi'"/>
                </span>
            </label>
        </div>
    </div>
</template>

<script>
  import SeasonSummer from "../../../icons/SeasonSummer";
  import SeasonWinter from "../../../icons/SeasonWinter";
  import SeasonMulti from "../../../icons/SeasonMulti";

  export default {
    name: "FieldCheckboxListSeasons",
    components: {SeasonMulti, SeasonWinter, SeasonSummer},
    props: {
      /*
        Все возможные значения для чекбоксов
        а) список строк
        б) список объектов с полями 'name' и 'value|slug'
      */
      possibleValues: {
        required: true
      },
      // Объект для v-model
      currentValues: {
        type: Array,
        required: true
      },
      // Склеивается со значениями инпутов
      inputId: {
        type: String,
        required: true
      },
      // Список значений на дисейбл (строки)
      disabledValues: {
        type: Array,
        required: false
      },
      /*
        Лейбл для всего листа
       */
      label: {
        type: String,
        required: false
      }
    },
    methods: {
      // Проверка значения на наличие в списке недостпных значений
      checkDisabled(value) {
        return this.disabledValues ? this.disabledValues.includes(value) : false;
      },
      // Поклейка уникального id
      uniqueId(inputId, value) {
        return `${inputId}_${value}`;
      },
      // Если объект со свойством value, вернет value, иначе пусть это будет строка
      valueAsString(item) {
        // TODO позже нормально будет)
        return  item.value !== undefined ? item.value : item.slug !== undefined ? item.slug : item;
      },
      nameAsString(item) {
        return item.name === undefined ? item : item.name;
      },
      // При изменении инпута
      updateInput(event) {
        const target = event.target;
        // Если инпут стал активным
        if (target.checked) {
          // Добавляем значение в текущие значения
          this.currentValues.push(target.value)
        } else {
          // Иначе убираем от туда
          this.currentValues.splice(this.currentValues.indexOf(target.value), 1)
        }
        // Даем родителю знать, что что то в списке поменялось
        this.$emit('change')
      },
      // Проверяем есть ли значение в текущих значениях
      shouldBeChecked(value) {
        return this.currentValues.includes(value);
      }
    }

  }
</script>

<style lang="scss">

</style>