import axios from "axios";

document.querySelectorAll('[data-manager-printed-form]').forEach((formEl) => {
    formEl.querySelector('input').addEventListener('change', () => {
        const formData = new FormData(formEl);
        axios({
            method: "post",
            url: formEl.getAttribute('action'),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
    })
    formEl.addEventListener('submit', (e) => {
        e.preventDefault();
        const formData = new FormData(formEl);
        axios({
            method: "post",
            url: formEl.getAttribute('action'),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
    });
});

document.querySelectorAll('[data-marks-form]').forEach((formEl) => {
    async function sendForm(formEl) {
        formEl.classList.add('_loading');
        const formData = new FormData(formEl);
        const response = await axios({
            method: "post",
            url: formEl.getAttribute('action'),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        formEl.classList.remove('_loading');
        const data = response.data;
        if (data.status === 'error') {
            alert(data.message);
        } else {
            // Жмем галочку марки ушли
            const row = formEl.closest('tr');
            const inputSent = row.querySelector('[name="sent"]');
            inputSent.checked = 'checked';
            setTimeout(() => {
                inputSent.dispatchEvent(new Event('change', {
                    bubbles: true,
                    cancelable: true,
                }));
            }, 100);
            const marks = row.querySelector('.marks');
            console.log(marks);
            marks.classList.toggle('_opened');
        }
    }

    formEl.addEventListener('submit', (e) => {
        e.preventDefault();
        sendForm(formEl);
    });
})


function initJsFilterFormElement(inputEl) {
    const opt = document.createElement('option');
    opt.value = 'ALL';
    opt.innerHTML = 'Все';
    inputEl.appendChild(opt);

    const selector = `[${inputEl.dataset.filterBy}]`;
    const datasetProp = inputEl.dataset.filterBy
        .replace('data-', '')
        .replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    const uniqueValues = [];
    document.querySelectorAll(selector).forEach((line) => {
        const value = line.dataset[datasetProp];
        if (!value) {
            return;
        }

        const item = uniqueValues.find((item) => item.value === value);
        if (!item) {
            uniqueValues.push({
                value: value,
                count: 1
            })
        } else {
            item.count++;
        }
    })
    uniqueValues.forEach((uniqueValue) => {
        const opt = document.createElement('option');
        opt.value = uniqueValue.value;
        opt.innerHTML = `${uniqueValue.value} (${uniqueValue.count})`;
        inputEl.appendChild(opt);
    });
}

function handleJsFilterFormElement(inputEl) {
    const selector = `[${inputEl.dataset.filterBy}]`;
    const datasetProp = inputEl.dataset.filterBy
        .replace('data-', '')
        .replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    const valueSelected = inputEl.value;

    document.querySelectorAll(selector).forEach((line) => {
        const value = line.dataset[datasetProp];
        if (value !== valueSelected && (valueSelected !== 'ALL')) {
            line.classList.add('_hide');
        }
    });
}

function dashToCamel(string) {
    return string.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

function submitForm(jsFilterForm) {
    // Обойти весь фильтр, собрать filter-criteria, обойти все строчки и поматчить
    document.querySelectorAll('[data-filter-item]').forEach((line) => {
        line.classList.remove('_hide');
    });
    jsFilterForm.querySelectorAll('select[data-filter-by]').forEach((inputEl) => {
        handleJsFilterFormElement(inputEl);
    });
}

const jsFilterForm = document.querySelector('[data-js-filter-form]');
if (jsFilterForm) {
    jsFilterForm.querySelectorAll('select[data-filter-by]').forEach((inputEl) => {
        initJsFilterFormElement(inputEl);

        inputEl.addEventListener('change', () => {
            submitForm(jsFilterForm);
        })
    });

    jsFilterForm.addEventListener('submit', (e) => {
        e.preventDefault();
        submitForm(jsFilterForm);
    })
}