<template>
  <div class="checkbox-list" :class="blockClasses">
    <label class="checkbox-list__label"
           :class="elementClasses('label')"
           v-if="field.label"
    >
      {{ field.label }}
    </label>

    <div :class="elementClasses('wrapper')" v-if="valueItem.value">
      <div :class="elementClasses('values')">
        <div :class="elementClasses('value')" v-for="(choice, index) in field.choices">
          <input type="checkbox"
                 :id="id(choice, index)"
                 :value="choice.key"
                 class="checkbox-list__input"
                 :class="elementClasses('input')"
                 v-model="valueItem.value"
                 :disabled="choice.disabled || field.disabled">
          <label :for="id(choice, index)"
                 class="checkbox-list__item-label"
                 :class="elementClasses('item-label')"
          >
            <span class="checkbox-list__label-icon" :class="elementClasses('label-icon')">
<!--              <CheckMarkBlack/>-->
            </span>
            <span
                class="checkbox-list__label-title text__small"
                :class="elementClasses('label-title')"
            >
              {{ choice.label }}
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  // import CheckMarkBlack from "../../components/icons/CheckMarkBlack";

  export default {
    name: "CheckboxList",
    // components: {CheckMarkBlack},
    mixins: [
      fieldMixin
    ],
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      }
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>