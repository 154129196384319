<template>
    <div class="cart-pay cart-block cart-block_wide">
        <div class="cart-pay__title cart-block__head">
            <div class="cart-block__num">
                3
            </div>
            <div class="cart-block__title">
                Оплата
            </div>
        </div>
        <div class="cart-block__content">
            <ul class="cart-payment__radio-list">
                <li class="cart-payment__radio-item">
                    <div class="cart-payment__radio">
                        <input class="cart-payment__input" type="radio" name="payment" v-model.number="payment" id="on-receipt" value="10">
                        <label for="on-receipt" class="cart-payment__label">
                            <span class="cart-payment__label-title">
                                Оплата при получении товара
                            </span>
                            <span class="cart-payment__label-description">
                                Наличными или банковской картой при получении в магазине
                            </span>
                        </label>
                    </div>
                </li>
                <li class="cart-payment__radio-item">
                    <div class="cart-payment__radio">
                        <input class="cart-payment__input" type="radio" name="payment" v-model.number="payment" id="online" value="20">
                        <label for="online" class="cart-payment__label">
                            <span class="cart-payment__label-title">
                                Оплата онлайн картой
                            </span>
                            <span class="cart-payment__label-description">
                                Онлайн-оплата платежной картой Visa, MasterCard, Visa Electron, Maestro
                            </span>
                        </label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import {TheMask} from 'vue-the-mask'
import ItemErrors from "./ItemErrors";

export default {
  name: "TheCartPay",
  components: {ItemErrors, TheMask},
  props: {},
  computed: {
    payment: {
      get () {
        return this.$store.state.order.payment
      },
      set (value) {
        this.$store.commit('order/setPayment', value)
      }
    }
  }
}

</script>

<style lang="scss">

</style>