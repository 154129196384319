<template>
    <div class="cart-result-mini">
        <div class="cart-result-mini__result-container">
        <span class="cart-result-mini__result-text">
            Итого
            <span class="cart-result-mini__result-count">
                {{ productCounts }}
            </span>
            <span class="cart-result-mini__result-items">
                товара
            </span>
            на сумму
        </span>
            <div class="cart-result-mini__sum-container">
            <span class="cart-result-mini__sum">
                {{ formatPrice(total) }}
            </span>
                <span class="cart-result-mini__postfix">
                ₽
            </span>
            </div>
        </div>
        <div class="cart-result-mini__delivery">
            <div class="cart-result-mini__delivery-title">
                Стоимость доставки
            </div>
            <div class="cart-result-mini__delivery-text">
                Рассчитывается менеджером
            </div>
        </div>
        <!--TODO Вернуть функционирующим-->
        <!--<a href="javascript:void(0)" class="cart-result-mini__button button button_transparent button_gray">
            Заказ в 1 клик
        </a>-->
    </div>
</template>

<script>

export default {
  name: "TheCartResultMini",
  props: {
    productCounts: Number,
    total: Number
  },
  methods: {
    formatPrice: function (price) {
      return this.$root.formatPrice(price);
    }
  },
  computed: {

  }
}

</script>

<style lang="scss">

</style>