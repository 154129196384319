<template>
    <div>
        <filter-main-layout
                filter-key="filter-main-tyre-auto"
                :select-fields="getSelectAndSlugSelectFields"
                :disabledValues="getDisabledValues"
                :checkboxes-fields="getCheckboxFields"
                :loading="loading"
                @change="filterValues"
                @submit="searchProducts"
                :reset-link="'/catalog/auto/tyre/all/all/all/all/all/all'"
        >

            <template v-slot:bottom-fields>
                <div class="selection-filter__bottom _many">

                  <div class="selection-filter__checkboxes-wrapper">
                    <div class="selection-filter__checkboxes" v-for="field in getCheckboxFields">
                      <field-checkbox-list-seasons v-if="field[0] === 'seasons'"
                                                   :input-id="'filter-main-tyre-auto_seasons'"
                                                   :possible-values="field[1].possibleValues"
                                                   :current-values="field[1].currentValue"
                                                   :disabled-values="getCheckboxFields[field[0]]"
                                                   @change="filterValues"
                      />
                      <base-field-checkbox-list v-else
                                                :input-id="'filter-main-tyre-auto_' + field[0]"
                                                :possible-values="field[1].possibleValues"
                                                :current-values="field[1].currentValue"
                                                :disabled-values="getCheckboxFields[field[0]]"
                                                @change="filterValues"
                      />
                    </div>
                  </div>

                  <a href="/catalog/auto/tyre/all/all/all/all/all/all"
                     class="button button_light selection-filter__clear selection-filter__clear-desktop selection-filter__clear-tyre"
                  > Сбросить фильтр </a>

                </div>
            </template>

        </filter-main-layout>
    </div>
</template>

<script>
    import FilterMainLayout from './FilterMainLayout';
    import BaseFieldCheckboxList from "../parts/BaseFieldCheckboxList";
    import FieldCheckboxListSeasons from "../parts/FieldCheckboxListSeasons";
    import {mapActions, mapState, mapGetters} from "vuex";

    export default {
        name: "TheFilterMainTyreAuto",
        components: {FieldCheckboxListSeasons, FilterMainLayout, BaseFieldCheckboxList},
        computed: {
          ...mapState('topParamAutoTyreModule', {
            paramInfo: 'paramInfo',
            loading: 'loading'
          }),
          ...mapGetters('topParamAutoTyreModule', {
            getSelectFields: 'getSelectFields',
            getSelectAndSlugSelectFields: 'getSelectAndSlugSelectFields',
            getCheckboxFields: 'getCheckboxFields',
            isCurrentPossible: 'isCurrentPossible',
            getDisabledValues: 'getDisabledValues'
          })
        },
        methods: {
          resetFilter(){

          },
          ...mapActions('topParamAutoTyreModule', {
            filterValues: 'filterValues',
            initializationFilter: 'initializationFilter',
            searchProducts: 'searchProducts'
          })
        },
        mounted() {
          this.initializationFilter();
        }
    }
</script>

<style lang="scss">

</style>