import Vue from 'vue';
import ScheduleService from "../../../services/schedule.service";

function isEmpty(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

function matchSlots(slots, matchSlot, postId) {
  let add = true; //Добавлять ли элемент

  matchSlot.postId = postId;

  if(slots.length === 0) {
    slots.push(matchSlot);
    return slots;
  }

  for (const slot in slots) {
    if(slots.hasOwnProperty(slot) && slots[slot].verbose === matchSlot.verbose) {
      if(slots[slot].freedom === false) {
        slots[slot].freedom = matchSlot.freedom;
        slots[slot].postId = matchSlot.postId;
      }
      add = false;
      break;
    }
  }

  if(add) {
    slots.push(matchSlot);
  }

  return slots;
}

const scheduleModule = {
  namespaced: true,
  state: {
    loading: true,
    graph: {},
    date: new Date(),
    monthDate: new Date(),
    disableDates: [],
    services: [],
    currentServiceId: null,
    activeService: {},
    selectedServiceCenterId: null,
    selectedSlot: null,
    carTypes: {},
    contacts: {
      name: {
        value: null,
        fieldName: 'name',
        id: 'name',
        errors: []
      },
      phone: {
        value: '',
        fieldName: 'phone',
        id: 'phone',
        errors: []
      },
      carType: {
        value: 10, // Бейте меня семеро
        fieldName: 'car-type',
        id: 'car-type'
      },
      carAndLicensePlateNumber: {
        value: '',
        fieldName: 'car-and-license-plate-number',
        id: 'car-and-license-plate-number',
        errors: []
      },
    }
  },
  getters: {
    timestamp: (state) => {
      return Math.round(state.date.getTime()/1000);
    },
    serviceCenters: (state) => {
      return state.graph.serviceCenters
    },
    selectedServiceCenter: (state, getters) => {
      let serviceCenterObject = {};

      for (const serviceCenter in getters.serviceCenters) {
        if(getters.serviceCenters.hasOwnProperty(serviceCenter) && getters.serviceCenters[serviceCenter].id === state.selectedServiceCenterId) {
          serviceCenterObject = getters.serviceCenters[serviceCenter];
        }
      }

      return serviceCenterObject;
    },
    currentSlots: (state, getters) => {
      let slots = [];

      if(isEmpty(getters.selectedServiceCenter)) {
        return slots;
      }

      const slotPosts = getters.selectedServiceCenter.slotPosts;

      slotPosts.forEach((slotPost) => {
        let postId = slotPost.id;
        let postSlots = slotPost.slots;

        postSlots.forEach((postSlot) => {
          slots = matchSlots(slots, postSlot, postId);
        })
      })

      slots.sort((a, b) => a.verbose > b.verbose ? 1 : -1)

      return slots;
    }
  },
  actions: {
    async init({ commit, state, dispatch }) {
      const services = await ScheduleService.getServices();
      commit('setServices', services.data);

      if(services.data) {
        if (state.currentServiceId) {
          const currentServiceObj = services.data.find(service => service.id === state.currentServiceId);
          commit('setActiveService', currentServiceObj);
        } else {
          commit('setActiveService', services.data[0]);
        }
      }
      dispatch('loadGraph');
      dispatch('loadCarTypes');
      dispatch('loadDisableDates');
    },
    setActiveService({commit, dispatch}, activeService) {
      commit('setActiveService', activeService);
      dispatch('loadGraph');
      dispatch('loadDisableDates');
    },
    setDate({commit, dispatch}, date) {
      commit('setDate', date);
      dispatch('loadGraph');
    },
    async loadGraph({ commit, state, getters }) {
      commit('setLoading');

      const graph = await ScheduleService.getUserGraph(state.activeService.id, getters.timestamp);
      commit('setGraph', graph.data);

      if(graph.data && graph.data.serviceCenters) {
        if (state.selectedServiceCenterId) {
          let correctSelectedServiceCenter = false;
          graph.data.serviceCenters.forEach((serviceCenter) => {
            if (serviceCenter.id === state.selectedServiceCenterId) {
              correctSelectedServiceCenter = true;
            }
          });
          if (!correctSelectedServiceCenter) {
            commit('setSelectedServiceCenterId', graph.data.serviceCenters[0].id);
          }
        }
      }

      commit('unsetLoading');
    },
    async loadCarTypes({ commit }) {
      let carTypes = await ScheduleService.getCarTypes();
      commit('setCarTypes', carTypes.data);
    },

    async loadDisableDates({ commit, state }) {
      commit('setLoading');

      const result = await ScheduleService.getDisableDates(state.activeService.id, Math.round(state.monthDate.getTime()/1000));
      commit('setDisableDates', result.data);

      commit('unsetLoading');
    },
  },
  mutations: {
    setServices(state, services) {
      state.services = services;
    },
    setActiveService(state, activeService) {
      state.activeService = activeService;
    },
    setDate: (state, date) => {
      state.date = date;
    },
    setGraph: (state, graph) => {
      state.selectedSlot = null;
      state.graph = graph;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    unsetLoading: (state) => {
      state.loading = false;
    },
    setSelectedServiceCenterId: (state, serviceCenterId) => {
      state.selectedSlot = null;
      state.selectedServiceCenterId = serviceCenterId;
    },
    setSelectedSlot: (state, slot) => {
      state.selectedSlot = slot;
    },
    setCarTypes(state, carTypes) {
      state.carTypes = carTypes;
    },
    setCurrentServiceId(state, id) {
      state.currentServiceId = id;
    },
    setMonthDate(state, date) {
      state.monthDate = date;
    },
    setDisableDates(state, data) {
      state.disableDates = data;
    },
  },
};

export default scheduleModule;