<template>
  <div class="range-field sidebar-filter-field" :class="blockClasses">
    <div class="range-field__label sidebar-filter-field__label" :class="elementClasses('label')">
      {{ field.label }}
    </div>

    <div class="range-field__slider-wrap">
      <input type="text"
             class="range-field__input"
             v-model="valueModel[0]"
      >
      <input type="text"
             class="range-field__input"
             v-model="valueModel[1]"
      >
      <div class="range-field__slider">
        <vue-slider
            v-model="valueModel"
            :min="field.data.min"
            :max="field.data.max"
            :lazy="true"
            :dotSize="21"
            :tooltip="'none'"
            ref="slider"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  import VueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/default.css';

  export default {
    name: "RangeField",
    mixins: [
      fieldMixin
    ],
    components: {
      VueSlider
    },
    computed: {
      valueModel: {
        get() {
          if (!this.valueItem.value) {
            return [0, 0];
          }

          return [
            this.valueItem.value.from,
            this.valueItem.value.to
          ]
        },
        set(value) {
          if (value[0] && value[1]) {
            this.valueItem.value.from = value[0];
            this.valueItem.value.to = value[1];
          }
        },

      }
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = {
            from: this.field.data.min,
            to: this.field.data.max
          };
        }
      }
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>