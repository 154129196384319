import {onDomChanges, onDomReady} from '../../components/dynamic/observer';

class Popup {
  constructor() {
    this.target = document.querySelector('[data-popup]');
    if (this.target) {
      this.initTimeoutSetting = this.target.dataset.popupInitTimeout * 1000;
      this.closeTimeoutSetting = this.target.dataset.popupCloseTimeout * 1000;
      this.isPopupSee = sessionStorage.getItem('isPopupSee');
      this.popupKey = this.target.dataset.popupKey;
      this.bind();
      this.bindMutated();
    }
  }

  bind() {
    if (this.initTimeoutSetting !== 0) {
      this.initByTimeout();
    }
  }

  bindMutated() {
    document.addEventListener('DOMContentMutated', this.initResolve);
  }

  initByTimeout() {
    setTimeout(() => {
      this.openPopup();
    }, this.initTimeoutSetting);
  }

  closeByTimeout() {
    setTimeout(() => {
      this.closePopup();
    }, this.closeTimeoutSetting);
  }

  openPopup() {
    const isPopupSee = sessionStorage.getItem(this.popupKey)
    if (this.target && !document.querySelector('body').classList.contains('modal-opened') && !isPopupSee) {
      this.target.click();
      sessionStorage.setItem(this.popupKey, this.popupKey);
      this.closeByTimeout();
    }
  }

  closePopup() {
    const closer = document.querySelector('.modal__closer');
    closer.click()
  }
}

const init = () => {
  const popup = new Popup();
}
onDomReady(init);
onDomChanges(init);
