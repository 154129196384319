<template>
    <TheAutoTyreSidebarFilter v-if="isTyres"/>
    <TheAutoWheelSidebarFilter v-else-if="isWheels"/>
</template>

<script>
  import {mapActions, mapState, mapGetters, Store} from "vuex";
  import TheAutoTyreSidebarFilter from '../sidebars/TheFilterSidebarTyreAuto.vue';
  import TheAutoWheelSidebarFilter from '../sidebars/TheFilterSidebarWheelAuto.vue';

  export default {
    name: "TheAutoMultiSidebarFilter",
    components: {
      TheAutoTyreSidebarFilter,
      TheAutoWheelSidebarFilter
    },
    computed: {
      ...mapGetters('autoMultiSidebarModule', {
        isTyres: 'isTyres',
        isWheels: 'isWheels',
        currentType: 'currentType'
      })
    },
    watch: {
      currentType: (newValue, oldValue) => {

      }
    },
    methods: {

    }
  }
</script>