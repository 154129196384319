import Chart from "chart.js/auto";
import axios from 'axios';
import _ from 'lodash';


async function handleFormSubmit(form) {
    const formData = new FormData(form);
    const object = {};
    formData.forEach(function(value, key){
        object[key] = value;
    });
    try {
        const response = await axios({
            url: '/orders-table',
            method: 'get',
            params: object
        });
        const div = document.createElement('div');
        div.innerHTML = response.data;
        const formResult = div.querySelector('[data-article-finder-search-form-result]');
        if (formResult) {
            document.querySelector('[data-article-finder-search-form-result]').replaceWith(formResult);
        }
    } catch (e) {

    }
}

function initForms() {
    document.querySelectorAll('[data-article-finder-search-form]').forEach((form) => {
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            try {
                handleFormSubmit(form);
            } catch (e) {
                console.log(e);
            }
            return false;
        });

        form.querySelectorAll('input').forEach((inputEl) => {
            inputEl.addEventListener('input', _.debounce(() => {
                handleFormSubmit(form);
            }, 300));
        });
    });
}
initForms();