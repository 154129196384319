<template>
    <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" width="2" height="8" transform="rotate(90 8 0)" fill="#1F2129"/>
    </svg>
</template>

<script>
export default {
  name: "MinusIcon"
}
</script>