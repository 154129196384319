import PickupPointsService from "../../services/pickupPoints.service";

const pickupPointsModule = {
  namespaced: true,
  state: {
    points: [],
  },
  getters: {
    getPoints(state) {
      return state.points;
    }
  },
  actions: {
    async fetch({ commit }) {
      try {
        const points = await PickupPointsService.fetch();
        commit('setPoints', points.data);
      } catch (e) {
        console.log(e);
      }
    }
  },
  mutations: {
    setPoints(state, value) {
      state.points = value;
    },
    reset(state) {
      Object.assign(state, {
        points: [],
      });
    }
  },
};

export default pickupPointsModule;