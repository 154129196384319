<template>
    <form class="sizes-filter" v-on:submit.prevent="updateProductList">
        <div class="sizes-filter__blocks">
            <div class="sizes-filter__block" v-if="standardSizeList.length">
                <div class="sizes-filter__title">
                    Заводские размеры
                </div>
                <ul class="sizes-filter__size-list-category">
                    <li class="sizes-filter__size-list-category-item"
                        v-for="standardSize in standardSizes">
                        <div class="sizes-filter__size-list-category-name">
                            {{ sizesCategoryName }}{{ standardSize.diameter }}
                        </div>
                        <ul class="sizes-filter__size-list">
                            <li class="sizes-filter__size-item"
                                v-for="sizeItem of standardSize.objects">
                                <input type="checkbox"
                                       :id="sizeItem.value"
                                       :value="sizeItem.value"
                                       v-model="selectedSizes[currentType]"
                                       v-on:change="change(selectedSizes[currentType])">
                                <label :for="sizeItem.value" v-on:click="showSubmitButton">{{ sizeItem.name }}</label>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="sizes-filter__block" v-if="compatibleSizeList.length">
                <div class="sizes-filter__title">
                    Совместимые размеры
                </div>
                <ul class="sizes-filter__size-list-category">
                    <li class="sizes-filter__size-list-category-item"
                        v-for="compatibleSize in compatibleSizes">
                        <div class="sizes-filter__size-list-category-name">
                            {{ sizesCategoryName }}{{ compatibleSize.diameter }}
                        </div>
                        <ul class="sizes-filter__size-list">
                            <li class="sizes-filter__size-item"
                                v-for="sizeItem of compatibleSize.objects">
                                <input type="checkbox"
                                       :id="sizeItem.value"
                                       :value="sizeItem.value"
                                       v-model="selectedSizes[currentType]"
                                       v-on:change="change(selectedSizes[currentType])">
                                <label :for="sizeItem.value" v-on:click="showSubmitButton">{{ sizeItem.name }}</label>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <button type="submit"
                    class="side-filter__ninja-button button"
                    ref="ninjaSubmitButton">
                Применить
            </button>
        </div>

        <preloader v-if="loading"/>
    </form>
</template>

<script>
  import {mapActions, mapState, mapGetters} from "vuex";
  import Preloader from "../../../Preloader/Preloader";

  export default {
    name: "TheSizesFilter",
    components: {Preloader},
    data() {
      return {
        selectedSizes: {
          rims: [],
          tires: [],
        }
      }
    },
    computed: {
      ...mapState('sizesFilterModule', {
        loading: 'loading'
      }),
      ...mapGetters('sizesFilterModule', {
        currentType: 'currentType',
        standardSizeList: 'standardSizeList',
        compatibleSizeList: 'compatibleSizeList',
        currentSelected: 'currentSelected',
        getSelectedSizes: 'getSelectedSizes'
      }),
      compatibleSizes() {
        return this.buildSizesPretty(this.compatibleSizeList);
      },
      standardSizes() {
        return this.buildSizesPretty(this.standardSizeList);
      },
      sizesCategoryName() {
        return (this.currentType === 'tires') ? 'R' : 'Диаметр - ';
      }
    },
    methods: {
      ...mapActions('sizesFilterModule', {
        init: 'init',
        setSelected: 'setSelected'
      }),
      ...mapActions('autoMultiSidebarModule', {
        updateProductList: 'updateProductList'
      }),
      change(selected) {
        this.setSelected(selected);
        if (window.innerWidth < 1280) {
          this.updateProductList();
        }
      },
      showSubmitButton(e) {
        const target = e.target;
        const ninjaSubmitButton = this.$refs.ninjaSubmitButton;
        const offset = (target.clientHeight / 2) + target.offsetTop;
        const offsetLeft = target.offsetLeft + target.offsetWidth;
        ninjaSubmitButton.style.top = `${offset}px`;
        ninjaSubmitButton.style.left = `${offsetLeft + 10}px`;
        ninjaSubmitButton.classList.add('_show');

        clearTimeout(this.hideNinjaSubmitButton);
        this.hideNinjaSubmitButton = setTimeout(() => {
          ninjaSubmitButton.classList.remove('_show');
        }, 6000);
      },
      buildSizesPretty(sizeList) {
        const sizes = [];
        sizeList.forEach((size) => {
          let index = sizes.findIndex((sizeObject) => {
            return sizeObject.diameter === size.diameter;
          });
          if(index === -1) {
            let sizesObject = {
              'diameter': size.diameter,
              'objects': []
            }
            sizesObject.objects.push(size);
            sizes.push(sizesObject);
          } else {
            sizes[index].objects.push(size);
          }
        });

        return sizes;
      }
    },
    mounted() {
    }
  }
</script>
