<template>
  <div class="steps-base-content" :class="className">
    <h2 class="steps-base-content__title">
      <slot name="title"></slot>
    </h2>

    <div class="steps-base-content__inner">
      <Preloader :loading="loading"/>

      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import Preloader from "./../../Preloader/Preloader.vue";
import {mapState} from "vuex";

export default {
  name: "TheStepsBaseContent",
  components: {Preloader},
  props: ['className'],
  computed: {
    ...mapState('schedule', [
      'loading',
    ]),
  }
}
</script>

<style lang="scss">

</style>