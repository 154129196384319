import Vue from 'vue';
import Vuex from 'vuex';

import cart from './modules/cart.module';
import favorites from "./modules/favorites.module";
import order from './modules/order.module';
import carPick from './modules/carPick.module';
import notification from './modules/notification.module';
import pickupPoints from './modules/pickupPoints.module';

import topParamAutoTyreModule from './modules/topFilter/topFilterAutoTyre.module';
import topParamAutoWheelModule from './modules/topFilter/topFilterAutoWheel.module';
import topParamTruckTyreModule from './modules/topFilter/topFilterTruckTyre.module';
import topParamMotoTyreModule from './modules/topFilter/topFilterMotoTyre.module';
import topParamTruckWheelModule from './modules/topFilter/topFilterTruckWheel.module';
import parameters from './modules/topFilter/parameters.module';

import sidebarAutoTyreModule from './modules/sidebar/sidebarAutoTyre.module';
import sidebarAutoWheelModule from './modules/sidebar/sidebarAutoWheel.module';
import sidebarTruckTyreModule from './modules/sidebar/sidebarTruckTyre.module';
import sidebarMotoTyreModule from './modules/sidebar/sidebarMotoTyre.module';
import sidebarTruckWheelModule from './modules/sidebar/sidebarTruckWheel.module';

import typeSwitcherModule from './modules/typeSwitcher.module';
import sizesFilterModule from './modules/sizesFilter.module';
import autoMultiSidebarModule from './modules/autoMultiSidebar.module';

import priceSliderModule from './modules/sidebar/priceSlider.module';
import schedule from "./modules/scheduleService/schedule.module";
import policyFilePathModule from "./modules/policyFilePath.module";

import priceCompetitorFilter from "./modules/priceCompetitor/priceCompetitorFilter.module";
import priceCompetitorItems from "./modules/priceCompetitor/priceCompetitorItems.module";

import currentFilterModule from './modules/currentFilter.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart,
    favorites,
    order,
    carPick,
    pickupPoints,
    policyFilePathModule,

    topParamAutoTyreModule,
    topParamAutoWheelModule,
    topParamTruckTyreModule,
    topParamTruckWheelModule,
    topParamMotoTyreModule,
    parameters,

    sidebarAutoTyreModule,
    sidebarAutoWheelModule,
    sidebarTruckTyreModule,
    sidebarTruckWheelModule,
    sidebarMotoTyreModule,

    typeSwitcherModule,
    sizesFilterModule,
    autoMultiSidebarModule,

    priceSliderModule,

    schedule,

    notification,

    priceCompetitorFilter,
    priceCompetitorItems,

    currentFilterModule
  },
  getters: {
    isLoading(state) {
      let loading = false;
      for (const key in state) {
        if (state.hasOwnProperty(key) && state[key].hasOwnProperty('loading')) {
          loading = loading || state[key].loading;
        }
      }
      return loading;
    },
  },
})