<template>
  <div class="steps-result">
    <div class="steps-result__inner">
      <div class="steps-result__heading">
        <h1 class="steps-result__title">
          Запись создана
        </h1>
      </div>

      <div class="steps-result__main">
        <ul class="steps-result__items">
          <li class="steps-result__item">
            <span class="steps-result__item-title">
              Услуга
            </span>
            <span class="steps-result__item-value">
              {{ activeService.name }}
            </span>
          </li>

          <li class="steps-result__item">
            <span class="steps-result__item-title">
              Сервисный центр
            </span>
            <span class="steps-result__item-value">
              {{ selectedServiceCenter.address }}
            </span>
          </li>

          <li class="steps-result__item">
            <span class="steps-result__item-title">
              Дата и время
            </span>
            <span class="steps-result__item-value">
              {{ humanizeDate }} {{ timeString }}
            </span>
          </li>

          <li class="steps-result__item">
            <span class="steps-result__item-title">
              Контактное лицо
            </span>
            <span class="steps-result__item-value">
              {{ contacts.name.value }}
            </span>
          </li>

          <li class="steps-result__item">
            <span class="steps-result__item-title">
              Телефон
            </span>
            <span class="steps-result__item-value">
              {{ contacts.phone.value }}
            </span>
          </li>

          <li class="steps-result__item">
            <span class="steps-result__item-title">
              Марка и гос.номер авто
            </span>
            <span class="steps-result__item-value">
              {{ contacts.carAndLicensePlateNumber.value }}
            </span>
          </li>
        </ul>

        <a href="/" title="На главную" class="steps-result__button button">
          На главную
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

let timeValue = null;

export default {
  name: "TheStepsResult",
  computed: {
    ...mapGetters('schedule', {
      selectedServiceCenter: 'selectedServiceCenter'
    }),
    ...mapState('schedule', [
      'activeService',
      'date',
      'contacts',
      'selectedSlot'
    ]),
    humanizeDate() {
      const formatter = new Intl.DateTimeFormat("ru", {
        day: "numeric",
        month: "long"
      });
      return formatter.format(this.date);
    },
    timeString() {
      if (timeValue == null) {
        if(this.selectedSlot == null) {
          return '';
        }

        timeValue = this.selectedSlot.verbose;
      }
      return `в ${timeValue}`
    }
  }
}
</script>

<style lang="scss">

</style>