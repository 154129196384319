<template>
  <div class="enum-filter-field" :class="blockClasses" v-if="field.choices.length">
    <label v-if="field.label"
           class="enum-filter-field__label"
           :class="elementClasses('label')">{{ field.label }}</label>
    <div class="enum-filter-field__wrapper"
         :class="elementClasses('wrapper')"
         v-if="valueItem.value"
    >
      <div class="enum-filter-field__values" :class="elementClasses('values')">
        <a href="javascript:void(0);"
           class="enum-filter-field__value"
           :class="elementClasses('value')"
           v-for="(choice, index) in field.choices"
        >
          <input type="checkbox" :id="id(choice, index)" :value="choice.key" v-model="valueItem.value" :disabled="choice.disabled || field.disabled">

          <label :for="id(choice, index)"
                 class="enum-filter-field__label"
                 :class="elementClasses('label')">
            <span class="enum-filter-field__label-icon" :class="elementClasses('label-icon')">
<!--              <CheckMarkBlack/>-->
            </span>
            <span
                class="enum-filter-field__label-title text__small"
                :class="elementClasses('label-title')"
            >
              {{ choice.label }}
            </span>

            <component :is="choice.data.icon"
                       class="enum-filter-field__icon"
                       :class="elementClasses('icon')"/>
          </label>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  // import Snowflake from "../../components/icons/Snowflake.vue";
  // import Sunshine from "../../components/icons/Sunshine.vue";
  // import Allseason from "../../components/icons/Allseason.vue";
  // import CheckMarkBlack from "../../components/icons/CheckMarkBlack";

  export default {
    name: "EnumCheckboxList",
    components: {
      // CheckMarkBlack,
      // Sunshine,
      // Snowflake,
      // Allseason
    },
    mixins: [
      fieldMixin
    ],
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      }
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>