<template>
    <div class="cart-confirm">
        <button class="cart-confirm__button" @click="sendOrder">
            Подтвердить заказ
        </button>
        <div class="cart-confirm__policy">
            Подтверждая заказ вы соглашаетесь с&nbsp;<a :href="'/uploads/co/' + policyFilePath"
                                                   class="cart-confirm__policy-link" target="_blank">
          политикой конфиденциальности</a>
        </div>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: "TheCartConfirm",
    methods: {
      ...mapActions('order', {
        'send': 'send'
      }),
      ...mapActions('policyFilePathModule', {
        'fetch': 'fetch'
      }),
      async sendOrder() {
        const success = await this.send();
        if (success) {
          window.goalManager.proceedYandexGoal('submit_shopping_cart_success');
          window.goalManager.proceedYandexGoal('success_order_allForm');
          window.goalManager.proceedVkGoal('success_order_allForm');
        }
      }
    },
    computed: {
      policyFilePath: {
        get () {
          return this.$store.state.policyFilePathModule.policyFilePath
        },
        set (value) {
          this.$store.commit('order/SetPolicyFilePath', value)
        }
      }
    },
    mounted() {
      this.fetch();
    }
  }

</script>

<style lang="scss">

</style>