import CarPickService from "../../services/carPick.service";

const carPickModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    // возможные значения параметров фильтра
    possibleValues: {
      manufacturers: [],
      carModels: [],
      generations: [],
      modifications: [],
    },
    // выбранные значения параметров фильтра и размеры шин и дисков
    params: {
      manufacturer: '',
      carModel: '',
      generation: '',
      modification: '',
      tyreSizes: [],
      wheelSizes: [],
    },
    // вся информация о выбранной модификации автомобиля
    modificationInfo: {},
  },
  getters: {
    isModificationInfo: (state) => Object.keys(state.modificationInfo).length,
  },
  actions: {
    // Вызывается при загрузке компонента в методе mounted()
    async loadManufacturers({ state, dispatch }) {
      if (!state.loaded && !state.loading) {
        // Сперва тянем список производителей
        dispatch('fetchManufacturers');
      }
    },
    // Подтягивание списка производителей
    async fetchManufacturers({ commit }) {
      commit('setLoading', true);
      try {
        // Получаем список производителей по апи
        const response = await CarPickService.fetchManufacturers();
        // Сохраняем его в стейт
        commit('loadManufacturers', response.data);
        commit('setLoaded');
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    // Вызывается при выборе производителя
    async loadCarModels({ commit, state, dispatch }) {
      commit('setLoading', true);
      try {
        // Тянем спсиок моделей по производителю по апи и сохраняем в стейт
        const response = await CarPickService.loadCarModels(state.params);
        commit('updateCarModels', response.data);
        commit('updateGenerations', []);
        commit('updateModifications', []);
        dispatch('safeClear', {
          manufacturer: state.params.manufacturer
        });
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    // Вызывается при выборе модели
    async loadGenerations({ commit, state, dispatch }) {
      commit('setLoading', true);
      try {
        // Тянем спсиок поколений по производителю и модели по апи и сохраняем в стейт
        const response = await CarPickService.loadGenerations(state.params);
        commit('updateGenerations', response.data);
        commit('updateModifications', []);
        dispatch('safeClear', {
          manufacturer: state.params.manufacturer,
          carModel: state.params.carModel
        });
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    // Вызывается при выборе поколения
    async loadModifications({ commit, state }) {
      commit('setLoading', true);
      try {
        // Тянем спсиок модификаций по производителю и модели и поколению по апи и сохраняем в стейт
        const response = await CarPickService.loadModifications(state.params);
        commit('updateModifications', response.data);
        commit('setLoaded');
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    // Вызывается при выборе модификации
    async loadModificationInfo({ commit, state, dispatch }) {
      commit('setLoading', true);
      try {
        // Тянем информацию ппроизводителю и модели и поколению и модификаци по апи и сохраняем в стейт
        const response = await CarPickService.loadModificationInfo(state.params);
        await commit('updateModificationInfo', response.data);
        await commit('setLoaded');
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    // Вызывается при отправке формы
    async search({ commit, state, rootState, dispatch }) {
      commit('setLoading', true);
      /*
        Если это первый вызов метода, значит у нас еще не загружен размерный фильтр, его вообще нет на странице.
        В этом случае нам нужно идти на сервак за шаблоном, что бы подменить контент и добавить элементы на страницу.
        Если это уже не первый вызов, то нам не нужно подменять контент, а информация по размерам уже есть в
        состоянии текущего модуля. Мы просто переинициализируем цепочку начиная с переключателя.
        Но пока есть проблема: тайтлы то нужно менять, но это уже совсем другая история
       */
      // TODO Тайтл например поменять хоть
      if (rootState.sizesFilterModule.loaded) { // Если уже загружен фильтр по размерам
        // Навесим прелодер на размерный фильтр
        commit('sizesFilterModule/setLoading', true, {root: true});
      } else {
        try {
          // Сервис отправит запрос, получит хтмл контент и подменит содержимое текущей страницы
          await CarPickService.search(state.params);
        } catch (e) {
          console.log(e);
        }
      }
      // Начинаем инициализировать переключатель шин и дисков
      dispatch('typeSwitcherModule/init', null, {root: true});
      commit('setLoading', false);
    },
    safeClear({commit, dispath}, newParams) {
      newParams = {
        manufacturer: '',
        carModel: '',
        generation: '',
        modification: '',
        tyreSizes: [],
        wheelSizes: [],
        ...newParams
      };
      commit('setParams', newParams);
      commit('updateModificationInfo', {});
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state) {
      state.loaded = true;
    },
    setParams(state, params) {
      state.params = params;
    },
    loadManufacturers(state, manufacturers) {
      state.possibleValues.manufacturers = manufacturers;
    },
    updateCarModels(state, carModels) {
      state.possibleValues.carModels = carModels;
    },
    updateGenerations(state, generations) {
      state.possibleValues.generations = generations;
    },
    updateModifications(state, modifications) {
      state.possibleValues.modifications = modifications;
    },
    updateModificationInfo(state, modificationInfo) {
      state.modificationInfo = modificationInfo;
    },
  },
};

export default carPickModule;