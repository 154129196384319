<template>
    <div class="cart-contacts cart-block">
        <div class="cart-block__head">
            <div class="cart-block__num">
                1
            </div>
            <div class="cart-block__title">
                Контактная информация
            </div>
        </div>
        <div class="cart-block__content">
            <ul class="cart-contacts__input-list">
                <li class="cart-contacts__input-item">
                    <div class="cart-contacts__input-item-container">
                        <label for="name" class="cart-contacts__input-label required">
                            Контактное лицо
                        </label>
                        <input type="text" class="cart-contacts__input required" v-model="name" id="name"
                               name="name" required="required">
                        <ItemErrors :errors="errors.name"/>
                    </div>
                </li>
                <li class="cart-contacts__input-item">
                    <div class="cart-contacts__input-item-container">
                        <label for="phone" class="cart-contacts__input-label required">
                            Телефон
                        </label>
                        <TheMask
                                :mask="'# (###) ###-##-##'"
                                :masked="true"
                                type="text"
                                v-model="phone"
                                class="cart-contacts__input required"
                                id="phone"
                                name="phone"
                                required="required"
                        />
                        <ItemErrors :errors="errors.phone"/>
                    </div>
                </li>
                <li class="cart-contacts__input-item">
                    <div class="cart-contacts__input-item-container">
                        <label for="mail" class="cart-contacts__input-label">
                            E-mail
                        </label>
                        <input type="text" class="cart-contacts__input" id="mail" name="mail" v-model="email">
                        <ItemErrors :errors="errors.email"/>
                    </div>
                </li>
            </ul>
            <div class="cart-contacts__info">
                * Поля со звёздочкой обязательны
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
  import {TheMask} from 'vue-the-mask'
  import ItemErrors from "./ItemErrors";

  export default {
    name: "TheCartContacts",
    components: {ItemErrors, TheMask},
    props: {},
    computed: {
      name: {
        get () {
          return this.$store.state.order.name
        },
        set (value) {
          this.$store.commit('order/setName', value)
        }
      },
      phone: {
        get () {
          return this.$store.state.order.phone
        },
        set (value) {
          this.$store.commit('order/setPhone', value)
        }
      },
      email: {
        get () {
          return this.$store.state.order.email
        },
        set (value) {
          this.$store.commit('order/setEmail', value)
        }
      },
      errors: {
        get () {
          return this.$store.state.order.errors
        },
      }
    }
  }

</script>

<style lang="scss">

</style>