<template>
  <div class="steps-service-tabs">
    <div class="tabs tabs_arrowed">
      <ul class="tabs__items">
        <li class="tabs__item" :class="{_active: isStepActive(step.id)}" v-for="(step, index) in steps" :key="step.id">
          <a href="javascript:void(0);" class="tabs__link" @click="setActiveStep(step.id)">
            <span class="tabs__index">
              {{ index + 1 }}
            </span>
            <span class="tabs__name">
              {{ step.name }}
            </span>
          </a>

          <div class="tabs__item-arrow">
            <div class="tabs__arrow-top-bar"></div>
            <div class="tabs__arrow-bottom-bar"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheStepsServiceTabs",
  props: ['activeStep', 'steps'],
  methods: {
    isStepActive(stepId) {
      return this.activeStep === stepId;
    },
    setActiveStep(stepId) {
      this.$emit('setActiveStep', stepId);
    }
  }
}
</script>

<style lang="scss">

</style>