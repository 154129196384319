import SidebarTruckTyreService from '../../../services/sidebar/sidebarTruckTyre.service';
import Sidebar from './sidebar.module';
import filterFieldClass from "../../../classes/filterFieldClass";

const SidebarTruckTyreModule = Object.assign({}, Sidebar);

const fields = {
  studdeds: new filterFieldClass({
    label: 'Шипованность',
    type: 'bool-checkboxes',
    requestFieldName: 'studded',
  }),
  producers: new filterFieldClass({
    label: 'Производители',
    type: 'slug-checkboxes',
    requestFieldName: 'producers',
  }),
};

const state = {
  service: SidebarTruckTyreService,
  name: 'sidebarTruckTyreModule',
  topFilterModuleName: 'topParamTruckTyreModule',
  paramInfo: fields
};

SidebarTruckTyreModule.state = {...SidebarTruckTyreModule.state, ...state};

export default SidebarTruckTyreModule;