const sliderCounter = (sliderContainer, slideIndex, slideCount, slider) => {
    if (slider && slideIndex && slideIndex) {
        const slidesIndexText = sliderContainer.querySelector(slideIndex);
        const slidesCountText = sliderContainer.querySelector(slideCount);
        let info = slider.getInfo();
        let slidesIndex = info.displayIndex;
        let slidesCount = info.slideCount;


        if (slidesIndexText && slidesCountText) {
            slidesIndexText.innerHTML = slidesIndex;
            slidesCountText.innerHTML = slidesCount;

            slider.events.on('transitionStart', () => {
                info = slider.getInfo();
                slidesIndex = info.displayIndex;
                slidesCount = info.slideCount;
                slidesIndexText.innerHTML = slidesIndex;
                slidesCountText.innerHTML = slidesCount;
            });
        }
    }
};

export default sliderCounter;