import LiveEvent from "../../components/live/live";

new LiveEvent('click', '.smart-tabs-offers li a', function smartTab(e) {
  let button = this;
  if (button.tagName === 'A') {
    e.preventDefault();
  }

  let selector = button.dataset.tab;
  if (!selector && button.getAttribute('href')) {
    selector = button.getAttribute('href');
  }

  let tab = document.querySelector(selector);

  if (tab) {
    if(selector === '#all') {
      tab.parentNode.querySelectorAll(".smart-content-offers").forEach((item) => {
        if(!item.classList.contains('_active')) {
          item.classList.add('_active');
        }
      });
    } else {
      tab.parentNode.querySelectorAll(".smart-content-offers").forEach((item) => { item.classList.remove('_active') });
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('changeAccordionContent'));
    }, 10);

    tab.classList.add('_active');
  }
  button.closest('.smart-tabs-offers').querySelectorAll('li').forEach((item) => { item.classList.remove('_active') });
  button.closest('li').classList.add('_active');

  let event = new CustomEvent('smart-tabs-handle', {'detail': {'button': button, 'tab': tab}});
  document.dispatchEvent(event);
});