import axios from 'axios';

const Sidebar = {
  fetchPossibleValues(url, params) {
    return axios.get(url, { params });
  },
  async updateProductList(url, params) {
    return axios.get(url, { params })
      .then(({ data }) => {
        const page = document.createElement('div');
        page.innerHTML = data;

        const newTotalData = page.querySelector('[data-product-total]');
        const currentTotalData = document.querySelector('[data-product-total]');

        if (currentTotalData && newTotalData) {
          currentTotalData.parentNode.replaceChild(newTotalData, currentTotalData);
        }

        const newData = page.querySelector('[data-product-list]');
        const currentData = document.querySelector('[data-product-list]');

        currentData.parentNode.replaceChild(newData, currentData);

        let event = new Event('DOMContentMutated');
        document.dispatchEvent(event);
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

export default Sidebar;