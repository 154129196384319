import Sidebar from './sidebar.service'

const SidebarAutoTyreService = {
  async fetchPossibleValues(params) {
    return Sidebar.fetchPossibleValues('/catalog/api/auto/tyre/filter-preparation', params);
  },
  async updateProductList(params) {
    return Sidebar.updateProductList('/catalog/api/auto/tyre/update-list', params);
  },
};

export default SidebarAutoTyreService;