<template>
  <TheStepsBaseContent class-name="steps-points-list">
    <template v-slot:title>
      Выберите сервисный центр
    </template>

    <template v-slot:content>
      <ul class="steps-base-content__items">
        <li class="steps-base-content__item"
            :class="{ _active: isActive(serviceCenter) }"
            v-for="serviceCenter in serviceCenters"
        >
          <a :href="`#service-address-` + serviceCenter.id"
             class="steps-base-content__item-link"
             @click.prevent="setActive(serviceCenter)"
          >
            {{ serviceCenter.address }}
          </a>
        </li>
      </ul>

      <TheServiceResult class="steps-base-content__result"/>
    </template>
  </TheStepsBaseContent>
</template>

<script>
import {mapState} from "vuex";
import TheStepsBaseContent from "./TheStepsBaseContent.vue";
import TheServiceResult from "../TheServiceResult.vue";

export default {
  name: "TheStepsPointsList",
  components: {TheServiceResult, TheStepsBaseContent},
  computed: {
    ...mapState('schedule', {
      'selectedServiceCenterIdStore': 'selectedServiceCenterId',
      'graph': 'graph',
      'selectedSlot': 'selectedSlot'
    }),
    serviceCenters() {
      return this.graph.serviceCenters
    },
    selectedServiceCenterId: {
      get() {
        return this.selectedServiceCenterIdStore
      },
      set(value) {
        this.$store.commit('schedule/setSelectedServiceCenterId', value)
      }
    },
  },
  methods: {
    isActive(serviceCenter) {
      return serviceCenter.id === this.selectedServiceCenterId;
    },
    setActive(serviceCenter) {
      this.selectedServiceCenterId = serviceCenter.id;
    },
  },
}
</script>

<style lang="scss">

</style>