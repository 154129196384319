/**
 *  Поворачивает и двигает картиночки немного.
 *  Случайным образом.
 *
 * */

let rotatePictures = [];

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

import {onDomReady} from "../../components/dynamic/observer";

function init() {
    rotatePictures = document.querySelectorAll('[data-picture-rotate]');

    rotatePictures.forEach((element) => {
        let horizontal = getRandomIntInclusive(-10, 10);
        let vertical = getRandomIntInclusive(-7, 7);
        let rotate = getRandomIntInclusive(-7, 7);
        element.style.opacity = getRandomIntInclusive(90, 100) / 100;
        element.style.cssText += `transform: translate3d(${horizontal}px, ${vertical}px, 0) rotate(${rotate}deg)`;
    });
}

onDomReady(() => {
    init();
});