<template>
  <div class="filter-sort__field-wrap">
<!--    <div class="filter-sort__total">-->
<!--      Найдено товаров: 45-->
<!--    </div>-->

    <BaseFieldSort
        v-if="this.getSortParams && getSortBy"
        class="filter-sort__field"
        :field-id="'sort'"
        :current-value="getSortBy"
        :values="this.getSortParams"
        v-model="getSortBy"
        @change="change"
    />

  </div>
</template>

<script>
import BaseFieldSort from "../parts/BaseFieldSort";
import {mapActions, mapGetters, mapState} from "vuex";
export default {
  name: "TheFilterTyreSort",
  components: {BaseFieldSort},
  computed: {
    ...mapGetters('currentFilterModule', [
        'getSortParams',
        'getSortBy',
    ]),
  },
  methods: {
    ...mapActions('currentFilterModule', [
        'updateProductList'
    ]),
    change(value) {
      this.updateProductList(value)
    }
  },
}
</script>

<style scoped>

</style>