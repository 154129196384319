<template>
    <div class="price-slider">
        <input type="text"
               class="price-slider__input"
               v-model="stateValue.value[0]">
        <input type="text"
               class="price-slider__input"
               v-model="stateValue.value[1]">
        <div class="price-slider__slider">
            <vue-slider
                    v-model="stateValue.value"
                    :min="getMin"
                    :max="getMax"
                    :lazy="true"
                    :dotSize="21"
                    :tooltip="'none'"
                    ref="slider"/>
        </div>
    </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from "vuex";
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  export default {
    name: "ThePriceSlider",
    data() {
      return {
        currentValue: [0, 0]
      }
    },
    computed: {
      ...mapGetters('priceSliderModule', {
        getMin: 'getMin',
        getMax: 'getMax',
      }),
      ...mapState('priceSliderModule', {
        stateValue: 'data'
      }),
      isValueChanged() {
        return JSON.stringify(this.currentValue) === JSON.stringify(this.stateValue.value);
      },
      isValueValid() {
        return this.stateValue.value[0] >= this.getMin && this.stateValue.value[1] <= this.getMax;
      }
    },
    methods: {
      ...mapActions('priceSliderModule', {
        filter: 'filter'
      }),
      onValueChange() {
        if (this.isValueValid) {
          if (this.isValueChanged) {
            this.currentValue = JSON.parse(JSON.stringify(this.stateValue.value));
            this.$refs.slider.setValue(this.stateValue.value);
          } else {
            this.filter()
          }
        }
      },
    },
    components: {
      VueSlider
    },
    mounted() {
    },
    watch: {
      stateValue: {
        deep: true,
        handler() {
          this.onValueChange();
        }
      }
    }
  }
</script>
