function initHamburgers() {
    const mobileHamburger = document.querySelector('[data-hamburger]');
    const closeTabs = document.querySelectorAll('[data-closed-block]');
    const menu = document.querySelector('[data-expand-block]');
    const secondMenu = document.querySelector('[data-second-menu]');
    const back = document.querySelectorAll('[data-back]');
    const secondExpand = document.querySelector('[data-second-expand]');
    const firstNav = document.querySelector('[data-first-menu]');


    if (mobileHamburger) {
        mobileHamburger.addEventListener('click', (e) => {
            if (!menu.classList.contains('_opened')) {
                menu.classList.add('_opened');
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'relative';
                mobileHamburger.classList.add('_opened');
            } else {
                menu.classList.remove('_opened');
                document.body.style.overflow = 'visible';
                document.body.style.position = 'static';
                mobileHamburger.classList.remove('_opened');
            }
        });
    }

    if (secondMenu) {
        secondMenu.addEventListener('click', (e) => {
            if (!secondExpand.classList.contains('_opened'))
                secondExpand.classList.add('_opened');
                firstNav.style.opacity = '0';
        });

    }

    if (closeTabs) {
        closeTabs.forEach((closeTab) => {
            closeTab.addEventListener('click', (e) => {
                menu.classList.remove('_opened');
                document.body.style.overflow = 'visible';
                document.body.style.position = 'static';
            });
        });
    }

    if (back) {
        back.forEach((backItem) => {
            backItem.addEventListener('click', (e) => {
                secondExpand.classList.remove('_opened');
                firstNav.style.opacity = '1';
            });
        });
    }
}

initHamburgers();
