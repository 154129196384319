const miniResultTracking = (elementStartTracking, elementEndTracking, miniResultElement) => {
    const elementStartBottom = window.pageYOffset + elementStartTracking.getBoundingClientRect().bottom;
    const elementEndBottom = window.pageYOffset + elementEndTracking.getBoundingClientRect().bottom;
    const windowBottom = window.pageYOffset + document.documentElement.clientHeight;

    if ((windowBottom >= elementStartBottom) && (windowBottom <= elementEndBottom)) {
        if (!(miniResultElement.classList.contains('_active'))) {
            miniResultElement.classList.add('_active');
        }
    } else {
        if (miniResultElement.classList.contains('_active')) {
            miniResultElement.classList.remove('_active');
        }
    }
};

window.onload = () => {
    const elementStartTracking = document.querySelector('[data-start-tracking]');
    const elementEndTracking = document.querySelector('[data-end-tracking]');
    const miniResultElement = document.querySelector('[data-result-mini]');

    if (elementStartTracking && elementEndTracking && miniResultElement) {
        window.addEventListener('resize', () => {
            miniResultTracking(elementStartTracking, elementEndTracking, miniResultElement)
        });

        window.addEventListener('scroll', () => {
            miniResultTracking(elementStartTracking, elementEndTracking, miniResultElement)
        });
    }
};