const searchInput = document.querySelector('[data-mm-search]');
if (searchInput) {
    initSearch(searchInput);
}

function initSearch(searchInput) {
    searchInput.addEventListener('input', () => {
        processSearch(searchInput.value);
    });
}

function processSearch(query) {
    // Разбиваем на группы, затем матчим те, для кого подошла хотя бы одна группа
    // Обходим офферы. Те кто поматчились маркируем _matched. Кто нет - убираем _matched

    const list = document.querySelector('[data-mm-list]');
    if (query) {
        list.classList.add('_search');
    } else {
        list.classList.remove('_search');
    }

    list.querySelectorAll('[data-offer]').forEach((offerEl) => {
        const name = offerEl.dataset.offerFullname;
        if (match(query, name)) {
            offerEl.classList.add('_matched');
        } else {
            offerEl.classList.remove('_matched');
        }
    });
}

function match(query, string) {
    const queryMatch = string.toLowerCase();
    const groups = query.split('|');
    for (let key in groups) {
        const group = groups[key];
        const terms = group.trim().toLowerCase().split(/[ ,]+/);
        const filteredTerms = terms.filter((term) => !!term);
        if (filteredTerms.length < 1) {
            continue;
        }
        if (filteredTerms.every((term) => queryMatch.includes(term))) {
            return true;
        }
    }
    return false;
}