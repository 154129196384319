import axios from 'axios';

const CarPickService = {
  async fetchManufacturers() {
    return axios.get('/api/cars/manufacturers');
  },
  async loadCarModels(params) {
    const manufacturerSlug = params.manufacturer;
    return axios.get(`/api/cars/models/${manufacturerSlug}`);
  },
  async loadGenerations(params) {
    const manufacturerSlug = params.manufacturer;
    const modelSlug = params.carModel;
    return axios.get(`/api/cars/generations/${manufacturerSlug}/${modelSlug}`);
  },
  async loadModifications(params) {
    const manufacturerSlug = params.manufacturer;
    const modelSlug = params.carModel;
    const generationSlug = params.generation;
    return axios.get(`/api/cars/modifications/${manufacturerSlug}/${modelSlug}/${generationSlug}`);
  },
  async loadModificationInfo(params) {
    const manufacturerSlug = params.manufacturer;
    const modelSlug = params.carModel;
    const generationSlug = params.generation;
    const modificationSlug = params.modification;
    return axios.get(`/api/cars/info/${manufacturerSlug}/${modelSlug}/${generationSlug}/${modificationSlug}`);
  },
  async search(params) {
    return axios.post(`/catalog/auto/car/${params.manufacturer}/${params.carModel}/${params.generation}/${params.modification}`, params.data)
      .then(({ data }) => {
        // По этому запросу мы получили хтмл код, стандартная процедура подмены контента
        const page = document.createElement('div');
        page.innerHTML = data;

        const newData = page.querySelector('[data-catalog-main]');
        const currentData = document.querySelector('[data-catalog-main]');

        currentData.parentNode.replaceChild(newData, currentData);

        // Отправка события мутации контента, то же событие вызывается при открытии модалок и тд и тп
        let event = new Event('DOMContentMutated');
        document.dispatchEvent(event);

        // Отправка события подмены контента каталога
        // Слушатель находитися в static/vue/app.js, там произойдет назначение елементам соответствующих компонентов
        // Переключатель шин и дисков, фильтр по размерам и мультисайдбар
        event = new Event('CatalogMainReplaced');
        document.dispatchEvent(event);

        const filterButton = document.querySelector(`[data-selector="[data-side-filter]"]._hide`);
        if (filterButton) {
          filterButton.classList.remove('_hide');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default CarPickService;