<template>
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0L15.4115 7.98257L24 8.78522L17.52 14.5214L19.4164 23L12 18.5626L4.58359 23L6.48 14.5214L0 8.78522L8.58845 7.98257L12 0ZM12 4.9346L9.91254 9.81897L4.65745 10.3101L8.62243 13.8199L7.46205 19.0078L12 16.2927L16.5379 19.0078L15.3776 13.8199L19.3426 10.3101L14.0875 9.81897L12 4.9346Z" fill="#B6BCCA"/>
    </svg>
</template>

<script>
    export default {
        name: "FavoritesIcon"
    }
</script>