require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh.js');
require('./common/goals2.js');
require('./common/form-validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/rotate-picture.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./common/need-animation.js');

require('./catalog/ajax-update-list');
require('./catalog/product-z-indexes');
require('./catalog/smart-tabs-offers');

require('./popup/popup');

require('./information/guarantee.js');

require('./personal/personal-menu.js');

require('./field/phone.js');
require('../components/smart-tabs/smart-tabs');
require('../components/smart-tabs/smart-tabs-multiply');
require('../vue/app.js');

require('./accordion');
require('./sliders');
require('./hamburger');
require('./magicScroll');
require('./rating-circle');
require('fslightbox');

require('./cart/result-mini');

require('./cart/add');

require('./favorites/toggle');

require('./map/multy-marks-map.js');

require('./to-top.js');

require('./ecommerce/ecommerce');
require('./marketplace/dashboard');

require('./filters/project-filters');

require('./marketplace/manager');
require('./marketplace/accountant');
require('./marketplace/store');
require('./marketplace/markup');
require('./common/copy-on-click');
