import PriceCompetitorService from "../../../services/priceCompetitor.service";

const PriceCompetitorItems = {
  namespaced: true,
  state: {
    tyres: [],
    competitors: [],
  },
  getters: {

  },
  mutations: {
    setTyres(state, data) {
      state.tyres = data;
    },
    setCompetitors(state, data) {
      state.competitors = data;
    },
  },
  actions: {
    async fetchCompetitors({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchCompetitors();
        commit('setCompetitors', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTyres({ state, dispatch, commit, rootState }) {
      try {
        // commit('catalogFilter/setLoading', true, { root: true });
        // const payload = await dispatch('getParams');
        // const { productType } = rootState.catalogFilter.settings;

        const result = await PriceCompetitorService.fetchTyres(rootState.priceCompetitorFilter.settings);
        commit('setTyres', result.data.tyreItems);
        // await dispatch('catalogFilter/replaceCatalogContent', result.data, { root: true });
        // document.dispatchEvent(new Event('DOMContentMutated'));
        // commit('catalogFilter/setLoading', false, { root: true });
      } catch (error) {
        console.log(error);
      }
    },
    async debounceFetchTyres({ state, commit, dispatch, rootState }) {
      try {
        this.debounceOrder = this.debounceOrder ? this.debounceOrder + 1 : 1;
        setTimeout(async () => {
          this.debounceOrder = this.debounceOrder - 1;
          if (!this.debounceOrder) {
            commit('priceCompetitorFilter/setPage', rootState.priceCompetitorFilter.settings.page + 1, {root: true});
            const result = await PriceCompetitorService.fetchTyres(rootState.priceCompetitorFilter.settings);

            if (result.data.tyreItems.length < 50) {
              commit('priceCompetitorFilter/setTrueIsLastPage', {}, {root: true});
            }

            commit('setTyres', state.tyres.concat(result.data.tyreItems));
          }
        }, 50);
      } catch (e)  {
        console.log(error);
      }
    },
    async fetchHistoryPrices({ state, dispatch, commit, rootState }, payload) {
      try {
        console.log(payload);
        const result = await PriceCompetitorService.fetchHistoryPrices(payload);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
}

export default PriceCompetitorItems;