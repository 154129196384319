<template>
    <div class="favorites__head">
        <div class="favorites__container">
            <div class="favorites__wrapper">
                <div class="favorites__top">
                    <div class="favorites__top-counter">
                        <div class="favorites__top-text">
                            Всего товаров:
                        </div>
                        <div class="favorites__top-count" v-if="(checkout && checkout.items && checkout.items.length > 0)">
                            {{ count }}
                        </div>
                    </div>
                    <div class="favorites__top-filters" v-if="(checkout && checkout.items && checkout.items.length > 0)">
                        <div class="favorites__top-select-wrap">
                            <div class="favorites__top-select-icon">
                                <sort-select-icon/>
                            </div>
                            <select class="favorites__top-select-button"
                                    v-model="selected"
                                    @change="sortBy"
                            >
                                <option v-for="option in options" :value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                        <div class="favorites__top-wrap">
                            <a href="javascript:void(0);"
                               class="favorites__top-link"
                               :class="{ 'favorites__top-link_active-grid': isActive === 1 }"
                               @click="setToGrid"
                            >
                                <sort-grid-icon/>
                            </a>
                            <a href="javascript:void(0);"
                               class="favorites__top-link"
                               :class="{ 'favorites__top-link_active-list': isActive === 2 }"
                               @click="setToList"
                            >
                                <sort-list-icon/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preloader
                v-if="!checkout"
                :class="{ 'favorites__preloader': !checkout}"
        />
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
    import TheFavoritesHeading from "./TheFavoritesHeading";
    import Preloader from "../Preloader/Preloader";
    import SortGridIcon from "../icons/SortGridIcon";
    import SortListIcon from "../icons/SortListIcon";
    import SortSelectIcon from "../icons/SortSelectIcon";

    export default {
        name: "TheFavoritesPage",
        components: {
            TheFavoritesHeading,
            Preloader,
            SortGridIcon,
            SortListIcon,
            SortSelectIcon,
        },
        data() {
            return {
                selected: '0',
                options: [
                    { text: 'По популярности', value: '0' },
                    { text: 'По цене (сначала дешевле)', value: '1' },
                    { text: 'По цене (сначала дороже)', value: '2' },
                    { text: 'По производителям', value: '3' }
                ],
                isActive: 1,
                isList: false,
            }
        },
        methods: {
            ...mapActions('favorites', {
                loadCheckout: 'loadCheckout',
                clean: 'clean',
                setFavoritePage: 'setFavoritePage',
                initializationFavorites: 'initializationFavorites'
            }),
            sortBy: function() {
                this.initializationFavorites({sort: this.selected, isList: this.isList});
            },
            setToGrid: function () {
                this.isActive = 1;
                this.isList = false;
                let items = document.querySelector('[data-favorites-list-items]');
                items.classList.remove('product-list__items_list');
            },
            setToList: function () {
                this.isActive = 2;
                this.isList = true;
                let items = document.querySelector('[data-favorites-list-items]');
                items.classList.add('product-list__items_list');
            }
        },
        computed: {
            ...mapState('favorites', {
                checkout: 'checkout',
            }),
            ...mapGetters('favorites', {
                count: 'count'
            })
        },
        mounted() {
            this.setFavoritePage(true);
        }
    }

</script>

<style lang="scss">

</style>