<template>
    <div>
        <VueJsDatepicker
            :language="lang"
            :inline="true"
            :value="date"
            :mondayFirst="true"
            :disabled-dates="disabledDates"
            @selected="setDate"
            @changedMonth="changeMonth"
        >
        </VueJsDatepicker>
    </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import VueJsDatepicker from 'vuejs-datepicker';
  import ru from 'vuejs-datepicker/dist/locale/translations/ru';

  export default {
    name: "TheDatePicker",
    components: {
      VueJsDatepicker
    },
    props: {
      disabledDates: Object,
    },
    data() {
      return {
        lang: ru
      }
    },
    computed: {
      ...mapState('schedule', [
        'date'
      ]),
      ...mapGetters('schedule', [
        'timestamp'
      ])
    },
    methods: {
      ...mapActions('schedule', [
        'setDate'
      ]),
      changeMonth(e) {
        if (e.timestamp) {
          this.$emit('changeMonth', new Date(e.timestamp));
        } else {
          this.$emit('changeMonth', e);
        }
      }
    }
  }
</script>

<style lang="scss">

</style>