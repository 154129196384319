import Vue from 'vue';
import FavoritesService from "../../services/favorites.service";

const favoritesModule = {
    namespaced: true,
    state: {
        loading: false,
        checkout: null,
        isFavoritePage: false
    },
    getters: {
        count: (state) => state.checkout ? state.checkout.items.length : 0
    },
    actions: {
        async add({ state, dispatch, commit }, { chosen, id }) {
            try {
                const result = await FavoritesService.add(chosen, id);
                chosen = chosen.replace(/\\/gi, '\\\\');
                document.querySelectorAll(`[data-chosen="${chosen}"][data-id="${id}"]`).forEach((item) => {
                    item.dataset.selected = result.data.key;
                });

                dispatch('fetchCheckout');
                dispatch('notification/showSuccessNotification', 'Товар успешно добавлен в избранное', {root:true});
            } catch (e) {
                dispatch('notifyError', e);
            }
        },
        async remove({ state, dispatch, commit, getters }, { key }) {
            try {
                const result = await FavoritesService.remove(key);
                document.querySelectorAll(`[data-selected="${key}"]`).forEach((item) => {
                    item.dataset.selected = '';
                });

                await dispatch('fetchCheckout');
                dispatch('notification/showErrorNotification', 'Товар удалён из избранного', {root:true});

                if (state.isFavoritePage) {
                    if (!getters.count) {
                        window.location.reload();
                    }
                }
            } catch (e) {
                dispatch('notifyError', e);
            }
        },
        async clean({ state, dispatch, commit }) {
            try {
                const result = await FavoritesService.clean();
                dispatch('fetchCheckout');
                window.location.reload();
            } catch (e) {
                dispatch('notifyError', e);
            }
        },
        async loadCheckout({ state, dispatch, commit }) {
            if (!state.checkout && !state.loading) {
                commit('setLoading', true);
                await dispatch('fetchCheckout');
                commit('setLoading', false);
            }
        },
        async fetchCheckout({ commit }) {
            try {
                const result = await FavoritesService.fetchCheckout();
                commit('setCheckout', result.data);
            } catch (e) {
                console.log(e);
            }
        },
        notifyError({ state, dispatch, commit }, error) {
            const errorMessage = error.response.data.detail;
            dispatch('notification/showErrorNotification', errorMessage, {root:true});
        },
        setFavoritePage({commit}, isFavoritePage) {
            commit('setFavoritePage', isFavoritePage)
        },
        async initializationFavorites ({ state, dispatch, commit }, params) {
            await FavoritesService.updateFavoritesList(params);
        },
    },
    mutations: {
        setCheckout(state, data) {
            Vue.set(state, 'checkout', data);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setFavoritePage(state, isFavorite) {
            state.isFavoritePage = isFavorite;
        }
    },
};

export default favoritesModule;