function toTopCheck() {
    const toTopElement = document.querySelector('[data-to-top]');
    document.addEventListener("scroll", () => {
        if (window.scrollY > window.innerHeight / 2) {
            toTopElement.classList.add('visible');
        } else {
            toTopElement.classList.remove('visible');
        }
    });
}
toTopCheck();
