<template>
  <div class="steps-record-for-service" :class="classes">
    <Notifications/>

    <TheStepsServiceTabs :active-step="activeStep"
                         :steps="steps"
                         @setActiveStep="activeStep = $event"
                         v-show="activeStep !== 'success'"/>

    <TheStepsServicesList v-show="activeStep === 'service'"/>

    <TheStepsPointsList v-show="activeStep === 'points'"/>

    <TheStepsDateTime v-show="activeStep === 'date-time'"/>

    <TheStepsContacts v-show="activeStep === 'contacts'" @submitSuccess="activeStep='success'"/>

    <TheStepsResult v-show="activeStep === 'success'"/>

    <div class="steps-record-for-service__buttons" v-show="activeStep !== 'success'">
      <a href="javascript:void(0);"
         class="steps-record-for-service__prev button button_transparent"
         v-show="activeStep !== 'service'"
         @click="prevStep"
      >
        <arrow-left/>

        Назад
      </a>

      <button
         class="steps-record-for-service__next button"
         v-show="activeStep !== 'contacts'"
         :disabled="!isCurrentStepSelected"
         @click.prevent="nextStep"
      >
        Далее
      </button>

      <a href="javascript:void(0);"
         class="steps-record-for-service__submit button"
         v-show="activeStep === 'contacts'"
         @click.prevent="submit"
      >
        Записаться
      </a>
    </div>

    <div class="steps-record-for-service__policy-wrap" v-show="activeStep === 'contacts'">
      <div class="steps-record-for-service__policy policy">
          Подтверждая запись вы соглашаетесь с&nbsp;<a :href="'/uploads/co/' + policyFilePath" class="policy__link">политикой конфиденциальности</a>
      </div>
    </div>

    <TheServiceResult class="steps-record-for-service__result" v-show="activeStep === 'contacts'"/>
  </div>
</template>

<script>
import TheStepsServiceTabs from "./steps/TheStepsServiceTabs.vue";
import TheStepsServicesList from "./steps/TheStepsServicesList.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import ArrowLeft from "../icons/ArrowLeft.vue";
import TheStepsPointsList from "./steps/TheStepsPointsList.vue";
import TheStepsDateTime from "./steps/TheStepsDateTime.vue";
import TheStepsContacts from "./steps/TheStepsContacts.vue";
import TheServiceResult from "./TheServiceResult.vue";
import Notifications from "../Notification/Notifications.vue";
import TheStepsResult from "./steps/TheStepsResult.vue";

export default {
  name: "TheStepsScheduleUserService",
  components: {
    TheStepsResult,
    Notifications,
    TheServiceResult,
    TheStepsContacts,
    TheStepsDateTime, TheStepsPointsList, ArrowLeft, TheStepsServicesList, TheStepsServiceTabs},
  props: {
    currentId: Number,
  },
  data() {
    return {
      activeStep: 'service',
      steps: [
        {
          id: 'service',
          name: 'Услуга',
        },
        {
          id: 'points',
          name: 'Сервисный центр',
        },
        {
          id: 'date-time',
          name: 'Дата и время',
        },
        {
          id: 'contacts',
          name: 'Контактные данные',
        },
      ]
    }
  },
  computed: {
    ...mapState('schedule', [
      'loading',
      'currentServiceId',
      'activeService',
      'selectedServiceCenterId',
      'selectedSlot'
    ]),
    classes() {
      return {
        _loading: this.loading,
        _service: this.activeStep === 'service',
        _point: this.activeStep === 'point',
        _time: this.activeStep === 'time',
        _contacts: this.activeStep === 'contacts',
      }
    },
    policyFilePath: {
      get () {
        return this.$store.state.policyFilePathModule.policyFilePath
      },
      set (value) {
        this.$store.commit('order/SetPolicyFilePath', value)
      }
    },
    isCurrentStepSelected() {
      let currentSelectedValue = null;

      switch (this.activeStep) {
        case 'service':
          currentSelectedValue = this.activeService !== {};
          break;
        case 'points':
          currentSelectedValue = this.selectedServiceCenterId !== null;
          break;
        case 'date-time':
          currentSelectedValue = (this.selectedSlot !== null || false);
      }

      return currentSelectedValue;
    },
  },
  methods: {
    ...mapActions('schedule', {
      'init': 'init',
    }),
    ...mapMutations('schedule', [
      'setCurrentServiceId'
    ]),
    ...mapActions('policyFilePathModule', {
      'fetch': 'fetch'
    }),
    prevStep() {
      const currentStepObject = this.steps.find(step => step.id === this.activeStep);
      const currentStepIndex = this.steps.indexOf(currentStepObject);
      this.activeStep = Object.values(this.steps)[currentStepIndex - 1].id;
    },
    nextStep() {
      const currentStepObject = this.steps.find(step => step.id === this.activeStep);
      const currentStepIndex = this.steps.indexOf(currentStepObject);
      this.activeStep = Object.values(this.steps)[currentStepIndex + 1].id;
    },
    submit() {
      this.$root.$emit('submitRecord');
      window.goalManager.proceedYandexGoal('success_order_allForm');
      window.goalManager.proceedVkGoal('success_order_allForm');
    }
  },
  beforeMount() {
    if(this.currentId) {
      this.setCurrentServiceId(this.currentId);
    }
  },
  mounted() {
    this.init();
    this.fetch();
  },
}
</script>

<style lang="scss">

</style>