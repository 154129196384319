const TypeSwitcherModule = {
  namespaced: true,
  state: {
    loaded: false,
    loading: true,
    types: {
      tires: {
        name: 'Шины',
        icon: 'SwitchTyreIcon'
      },
      rims: {
        name: 'Диски',
        icon: 'SwitchWheelIcon'
      }
    },
    current: 'tires',
  },
  actions: {
    // Сейчас инициализация запускается от подборщика по машине
    // При инициализации можно передать ключ 'rims', что бы все началось с дисков
    async init({ state, commit, dispatch }, key) {
      commit('setLoading', true)
      if (key) {
        await commit('setCurrent', key);
      }
      commit('setLoaded');

      // Инициализируем фильтр по размерам
      await dispatch('sizesFilterModule/init', {}, {root: true});
      commit('setLoading', false);
    },
    async setCurrent({ commit, dispatch }, key) {
      await commit('setCurrent', key);
      commit('setLoading', true)
      await dispatch('sizesFilterModule/changeTypeEvent', null, {root: true});
      commit('setLoading', false);
    }
  },
  getters: {
    isCurrent: state => key => state.current === key,
    current: state => state.current
  },
  mutations: {
    setCurrent(state, key) {
      state.current = key;
    },
    setLoaded(state) {
      state.loaded = true;
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  }
};

export default TypeSwitcherModule;