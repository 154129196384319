import OrderService from "../../services/order.service";

const orderModule = {
  namespaced: true,
  state: {
    name: '',
    phone: '',
    email: '',
    receiving: 10,
    payment: 10,
    address: '',
    pickupPoint: null,
    offBonus: 0,
    comment: '',
    policyFilePath: null,
    errors: {
      name: [],
      phone: [],
      email: [],
      address: []
    },
    redirectBaseUrl: '/cart/success'
  },
  getters: {
    isPickup(state) {
      return state.receiving === 10;
    },
    isDelivery(state) {
      return state.receiving === 20;
    },
  },
  actions: {
    async send({ state, dispatch, commit }) {
      try {
        dispatch('cart/orderGoals', {root:true});
        const result = await OrderService.save(state);
        dispatch('redirectToSuccessPage', result)
        return true;
      } catch (error) {
        const errors = error.response.data.errors;
        commit('setErrors', errors);
      }
      return false;
    },
    redirectToSuccessPage({state, dispatch, commit}, result) {
      window.location.pathname = `${state.redirectBaseUrl}/${result.data.order_id}`;
      /*commit('reset');
      dispatch('notification/showSuccessNotification', 'Заказ успешно оформлен', {root:true});
      dispatch('cart/fetchCheckout', null, {root:true});*/
    }
  },
  mutations: {
    setName(state, value) {
      state.name = value;
    },
    setPhone(state, value) {
      state.phone = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setComment(state, value) {
      state.comment = value;
    },
    setPayment(state, value) {
      state.payment = value;
    },
    setReceiving(state, value) {
      state.receiving = value;
    },
    setAddress(state, value) {
      state.address = value;
    },
    SetPickupPoint(state, value) {
      state.pickupPoint = value;
    },
    SetPolicyFilePath(state, value) {
      state.policyFilePath = value;
    },
    cleanErrors(state) {
      state.errors = {
        name: [],
        phone: [],
        email: [],
        address: []
      }
    },
    reset(state) {
      Object.assign(state, {
        name: '',
        phone: '',
        email: '',
        address: '',
        pickupPoint: '',
        delivery: 10,
        payment: 10,
        offBonus: 0,
        comment: '',
        policyFilePath: null,
        errors: {
          name: [],
          phone: [],
          email: [],
          address: []
        }
      });
    }
  },
};

export default orderModule;