<template>
  <div class="cart-receiving-delivery">
    <div class="cart-contacts__input-item-container">
      <label for="address" class="cart-contacts__input-label required">
        Адрес
      </label>
      <input type="text" class="cart-contacts__input required" v-model="address" id="address"
             name="address" required="required" placeholder="Город, улица, дом, квартира/офис...">
      <ItemErrors :errors="errors.address"/>
    </div>
    <div class="cart-receiving-delivery__delivery-description">
      Отправляем заказы в любой регион России всеми транспортными компаниями.
      Подобрать подходящую вам поможет наш менеджер после оформления заказа
      <br>
      Доставка по Кирову временно не доступна
    </div>
  </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import ItemErrors from "./ItemErrors";

export default {
  name: "TheCartComponentDelivery",
  components: {ItemErrors, TheMask},
  props: {},
  computed: {
    address: {
      get () {
        return this.$store.state.order.address
      },
      set (value) {
        this.$store.commit('order/setAddress', value)
      }
    },
    errors: {
      get () {
        return this.$store.state.order.errors
      },
    }
  }
}
</script>

<style lang="scss">

</style>