<template>
    <div class="record-for-service-result">
        <div class="record-for-service-result__top-container">
            <div class="record-for-service-result__result-wrapper">
                <ul class="record-for-service-result__items">
                    <li class="record-for-service-result__item" v-show="activeService.name">
                        <span class="record-for-service-result__item-title">
                            Услуга
                        </span>
                        <span class="record-for-service-result__item-subtitle">
                            {{ activeService.name }}
                        </span>
                    </li>
                    <li class="record-for-service-result__item" v-show="selectedServiceCenter.address">
                        <span class="record-for-service-result__item-title">
                            Сервисный центр
                        </span>
                        <span class="record-for-service-result__item-subtitle">
                            {{ selectedServiceCenter.address }}
                        </span>
                    </li>
                    <li class="record-for-service-result__item" v-show="humanizeDate && timeString">
                        <span class="record-for-service-result__item-title">
                            Дата и время
                        </span>
                        <span class="record-for-service-result__item-subtitle">
                            {{ humanizeDate }} {{ timeString }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="record-for-service-result__description-container">
              Стоимость услуги определяется специалистом в зависимости от объема и срочности работы
            </div>
        </div>
        <div class="record-for-service-result__bottom-container">
            <a href="/modal-information-consultation" title="Помощь с записью" class="record-for-service-result__help link-icon link-icon_big" data-modal>
                <QuestionIcon/>
                <span>Помощь с записью</span>
            </a>
        </div>
    </div>
</template>

<script>
  import QuestionIcon from "../icons/QuestionIcon.vue";
  import {mapGetters, mapState} from "vuex";
  import Modal from "../../../components/modal/modal";

  export default {
    name: "TheServiceResult",
    components: {
      QuestionIcon
    },
    props: {

    },
    data() {
      return {

      }
    },
    methods: {
      // deprecated
      // openModal: (e) => {
      //   e.preventDefault();
      //   const link = e.target;
      //   const modal = new Modal(link, {
      //     closerText: '',
      //     onSubmit: () => {}
      //   });
      //   return false;
      // }
    },
    computed: {
      ...mapGetters('schedule', {
        selectedServiceCenter: 'selectedServiceCenter'
      }),
      ...mapState('schedule', [
        'activeService',
        'date',
        'selectedSlot'
      ]),
      humanizeDate() {
        const formatter = new Intl.DateTimeFormat("ru", {
          day: "numeric",
          month: "long"
        });
        return formatter.format(this.date);
      },
      timeString() {
        if(this.selectedSlot == null) {
          return '';
        }
        return `в ${this.selectedSlot.verbose}`
      }
    },
    watch: {

    }
  }
</script>

<style lang="scss">

</style>