import SidebarAutoTyreService from '../../../services/sidebar/sidebarAutoTyre.service';
import Sidebar from './sidebar.module';
import filterFieldClass from "../../../classes/filterFieldClass";


const SidebarAutoTyreModule = Object.assign({}, Sidebar);

const fields = {
  studdeds: new filterFieldClass({
    label: 'Шипованность',
    type: 'bool-checkboxes',
    requestFieldName: 'studded',
  }),
  producers: new filterFieldClass({
    label: 'Производители',
    type: 'slug-checkboxes',
    requestFieldName: 'producers',
  }),
};

const state = {
  service: SidebarAutoTyreService,
  name: 'sidebarAutoTyreModule',
  topFilterModuleName: 'topParamAutoTyreModule',
  paramInfo: fields
};

SidebarAutoTyreModule.state = {...SidebarAutoTyreModule.state, ...state};

export default SidebarAutoTyreModule;