import SidebarAutoWheelService from '../../../services/sidebar/sidebarAutoWheel.service';
import Sidebar from './sidebar.module';
import filterFieldClass from "../../../classes/filterFieldClass";

const SidebarAutoWheelModule = Object.assign({}, Sidebar);

const fields = {
  widths: new filterFieldClass({
    label: 'Ширина',
    type: 'checkboxes',
    requestFieldName: 'widths',
  }),
  producers: new filterFieldClass({
    label: 'Производители',
    type: 'slug-checkboxes',
    requestFieldName: 'producers',
  }),
};

const state = {
  service: SidebarAutoWheelService,
  name: 'sidebarAutoWheelModule',
  topFilterModuleName: 'topParamAutoWheelModule',
  paramInfo: fields
};

SidebarAutoWheelModule.state = {...SidebarAutoWheelModule.state, ...state};

export default SidebarAutoWheelModule;