import topFilterTruckTyreService from '../../../services/topFilter/topFilterTruckTyre.service'
import topFilterModule from './topFilter.module';
import filterFieldClass from "../../../classes/filterFieldClass";
import TopFilterMotoTyreModule from "./topFilterMotoTyre.module";

// Делаем копию модуля с общей функциональностью
const TopFilterTruckTyreModule = Object.assign({}, topFilterModule);

/*
  Создаем поля для фильтра
  type: select|checkboxes|slug-select
  requestFieldName - название поля, по которому будут отправляться данные этого поля
  orderFill - порядок заполнения данных с урла, начиная с конца
  currentValue - значение по умолчанию
 */
const fields = {
  types: new filterFieldClass({
    type: 'checkboxes',
    requestFieldName: 'types',
    orderFill: 5
  }),
  widths: new filterFieldClass({
    label: 'Ширина',
    type: 'select',
    requestFieldName: 'width',
    currentValue: 'all',
    orderFill: 4
  }),
  heights: new filterFieldClass({
    label: 'Высота',
    type: 'select',
    requestFieldName: 'height',
    currentValue: 'all',
    orderFill: 3
  }),
  diameters: new filterFieldClass({
    label: 'Диаметр',
    type: 'select',
    requestFieldName: 'diameter',
    currentValue: 'all',
    orderFill: 2,
  }),
  axes: new filterFieldClass({
    type: 'checkboxes',
    requestFieldName: 'axes',
    orderFill: 1,
  }),
  producers: new filterFieldClass({
    label: 'Производитель',
    type: 'slug-select',
    requestFieldName: 'producer',
    currentValue: 'all',
    orderFill: 0,
  }),
};

const state = {
  // Назначаем сервис для апи
  service: topFilterTruckTyreService,
  // Передаем название модуля связанного сайдбара
  sidebarModuleName: 'sidebarTruckTyreModule',
  paramInfo: fields
};

TopFilterTruckTyreModule.state = {...TopFilterTruckTyreModule.state, ...state};

export default TopFilterTruckTyreModule;