import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  const closerGoal = link.dataset.closerGoal ? link.dataset.closerGoal : '';
  const theme = link.dataset.theme ? link.dataset.theme : 'default';
  let modal = new Modal(this, {
    closerText: '',
    theme,
    closerGoal,
    onFormSuccess: function () {
      const submitEvent = new Event('modal-form:success');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
      window.goalManager.proceedYandexGoal('success_order_allForm');
    },
  });
  return false;
});

new LiveEvent('click', '[data-modal-consultation]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      const submitEvent = new Event('modal-form:success');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
      window.goalManager.proceedYandexGoal('success_order_allForm');
    },
    onSubmit: () => {}
  });
  return false;
});