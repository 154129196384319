<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.08 1L14.1068 7.79023L21.5 8.57054L15.9774 13.5474L17.5199 20.8199L11.08 17.1056L4.64015 20.8199L6.18266 13.5474L0.660084 8.57054L8.0533 7.79023L11.08 1ZM11.08 5.91239L9.41039 9.65811L5.33204 10.0886L8.37848 12.834L7.52758 16.8457L11.08 14.7968L14.6325 16.8457L13.7816 12.834L16.828 10.0886L12.7497 9.65811L11.08 5.91239Z" fill="#B6BCCA"/>
    </svg>
</template>

<script>
export default {
  name: "CartFavouritesIcon"
}
</script>