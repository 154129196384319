<template>
    <div class="record-for-service-choose tabs-mini tabs-mini_gray">
        <ul class="record-for-service-choose__items">
            <li class="record-for-service-choose__item" v-for="service in services" :class="{ _active: isActive(service) }">
                <a :href="`#service-` + service.id" @click="setActive(service)">{{ service.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';

  export default {
    name: "TheServices",
    methods: {
      ...mapActions('schedule', {
        'setActive': 'setActiveService'
      }),
      isActive(service) {
        return this.activeService === service;
      },
      setActive(service) {
        if(service !== this.activeService) {
          this.$store.dispatch('schedule/setActiveService', service);
        }
      },
    },
    computed: {
      ...mapState('schedule', [
        'services',
        'activeService'
      ])
    }
  }
</script>

<style lang="scss">

</style>