import topFilter from "./topFilter.service";

const topFilterMotoTyreService = {
  async fetchPossibleValues(params) {
    return topFilter.fetchPossibleValues('/catalog/api/moto/tyre/filter-preparation', params);
  },
  async searchProducts(params) {
    return topFilter.searchProducts('/catalog/api/moto/tyre/search', params);
  },
};

export default topFilterMotoTyreService;
