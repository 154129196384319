import {Accordion} from "accordion";

const defaultAccordion = () => {
  document.querySelectorAll('[data-accordion]').forEach((element) => {
    new Accordion(element);
  });
};

let mobileAccordions = [];

const mobileAccordion = () => {
  document.querySelectorAll('[data-accordion-mobile]').forEach((element) => {
    let mediumBreakpoint = getComputedStyle(document.documentElement).getPropertyValue('--medium-breakpoint-value');
    if (window.innerWidth < mediumBreakpoint) {
      mobileAccordions.push(new Accordion(element));
    } else {
      mobileAccordions.push(new Accordion(element, {
        disabled: true,
      }));
    }
  });
};

window.addEventListener('load', () => {
  defaultAccordion();
  mobileAccordion();
});

window.addEventListener('resize', () => {
  let mediumBreakpoint = getComputedStyle(document.documentElement).getPropertyValue('--medium-breakpoint-value');
  mobileAccordions.forEach((accordion) => {
    accordion.disabled = window.innerWidth > mediumBreakpoint;
  })
});

window.addEventListener('changeAccordionContent', () => {
  mobileAccordions.forEach((accordion) => {
    accordion.update();
  })
})

