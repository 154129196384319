import PriceCompetitorService from "../../../services/priceCompetitor.service";

const PriceCompetitorFilter = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    isLastPage: false,
    settings: {
      width: null,
      height: null,
      diameter: null,
      season: null,
      producer: null,
      available: null,
      lowPrices: false,
      page: 1,
    },
    fields: {
      width: [],
      height: [],
      diameter: [],
      producer: [],
      season: [],
    },
    // mode: 'param', // param|auto
    // currentOrder: null,
    // hidePrice: false,
    // columns: [
    //   {name: 'Производитель', key: 'producer'},
    //   {name: 'Наименование', key: 'name'},
    //   {name: 'РРЦ', key: 'rrc', sort: true, name_alt: 'Цена'},
    //   {name: 'Склады', key: 'storage'},
    //   {name: 'В наличии', key: 'quantity', sort: true},
    //   {name: 'Цена', key: 'price', sort: true},
    //   {name: 'Количество', key: 'order'},
    // ],
  },
  getters: {
    // isParamFilter: (state) => state.mode === 'param',
    // isAutoFilter: (state) => state.mode === 'auto',
    // hasAutoTab: (state) => state.settings.hasAuto,
    // productType: (state) => state.settings.productType,
  },
  mutations: {
    // setMode(state, mode) {
    //   state.mode = mode;
    // },
    // setSettings(state, settings) {
    //   state.settings = settings;
    // },
    setWidthValues(state, data) {
      state.fields.width = data;
    },
    setHeightValues(state, data) {
      state.fields.height = data;
    },
    setDiameterValues(state, data) {
      state.fields.diameter = data;
    },
    setSeasonValues(state, data) {
      state.fields.season = data;
    },
    setProducerValues(state, data) {
      state.fields.producer = data;
    },
    setPage(state, data) {
      state.settings.page = data;
    },
    setTrueIsLastPage(state) {
      state.isLastPage = true;
    },
    setFalseIsLastPage(state) {
      state.isLastPage = false;
    },
    // setLoading(state, loading) {
    //   state.loading = loading;
    // },
    // setLoaded(state, loaded) {
    //   state.loaded = loaded;
    // },
    // setCurrentOrder(state, order) {
    //   state.currentOrder = order;
    // },
    // setHidePrice(state, hidePrice) {
    //   state.hidePrice = hidePrice;
    // }
  },
  actions: {
    async fetchFilterParams({ state, dispatch, commit, rootState }) {
      try {
        dispatch('fetchWidths');
        dispatch('fetchHeights');
        dispatch('fetchDiameters');
        dispatch('fetchSeasons');
        dispatch('fetchProducers');
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWidths({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchWidths();
        commit('setWidthValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchHeights({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchHeights();
        commit('setHeightValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDiameters({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchDiameters();
        commit('setDiameterValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSeasons({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchSeasons();
        commit('setSeasonValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProducers({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchProducers();
        commit('setProducerValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    // async initFilter({ state, commit, dispatch }, settings) {
    //   if (!state.loading && !state.loaded) {
    //     commit('setLoading', true);
    //     commit('setSettings', settings);
    //     await dispatch('filterParam/loadPossibleFields', {}, { root: true });
    //     commit('setLoading', false);
    //     commit('setLoaded', true);
    //   }
    // },
    // replaceCatalogContent({}, html) {
    //   const page = document.createElement('div');
    //   page.innerHTML = html;
    //   const newData = page.querySelector('[data-catalog-list]');
    //   const currentData = document.querySelector('[data-catalog-list]');
    //   currentData.innerHTML = newData.innerHTML;
    // },
    // async setOrder({state, commit, dispatch}, order) {
    //   commit('setCurrentOrder', order);
    //   if (state.mode === 'param') {
    //     await dispatch('filterParam/fetchProducts', {}, { root: true });
    //   }
    // },
    // updateHidePrice({ commit }, value) {
    //   const catalogElement = document.querySelector('[data-catalog-list-block]');
    //   if (catalogElement) {
    //     if (value) {
    //       catalogElement.classList.add('_hide-price');
    //     } else {
    //       catalogElement.classList.remove('_hide-price');
    //     }
    //   }
    //   commit('setHidePrice', value);
    // }
  },
};

export default PriceCompetitorFilter;