<template>
    <div>
        <label v-if="label" :for="fieldId">
            {{ label }}
        </label>

        <select :id="fieldId" @change="change" :value="currentValue" :disabled="disabled">
            <option v-if="!noDefault" :value="defaultValue.value">{{ defaultValue.name }}</option>
            <option v-for="value in values"
                    :value="valueAsString(value)"
                    :disabled="checkDisabled(valueAsString(value))">
                {{ nameAsString(value) }}
            </option>
        </select>
    </div>
</template>

<script>
  export default {
    name: "BaseFieldSelect",
    props: {
      // Уникальный id селекта
      fieldId: {
        type: String,
        required: true
      },
      /*
        Все возможные значения селекта
        а) список строк
        б) список объектов с полями 'name' и 'value|slug'
       */
      values: {
        type: Array,
        required: true
      },
      currentValue: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      // Список недоступных значений из списка всех значений
      disableValues: {
        type: Array,
        required: false
      },
      // Лейбл. Отображается при наличии
      label: {
        type: String,
        required: false
      },
      // true - не добавлять 'all' => 'Все'
      noDefault: {
        type: Boolean,
        required: false,
        default: false
      },
      defaultValue: {
        type: Object,
        required: false,
        default: () => {
          return {
            name: 'Все',
            value: 'all'
          }
        }
      }
    },
    methods: {
      change(event) {
        // При изменении инпута передаем событие 'input', что бы связанный объект по v-mode принял изменения
        this.$emit('input', event.target.value ? event.target.value : null);
      },
      valueAsString(item) {
        // TODO позже нормально будет)
        if (item.slug !== undefined) {
          return item.slug;
        }
        if (item.id !== undefined) {
          return item.id;
        }
        if (item.value !== undefined) {
          return item.value;
        }

        return item;
      },
      nameAsString(item) {
        let fullName = undefined;
        if (item.name) {
          fullName = item.name;
          if (item['yearFrom'] && item['yearTo']) {
            fullName += ` (${item['yearFrom']} - ${item['yearTo']})`;
          }
        }
        return fullName === undefined ? item : fullName;
      },
      checkDisabled(value) {
        return this.disableValues ? this.disableValues.includes(value) : false;
      }
    }
  }
</script>