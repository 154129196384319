<template>
    <transition-group name="notifications-list" class="notifications">
        <div class="notification" v-for="(notification, key) in notifications" :key="notification.key" :class="['notification_' + notification.type]">
            <div class="notification__text">
                {{ notification.text }}
            </div>
            <a class="notification__close" href="javascript:void(0);" @click.prevent="removeNotification(key)">
                &times;
            </a>
        </div>
    </transition-group>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: "Notifications",
    computed: {
      ...mapState('notification', [
        'notifications'
      ])
    },
    methods: {
      ...mapMutations('notification', [
        'removeNotification'
      ])
    }
  }
</script>

<style lang="scss">

</style>