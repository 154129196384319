<template>
    <div class="record-for-service-contacts">
        <ul class="record-for-service-contacts__input-list">
            <li class="record-for-service-contacts__input-item">
                <div class="record-for-service-contacts__input-item-container">
                    <label :for="contacts.name.id" class="record-for-service-contacts__input-label required">
                        Контактное лицо
                    </label>
                    <input type="text" class="record-for-service-contacts__input required" v-model="contacts.name.value"
                           :id="contacts.name.id"
                           :name="contacts.name.fieldName"
                           required="required">
                    <ItemErrors :errors="contacts.name.errors"/>
                </div>
            </li>
            <li class="record-for-service-contacts__input-item record-for-service-contacts__input-item_phone">
                <div class="record-for-service-contacts__input-item-container">
                    <label :for="contacts.phone.id" class="record-for-service-contacts__input-label required">
                        Телефон
                    </label>
                    <TheMask
                            :mask="'+7 (###) ###-##-##'"
                            :masked="true"
                            type="text"
                            v-model="contacts.phone.value"
                            class="cart-contacts__input required"
                            id="phone"
                            name="phone"
                            required="required"
                    />
                    <ItemErrors :errors="contacts.phone.errors"/>
                </div>
            </li>
            <li class="record-for-service-contacts__input-item">
                <div class="record-for-service-contacts__input-item-container">
                    <label for="mail" class="record-for-service-contacts__input-label required">
                        Марка и гос.номер авто
                    </label>
                    <input type="text" class="record-for-service-contacts__input required" v-model="contacts.carAndLicensePlateNumber.value"
                           :id="contacts.carAndLicensePlateNumber.id"
                           :name="contacts.carAndLicensePlateNumber.name">
                    <ItemErrors :errors="contacts.carAndLicensePlateNumber.errors"/>
                </div>
            </li>
            <li class="record-for-service-contacts__input-item record-for-service-contacts__input-item_car-type">
                <div class="record-for-service-contacts__input-item-container">
                    <label class="form-input__label" :for="contacts.carType.id">
                        Тип автомобиля:
                    </label>
                    <select :id="contacts.carType.id" :name="contacts.carType.fieldName" v-model="contacts.carType.value">
                        <option
                                v-for="(carType, key) in carTypes"
                                :value="key"
                        >
                            {{ carType }}
                        </option>
                    </select>
                </div>
            </li>
        </ul>
        <div class="record-for-service-contacts__info">
            * Поля со звёздочкой обязательны
        </div>
    </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import InputMask from 'vue-input-mask';
  import ItemErrors from "../cart/ItemErrors.vue";
  import ScheduleService from "../../services/schedule.service";
  import {TheMask} from 'vue-the-mask'

  export default {
    name: "TheServiceForm",
    components: {
      ItemErrors,
      InputMask,
      TheMask
    },
    methods: {
      ...mapActions({
        'showErrorNotification': 'notification/showErrorNotification',
        'showSuccessNotification':'notification/showSuccessNotification',
        'loadGraph': 'schedule/loadGraph'
      }),
      ...mapMutations('schedule', {
        'setLoading': 'setLoading',
        'unsetLoading': 'unsetLoading',
      }),
      async submit() {
        if(!this.selectedSlot) {
          this.showErrorNotification('Не выбрано время записи')
          return null;
        }

        const digits = this.contacts.phone.value.replace(/[^0-9]/g, '');
        if (digits.length < 11) {
          this.showErrorNotification('Введите номер телефона полностью!');
          return null;
        }
        if (!this.contacts.carAndLicensePlateNumber.value) {
          this.showErrorNotification('Укажите марку и гос.номер авто!');
          return null;
        }
        if (!this.contacts.name.value) {
          this.showErrorNotification('Укажите контактное лицо!');
          return null;
        }

        this.setLoading();

        try {
          const response = await ScheduleService.saveRecord({
            name: this.contacts.name.value,
            date: this.selectedSlot.dateTime,
            postId: this.selectedSlot.postId,
            serviceId: this.activeService.id,
            phone: this.contacts.phone.value,
            carAndLicensePlateNumber: this.contacts.carAndLicensePlateNumber.value,
            carType: parseInt(this.contacts.carType.value, 10)
          });
          const message = response.data.message;
          this.showSuccessNotification(message);
          this.$emit('submitSuccess');
          this.clean();
          this.loadGraph();

          window.goalManager.proceedYandexGoal('submit_sign_up_for_the_service');
          window.goalManager.proceedVkGoal('submit_sign_up_for_the_service#1000');

        } catch (e) {
          this.cleanErrors();
          this.prepareErrors(e.response.data.errors);
          this.showErrorNotification('Имеются ошибки');
          this.unsetLoading();
        }
      },
      prepareErrors(errors) {
        for (const error in errors) {
          if(errors.hasOwnProperty(error)) {
            switch (error) {
              case 'carAndLicensePlateNumber':
                this.contacts.carAndLicensePlateNumber.errors = errors[error];
                break;
              case 'name':
                this.contacts.name.errors = errors[error];
                break;
              case 'phone':
                this.contacts.phone.errors = errors[error];
                break;
            }
          }
        }
      },
      clean() {
        this.cleanErrors();
        // this.cleanFields();
      },
      cleanErrors() {
        this.contacts.carAndLicensePlateNumber.errors = [];
        this.contacts.name.errors = [];
        this.contacts.phone.errors = [];
      },
      cleanFields() {
        this.contacts.carAndLicensePlateNumber.value = '';
        this.contacts.name.value = '';
        this.contacts.phone.value = ' ';
      },
    },
    computed: {
      ...mapState('schedule', [
        'carTypes',
        'selectedSlot',
        'activeService',
        'contacts'
      ])
    },
    watch: {

    },
    mounted() {
      this.$root.$on('submitRecord', () => {
        // your code goes here
        this.submit()
      })
    }
  }
</script>

<style lang="scss">
    .record-for-service-contacts {
        &__input-item {
            padding: 0 11px 15px;
            flex: 0 0 50%;
        }
    }
</style>