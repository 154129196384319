import topFilterAutoTyreService from '../../../services/topFilter/topFilterAutoTyre.service'
import topFilterModule from './topFilter.module';
import filterFieldClass from '../../../classes/filterFieldClass'

// Делаем копию модуля с общей функциональностью
const TopFilterAutoTyreModule = Object.assign({}, topFilterModule);

/*
  Создаем поля для фильтра
  type: select|checkboxes|slug-select
  requestFieldName - название поля, по которому будут отправляться данные этого поля
  orderFill - порядок заполнения данных с урла, начиная с конца
  currentValue - значение по умолчанию
 */
const fields = {
  seasons: new filterFieldClass({
    type: 'checkboxes',
    requestFieldName: 'seasons',
    orderFill: 5
  }),
  widths: new filterFieldClass({
    label: 'Ширина',
    type: 'select',
    requestFieldName: 'width',
    currentValue: 'all',
    orderFill: 4
  }),
  heights: new filterFieldClass({
    label: 'Высота',
    type: 'select',
    requestFieldName: 'height',
    currentValue: 'all',
    orderFill: 3
  }),
  diameters: new filterFieldClass({
    label: 'Диаметр',
    type: 'select',
    requestFieldName: 'diameter',
    currentValue: 'all',
    orderFill: 2,
  }),
  // cargos: new filterFieldClass({
  //   type: 'checkboxes',
  //   requestFieldName: 'cargo',
  //   orderFill: 1,
  // }),
  producers: new filterFieldClass({
    label: 'Производитель',
    type: 'slug-select',
    requestFieldName: 'producer',
    currentValue: 'all',
    orderFill: 0,
  }),
};

const state = {
  // Назначаем сервис для апи
  service: topFilterAutoTyreService,
  // Передаем название модуля связанного сайдбара
  sidebarModuleName: 'sidebarAutoTyreModule',
  paramInfo: fields
};

TopFilterAutoTyreModule.state = {...TopFilterAutoTyreModule.state, ...state};


export default TopFilterAutoTyreModule;