<template>
    <div class="preloader" v-show="loading">
        <div class="preloader__inner">
            <ul class="preloader__bubbles">
                <li class="preloader__bubble"></li>
                <li class="preloader__bubble"></li>
                <li class="preloader__bubble"></li>
            </ul>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Preloader",
    props: {
      loading: Boolean
    }
  }
</script>

<style lang="scss">

</style>