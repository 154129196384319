<template>
    <div>
        <filter-sidebar-layout
            :filter-key="'filter-sidebar-wheel-truck'"
            :loading="loading"
            :fields="getFields"
            :disabled-values="disabledValues"
            v-on:change="filterValues()"
            v-on:submit="updateProductList"/>
    </div>
</template>

<script>
    import FilterSidebarLayout from "./FilterSidebarLayout";
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

    export default {
    name: "TheFilterSidebarWheelTruck",
    components: {FilterSidebarLayout},
    computed: {
      ...mapState('sidebarTruckWheelModule', {
        paramInfo: 'paramInfo',
        loading: 'loading',
      }),
      ...mapGetters('sidebarTruckWheelModule', {
        isCurrentPossible: 'isCurrentPossible',
        getFields: 'getFields',
        disabledValues: 'getDisabledValues'
      }),
    },
    methods: {
      ...mapActions('sidebarTruckWheelModule', {
        filterValues: 'filterValues',
        updateProductList: 'updateProductList'
      }),
    }
  }
</script>