import Sidebar from './sidebar.service'

const SidebarTruckTyreService = {
  async fetchPossibleValues(params) {
    return Sidebar.fetchPossibleValues('/catalog/api/moto/tyre/filter-preparation', params);
  },
  async updateProductList(params) {
    return Sidebar.updateProductList('/catalog/api/moto/tyre/update-list', params);
  },
};

export default SidebarTruckTyreService;