const ParametersModule = {
    namespaced: true,
    state: {
        isChain: false,
    },
    actions: {
        async init({commit, dispatch}) {
            const isChain = await dispatch("getCookie", 'isChain');
            // console.log(isChain)
            commit('setIsChain', isChain === 'true');
        },
        async change({commit, dispatch}, isChain) {
            commit('setIsChain', isChain);
            dispatch("setCookie", isChain);

            //refresh
            location.reload();
        },

        async setCookie({}, isChain){
            document.cookie = `isChain=${isChain};path=/`;
            console.log( document.cookie)
        },
        async getCookie({}, name){
            let matches = document.cookie.match(new RegExp(
                    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }
    },
    getters: {

    },
    mutations: {
        setIsChain(state, isChain) {
            console.log(isChain)
            state.isChain = isChain;
        },

    }
};

export default ParametersModule;