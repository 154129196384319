<template>
    <div class="side-filter" data-side-filter>
        <div class="side-filter__title-block">
            <a href="javascript:void(0);"
               class="side-filter__closer"
               data-toggle-link
               data-selector="[data-side-filter]"
               data-toggle="_open">
                <close-icon/>
            </a>
            <div class="side-filter__title">
                Фильтры
            </div>
        </div>

        <div class="side-filter__wrap">
            <form class="side-filter__form" v-on:submit.prevent="submitForm">
                <ul class="side-filter__sections">
                    <li class="side-filter__section">
                        <div class="side-filter__section-name">
                            <div class="side-filter__label">
                                <label>Цена</label>
                            </div>
                        </div>
                        <ul class="checkbox-list">
                            <price-slider/>
                        </ul>
                    </li>

                    <li class="side-filter__section" v-for="field in fields">
                        <div class="side-filter__section-name">
                            <div class="side-filter__label">
                                <label>{{ field[1].label }}</label>
                            </div>
                            <div class="side-filter__count">

                            </div>
                        </div>

                        <div class="side-filter__field">
                            <div class="side-filter__more-box"
                                 data-more-box
                                 :class="{ _scrollable: (field[1].possibleValues.length > 10)}"
                            >
                                <base-field-checkbox-list
                                        v-if="['checkboxes', 'bool-checkboxes', 'slug-checkboxes'].includes(field[1].type)"
                                        :possible-values="field[1].possibleValues"
                                        :current-values="field[1].currentValue"
                                        :input-id="filterKey + '_' + field[0]"
                                        :disabled-values="disabledValues[field[0]]"
                                        @change="change"
                                />
                            </div>
                            <a href="javascript:void(0);"
                               @click.capture="expand"
                               class="side-filter__more-link link-arrow link-arrow_to-bottom"
                               v-if="field[1].possibleValues.length > 10"
                            >
                              <span class="side-filter__more-link-title side-filter__more-link-title_more">
                                Еще
                              </span>
                              <span class="side-filter__more-link-title side-filter__more-link-title_hide">
                                Скрыть
                              </span>
                            </a>
                        </div>
                    </li>

                    <li class="side-filter__buttons">
                        <button type="submit" class="side-filter__button button button_wide">
                            Применить
                        </button>
                        <button type="reset"
                                class="side-filter__button button button_transparent button_wide"
                                v-on:click.prevent="resetForm()">
                            Сбросить
                        </button>
                    </li>
                </ul>

                <button type="submit"
                        class="side-filter__ninja-button button"
                        ref="ninjaSubmitButton">
                    Применить
                </button>

                <preloader v-if="loading"/>
            </form>

        </div>
    </div>
</template>

<script>
    import Preloader from '../../../Preloader/Preloader';
    import PriceSlider from '../parts/ThePriceSlider';
    import BaseFieldCheckboxList from '../parts/BaseFieldCheckboxList';
    import CloseIcon from "../../../icons/CloseIcon";

    export default {
    name: "FilterSidebarLayout",
    components: {
      Preloader,
      PriceSlider,
      BaseFieldCheckboxList,
      CloseIcon
    },
    props: {
      filterKey: {
        type: String,
        required: true
      },
      fields: {
        type: Array
      },
      loading: {
        type: Boolean,
        required: false
      },
      disabledValues: {
        type: Object,
        required: false
      }
    },
    methods: {
      change(e) {
        this.showSubmitButton(e);
        this.$emit('change', e);
      },
      submitForm() {
        this.$emit('submit');
      },
      resetForm() {
        window.location.reload();
      },
      expand(e) {
        let block;
        if (e.target.tagName === 'SPAN') {
          block = e.target.parentNode.parentNode.querySelector('[data-more-box]');
        } else {
          block = e.target.parentNode.querySelector('[data-more-box]');
        }

        if (block) {
          block.classList.toggle('_open');
        }
      },
      showSubmitButton(e) {
        const target = e.target;
        const parent = target.parentNode;
        const ninjaSubmitButton = this.$refs.ninjaSubmitButton;
        const ninjaParent = ninjaSubmitButton.parentElement;

        const ninjaParentTop = ninjaParent.getBoundingClientRect().top;
        const parentTop = parent.getBoundingClientRect().top;
        let diff = 0;

        if (ninjaParentTop < 0) {
          diff = Math.abs(ninjaParentTop) + Math.abs(parentTop);
        } else {
          diff = Math.abs(parentTop) - Math.abs(ninjaParentTop);
        }

        const offset = (parent.clientHeight / 3) + diff;
        ninjaSubmitButton.style.top = `${offset}px`;
        ninjaSubmitButton.classList.add('_show');

        clearTimeout(this.hideNinjaSubmitButton);
        this.hideNinjaSubmitButton = setTimeout(() => {
          ninjaSubmitButton.classList.remove('_show');
        }, 6000);
      }
    }
  }
</script>

<style lang="scss">

</style>