<template>
    <form class="selection-filter__form" @submit.prevent="submitForm">
        <div class="selection-filter__form-main">

            <slot name="select-fields">
                <ul v-if="selectFields" class="selection-filter__fields">
                    <li class="selection-filter__field" v-for="field in selectFields">
                        <base-field-select
                                class="table-select"
                                :field-id="filterKey + '_' + field[0]"
                                :values="field[1].possibleValues"
                                v-model="field[1].currentValue"
                                :currentValue="field[1].currentValue"
                                :label="field[1].label"
                                :disable-values="disabledValues[field[0]]"
                                @input="change"
                        />
                    </li>
                </ul>
            </slot>

            <slot name="bottom-fields">
                <div class="selection-filter__bottom" :class="{_many: checkboxesFields.length > 1}">

                  <div class="selection-filter__checkboxes-wrapper">
                    <div class="selection-filter__checkboxes" v-for="field in checkboxesFields">
                        <base-field-checkbox-list
                                :input-id="filterKey + '_' + field[0]"
                                :possible-values="field[1].possibleValues"
                                :current-values="field[1].currentValue"
                                :disabled-values="disabledValues[field[0]]"
                                @change="change"
                        />
                    </div>
                  </div>

                  <a v-bind:href="resetLink"
                     class="button button_light selection-filter__clear selection-filter__clear-desktop selection-filter__clear-tyre"
                  > Сбросить фильтр </a>

                </div>
            </slot>

            <div class="selection-filter__button-wrapper">

                <button class="selection-filter__button button" type="submit">
                    Подобрать
                </button>

                <a :href="resetLink"
                   class="button button_light selection-filter__button selection-filter__clear selection-filter__clear-mobile"
                > Сбросить фильтр </a>

            </div>

        </div>

        <preloader v-if="loading"/>
    </form>
</template>

<script>
  import Preloader from "../../../Preloader/Preloader";
  import BaseFieldSelect from "../parts/BaseFieldSelect";
  import BaseFieldCheckbox from "../parts/BaseFieldCheckbox";
  import BaseFieldCheckboxList from "../parts/BaseFieldCheckboxList";

  export default {
    name: "FilterMainLayout",
    components: {
      BaseFieldCheckboxList,
      BaseFieldCheckbox,
      BaseFieldSelect,
      Preloader,
    },
    props: {
      // Уникальный ключ формы для генерации уникальный id инпутов и селектов
      filterKey: {
        type: String,
        required: true
      },
      resetLink: {
        type: String,
        required: true
      },
      // Поля типа 'select' с одиночным выбором
      selectFields: {
        type: Array,
        required: false
      },
      checkboxesFields: {
        type: Array,
        required: false
      },
      // Список полей, у каждого поля список недоступных значений
      disabledValues: {
        type: Object,
        required: false
      },
      loading: {
        type: Boolean,
        required: false
      },
    },
    methods: {
      change(event) {
        /*
            Событие input у BaseFieldSelect дает понять объекту, связанного по v-model, что пора меняться
            В то же время, по событию input у BaseFieldSelect вызывается этот метод, который генерит событие change
            Это даст родителю понять, что какое либо поле изменилось и пора запускать фильтрацию занчений
         */
        this.$emit('change', event);
      },
      submitForm() {
        this.$emit('submit');
      },
    },
  }
</script>

<style lang="scss">

</style>