<template>
    <div class="record-for-service-point">
        <div class="record-for-service-point__container">
            <div class="record-for-service-point__choose-address tabs-mini tabs-mini_gray">
              <ul class="record-for-service-point__items">
                <li class="record-for-service-point__item" v-for="serviceCenter in serviceCenters" :class="{ _active: isActive(serviceCenter) }">
                  <a :href="`#service-address-` + serviceCenter.id" @click.prevent="setActive(serviceCenter)">{{ serviceCenter.address }}</a>
                </li>
              </ul>
            </div>
          <div class="record-for-service-point__title">
            Дата и время
          </div>
            <div class="record-for-service-point__main">
                <div class="record-for-service-point__calendar">
                    <TheDatePicker/>
                </div>
                <ul class="record-for-service-point__time-list">
                    <li class="record-for-service-point__item"
                        v-for="(slot, key) in currentSlots"
                        :class="{'_disabled' : !slot.freedom || slotTimeIsPast(slot.dateTime)}"
                    >
                        <input type="radio" name="times" :id="`slot-`+key" :value="slot.verbose" :checked="isCheckedSlot(slot)">
                        <label class="record-for-service-point__item-link" :for="`slot-`+key" @click="setCurrentSlot(slot)">{{ slot.verbose }}</label>
                    </li>
                </ul>

                <div class="record-for-service-point__main-locked" v-if="!hasActiveServiceCenter">
                  <div>
                    <span class="record-for-service-point__main-locked-svg">
                      &uarr;
                    </span>
                    <br/>
                    Выберите сервисный центр, <br/>
                    чтобы увидеть свободное время
                  </div>



                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import TheDatePicker from "./TheDatePicker.vue";
  import {scrollToTarget} from "../../../components/scroll/scroll";

  export default {
    name: "TheServicePoint",
    components: {
      TheDatePicker
    },
    props: {

    },
    data() {
      return {
        now: 0
      }
    },
    methods: {
      isActive(serviceCenter) {
        return serviceCenter.id === this.selectedServiceCenterId;
      },
      setActive(serviceCenter) {
        this.selectedServiceCenterId = serviceCenter.id
      },
      setCurrentSlot(slot) {
        if(slot !== this.selectedSlot) {
          this.$store.commit('schedule/setSelectedSlot', slot);
        }
        scrollToTarget(
            document.querySelector('#record-for-service-contacts'),
            -(document.querySelector('.sub-header').clientHeight)
        );
      },
      isCheckedSlot(slot) {
        if (slot === this.selectedSlot) {
          return true
        }

        return false;
      },
      slotTimeIsPast(slotDate) {
        const [dateValues, timeWithGmt] = slotDate.split('T');
        const [year, month, day] = dateValues.split('-'); // год, месяц, день
        const [time, gmt] = timeWithGmt.split('+'); // время, gmt
        const [hours, minutes, seconds] = time.split(':'); // часы, минуты, секунды
        const date = new Date(+year, month - 1, +day, +hours - 1, +minutes, +seconds).getTime();

        return this.now > date;
      },
      updateNow() {
        this.now = Date.now();
      }
    },
    computed: {
      ...mapState('schedule', {
        'selectedServiceCenterIdStore': 'selectedServiceCenterId',
        'graph': 'graph',
        'selectedSlot': 'selectedSlot'
      }),
      ...mapGetters('schedule', {
        selectedServiceCenter: 'selectedServiceCenter',
        currentSlots: "currentSlots"
      }),
      serviceCenters() {
        return this.graph.serviceCenters
      },
      selectedServiceCenterId: {
        get() {
          return this.selectedServiceCenterIdStore
        },
        set(value) {
          this.$store.commit('schedule/setSelectedServiceCenterId', value)
        }
      },
      hasActiveServiceCenter() {
        return !!this.selectedServiceCenterId;
      },
    },
    watch: {

    },
    mounted() {
      this.updateNow();
      setInterval(this.updateNow.bind(this) , (1000 * 60));
    }
  }
</script>

<style lang="scss">

</style>