import Sidebar from './sidebar.service'

const SidebarAutoWheelService = {
  async fetchPossibleValues(params) {
    return Sidebar.fetchPossibleValues('/catalog/api/auto/wheel/filter-preparation', params);
  },
  async updateProductList(params) {
    return Sidebar.updateProductList('/catalog/api/auto/wheel/update-list', params);
  },
};

export default SidebarAutoWheelService;