<template>
  <div class="sidebar-checkbox-list-field sidebar-filter-field" :class="blockClasses">
    <label class="sidebar-filter-field__label" :class="elementClasses('label')">
      {{ field.label }}
    </label>

    <div :class="elementClasses('content-wrap')">
      <div class="side-filter__more-box"
           :class="contentClasses"
           data-more-box
      >
        <div :class="elementClasses('choices-item')"
             v-for="(choice, index) in field.choices"
        >
          <input type="checkbox"
                 :class="elementClasses('input')"
                 :id="id(choice, index)"
                 :value="choice.key"
                 v-model="valueItem.value"
                 :disabled="choice.disabled"
                 @input="input"
          >
          <label :for="id(choice, index)"
                 :class="elementClasses('label')"
          >
            {{ choice.label }}
          </label>
        </div>
      </div>

      <a href="javascript:void(0);"
         class="link-arrow link-arrow_to-bottom"
         :class="elementClasses('show-more')"
         v-if="field.choices.length > 10"
         @click.capture="expand"
      >
        <span :class="[...elementClasses('show-more-title'), ...elementClasses('show-more-title_show')]">
          Еще
        </span>
        <span :class="[...elementClasses('show-more-title'), ...elementClasses('show-more-title_hide')]">
          Скрыть
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import {fieldMixin} from "./fieldMixin";

export default {
  name: "SidebarCheckboxListField",
  mixins: [
    fieldMixin
  ],
  computed: {
    contentClasses() {
      const classes = this.elementClasses('choices-list');
      if (this.field.choices.length > 10) {
        classes.push('_scrollable');
      } else {
        classes.splice(classes.indexOf('_scrollable'), 1);
      }
      return classes;
    },
  },
  methods: {
    checkValue() {
      if (!this.valueItem.value) {
        this.valueItem.value = [];
      }
    },
    id(choice, index) {
      index = index | 0;
      return `${this.field.key}_${choice.key}_${index}`;
    },
    expand(e) {
      let block;
      if (e.target.tagName === 'SPAN') {
        block = e.target.parentNode.parentNode.querySelector('[data-more-box]');
      } else {
        block = e.target.parentNode.querySelector('[data-more-box]');
      }

      if (block) {
        block.classList.toggle('_open');
      }
    },
    input(e) {
      this.$emit('input', e);
    }
  },
  mounted() {
    this.checkValue();
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">

</style>