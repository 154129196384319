<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12.9492" y="1.63599" width="2" height="16" transform="rotate(45 12.9492 1.63599)" fill="#B6BCCA"/>
        <rect x="1.63574" y="3.05029" width="2" height="16" transform="rotate(-45 1.63574 3.05029)" fill="#B6BCCA"/>
    </svg>
</template>

<script>
export default {
  name: "SmallCloserIcon"
}
</script>