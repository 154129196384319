<template>
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#B6BCCA" stroke-width="2" d="M10 1h5v5h-5zM10 10h5v5h-5zM1 1h5v5H1zM1 10h5v5H1z"/>
    </svg>
</template>

<script>
    export default {
        name: "SortGridIcon"
    }
</script>