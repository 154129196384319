<template>
  <div class="checkbox-list-mobile" :class="fieldClasses" v-if="field.choices.length">
    <div class="checkbox-list-mobile__heading" v-if="field.label || field.help">
      <div v-if="field.label"
             class="checkbox-list-mobile__label text__small text__small_medium"
             :class="elementClasses('label')"
      >
        {{ field.label }}
      </div>

      <span class="checkbox-list-mobile__help"
            :class="elementClasses('link-help')"
            v-if="field.help"
      >
        <span class="checkbox-list-mobile__help-icon">
<!--          <QuestionBackground/>-->
        </span>
        <span class="checkbox-list-mobile__help-description">
          {{ field.help }}
        </span>
      </span>

      <a href="javascript:void(0);"
         title="Показать все"
         class="checkbox-list-mobile__show-all"
         :class="showAllLinkClasses"
         data-toggle-link
         :data-selector="`[data-checkbox-mobile-modal='${field.key}']`"
         data-toggle="_visible"
         v-if="isShowAllVisible"
         @click="toggleModalOpened"
      >

        <span class="checkbox-list-mobile__show-all-icon"
              :class="elementClasses('show-all-icon')"
        ></span>
        <span class="checkbox-list-mobile__show-all-title"
              :class="elementClasses('show-all-title')"
        >
          Показать все ({{ choices.length }})
        </span>
      </a>
    </div>

    <div class="checkbox-list-mobile__search" v-if="isSearchable">
      <input type="text"
             class="checkbox-list-mobile__search-input"
             placeholder="Найти"
             v-model="searchValue">
      <div class="checkbox-list-mobile__search-icon">
<!--        <Search/>-->
      </div>
    </div>

    <div class="checkbox-list-mobile__content"
         :class="elementClasses('content')"
         v-if="valueItem.value"
    >
      <div class="checkbox-list-mobile__values" :class="elementClasses('values')">
        <div class="checkbox-list-mobile__value"
             :class="elementClasses('value')"
             v-for="(choice, index) in choices"
             :data-value="field.key"
        >
          <input
              type="checkbox"
              :id="id(choice, index)"
              :value="choice.key"
              class="checkbox-list-mobile__input"
              :class="elementClasses('input')"
              v-model="valueItem.value"
          >
          <label :for="id(choice, index)"
                 class="checkbox-list-mobile__item-label"
                 :class="elementClasses('item-label')"
          >
            {{ choice.label }}
          </label>
        </div>
      </div>
    </div>

    <div class="checkbox-list-mobile__modal"
         :class="elementClasses('modal')"
         :data-checkbox-mobile-modal="field.key"
    >
      <div class="checkbox-list-mobile__modal-heading">
        <a href="javascript:void(0);"
           title="Скрыть"
           class="checkbox-list-mobile__close"
           :class="elementClasses('close')"
           data-toggle-link
           :data-selector="`[data-checkbox-mobile-modal='${field.key}']`"
           data-toggle="_visible"
           @click="toggleModalOpened"
        >
<!--          <ArrowLeft/>-->
        </a>

        <div class="checkbox-list-mobile__modal-heading-title">
          {{ field.label }}
        </div>
      </div>

      <div class="checkbox-list-mobile__search checkbox-list-mobile__search_modal"
           v-if="isSearchable"
      >
        <input type="text"
               class="checkbox-list-mobile__search-input"
               placeholder="Найти"
               v-model="searchValue">
        <div class="checkbox-list-mobile__search-icon">
<!--          <Search/>-->
        </div>
      </div>

      <div class="checkbox-list-mobile__modal-values" :class="elementClasses('modal-values')">
        <div class="checkbox-list-mobile__modal-value"
             :class="elementClasses('modal-value')"
             v-for="(choice, index) in choices"
        >
          <input
              type="checkbox"
              :id="id(choice, index)"
              :value="choice.key"
              class="checkbox-list-mobile__modal-input"
              :class="elementClasses('modal-input')"
              v-model="valueItem.value"
          >
          <label :for="id(choice, index)"
                 class="checkbox-list-mobile__modal-item-label"
                 :class="elementClasses('modal-item-label')"
          >
            <span class="checkbox-list-mobile__choice-icon"
                  :class="elementClasses('choice-icon')"
            >
<!--              <CheckMarkBlack/>-->
            </span>
            <span class="checkbox-list-mobile__choice-name"
                  :class="elementClasses('choice-name')"
            >
              {{ choice.label }}
            </span>
          </label>
        </div>
      </div>

      <div class="checkbox-list-mobile__submit-wrap">
        <a href="javascript:void(0);"
           title="Показать товары"
           class="checkbox-list-mobile__submit button button_primary"
           data-toggle-link
           :data-selector="`[data-checkbox-mobile-modal='${field.key}']`"
           data-toggle="_visible"
           @click="toggleModalOpened"
        >
          <span class="button__wrapper">
              Выбрать
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  // import ArrowLeft from "../../components/icons/ArrowLeft";
  // import CheckMarkBlack from "../../components/icons/CheckMarkBlack";
  // import QuestionBackground from "../../components/icons/QuestionBackground";
  // import Search from "../../components/icons/Search";

  export default {
    name: "CheckboxListMobile",
    // components: {Search, CheckMarkBlack, ArrowLeft, QuestionBackground},
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        values: [],
        searchValue: '',
        modalOpened: false,
      }
    },
    computed: {
      fieldClasses() {
        const classes = this.blockClasses;
        if (!this.field.label && !this.field.help) {
          classes.push('checkbox-list-mobile_unlabeled');
        }
        return classes;
      },
      choices() {
        let choices = JSON.parse(JSON.stringify(this.field.choices));
        if (this.searchValue) {
          choices = choices.filter((choice) => choice.key.includes(this.searchValue));
        }
        const orderedChoices = this.field.data.orderedChoices;
        if(!this.modalOpened && orderedChoices && orderedChoices.length) {
          choices.sort((a, b) => {
            if (!orderedChoices.includes(a.key) || !orderedChoices.includes(b.key)) {
              return;
            }
            return orderedChoices.indexOf(a.key) - orderedChoices.indexOf(b.key);
          });
        }
        return choices;
      },
      showAllLinkClasses() {
        const classes = this.elementClasses('show-all');
        if (this.choices.length > 4) {
          classes.push('_visible')
        }
        return classes;
      },
      isShowAllVisible() {
        if (!this.values.length) {
          return;
        }

        const valuesContainer = this.values[0].parentNode;
        const valuesContainerBottom = valuesContainer.offsetTop + valuesContainer.clientHeight;

        return Array.from(this.values).some((value) => value.offsetTop > valuesContainerBottom);
      },
      isSearchable() {
        return this.field.data['searchable'] ? this.field.data['searchable'] : false;
      },
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      toggleModalOpened() {
        this.modalOpened = !this.modalOpened;
      }
    },
    mounted() {
      this.checkValue();
      setTimeout(() => {
        this.values = document.querySelectorAll(`[data-value=${this.field.key}]`);
      }, 500);
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>