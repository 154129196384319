import topFilter from "./topFilter.service";

const topFilterAutoTyreService = {
  async fetchPossibleValues(params) {
    return topFilter.fetchPossibleValues('/catalog/api/auto/tyre/filter-preparation', params);
  },
  async searchProducts(params) {
    return topFilter.searchProducts('/catalog/api/auto/tyre/search', params);
  },
};

export default topFilterAutoTyreService;
