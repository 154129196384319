const AutoMultiSidebarModule = {
  namespaced: true,
  state: {

  },

  getters: {
    currentType: (state, getters, rootState) => rootState.typeSwitcherModule.current,

    isTyres: (state, getters) => getters.currentType === 'tires',

    isWheels: (state, getters) => getters.currentType === 'rims',
  },
  actions: {
    // Инициализация вызывается из фильтра по размерам
    async init({getters, dispatch}) {
      // Помечаем оба сайдбара как не загруженные
      await dispatch('unload');

      // Инициализируем сайдбар шин или дисков, в зависимости от того, что сейчас в переключателе
      if (getters.isTyres) {
        await dispatch('initTyres');
      } else if (getters.isWheels) {
        await dispatch('initWheels');
      }
    },

    async changeTypeEvent({ getters, rootState, dispatch, commit}) {
      await dispatch('init');
    },

    async changeSizesEventHandler({ getters, dispatch }) {
      if (getters.isTyres) {
        await dispatch('sidebarAutoTyreModule/filterValues', false, {root: true});
      } else if (getters.isWheels) {
        await dispatch('sidebarAutoWheelModule/filterValues', false, {root: true});
      }
    },

    async initTyres({dispatch}) {
      await dispatch('sidebarAutoTyreModule/initializationFilter', null, {root: true});
    },

    async initWheels({dispatch}) {
      await dispatch('sidebarAutoWheelModule/initializationFilter', null, {root: true});
    },

    updateProductList({ getters, dispatch }) {
      if (getters.isTyres) {
        dispatch('sidebarAutoTyreModule/updateProductList', false, {root: true});
      } else if (getters.isWheels) {
        dispatch('sidebarAutoWheelModule/updateProductList', false, {root: true});
      }
    },

    async unload({ dispatch }) {
      await dispatch('sidebarAutoTyreModule/setLoaded', false, {root: true});
      await dispatch('sidebarAutoWheelModule/setLoaded', false, {root: true});
    }
  }

};

export default AutoMultiSidebarModule;