import {tns} from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';
import sliderCounter from "./sliderCounter";

const smallBreakpoint = 375;
const mediumBreakpoint = 768;
const largeBreakpoint = 1280;

const hideArrows = (leftArrow, rightArrow) => {
    if (leftArrow && !leftArrow.classList.contains('_hide')) {
        leftArrow.classList.add('_hide');
    }
    if (rightArrow && !rightArrow.classList.contains('_hide')) {
        rightArrow.classList.add('_hide');
    }
}

const showArrows = (leftArrow, rightArrow) => {
    if (leftArrow && leftArrow.classList.contains('_hide')) {
        leftArrow.classList.remove('_hide');
    }
    if (rightArrow && rightArrow.classList.contains('_hide')) {
        rightArrow.classList.remove('_hide');
    }
}

const arrowsVisibleChecker = (slideCount, leftArrow, rightArrow, smallCount, mediumCount, largeCount) => {
    let width = document.body.clientWidth;

    showArrows(leftArrow, rightArrow);

    if (width < mediumBreakpoint) {
        if (slideCount <= smallCount) {
            hideArrows(leftArrow, rightArrow);
        }
    } else if (width >= mediumBreakpoint && width < largeBreakpoint) {
        if (slideCount <= mediumCount) {
            hideArrows(leftArrow, rightArrow);
        }
    } else if (width > largeBreakpoint) {
        if (slideCount <= largeCount) {
            hideArrows(leftArrow, rightArrow);
        }
    }
}

const checkCountItems = (slider, leftArrow, rightArrow, smallCount, mediumCount, largeCount) => {
    if (!slider) {
        return;
    }
    const slideCount = slider.getInfo().slideCount;

    arrowsVisibleChecker(slideCount, leftArrow, rightArrow, smallCount, mediumCount, largeCount)

    window.addEventListener('resize', () => {
        arrowsVisibleChecker(slideCount, leftArrow, rightArrow, smallCount, mediumCount, largeCount)
    })
}

/**
 * @param left
 * @param right
 * @param slider
 * @param target - Элемент в котором будет осуществляться поиск стрелок
 * @param smallCount
 * @param mediumCount
 * @param largeCount
 */
function bindArrows(left, right, slider, target = document, smallCount = 0, mediumCount = 3, largeCount = 5) {
    const leftArrow = target.querySelector(left);
    const rightArrow = target.querySelector(right);

    if (leftArrow) {
        leftArrow.addEventListener('click', () => {
            slider.goTo('prev');
        });
    }

    if (rightArrow) {
        rightArrow.addEventListener('click', () => {
            slider.goTo('next');
        });
    }

    checkCountItems(slider, leftArrow, rightArrow, smallCount, mediumCount, largeCount);
}


if (document.querySelector('[data-article-slider]')) {
    const articleSlider = tns({
        container: '[data-article-slider]',
        items: 4,
        slideBy: 1,
        nav: false,
        speed: 700,
        autoplayButtonOutput: false,
        controls: false,
        loop: false,
        swipeAngle: true,
        rewind: false,
        responsive: {
            0: {
                disable: true
            },
            375: {
                disable: true
            },
            768: {
                disable: true,
            },
            1280: {
                disable: false,
            },
        }
    });

    bindArrows('[data-article-left]', '[data-article-right]', articleSlider);
}

if (document.querySelector('[data-about-slider]')) {
    const sliderAbout = tns({
        container: '[data-about-slider]',
        items: 1,
        slideBy: 1,
        nav: false,
        speed: 700,
        autoplay: true,
        autoplayButtonOutput: false,
        controls: false,
        loop: true,
        swipeAngle: true,
        rewind: false,
    });

    bindArrows('[data-arrow-left]', '[data-arrow-right]', sliderAbout, document, 100, 100, 1);
}

if (document.querySelector('[data-offer-slider-container]')) {
    const offerSliderContainer = document.querySelector('[data-offer-slider-container]');
    const slider = tns({
        container: offerSliderContainer.querySelector('[data-offer-slider]'),
        items: 1,
        slideBy: 1,
        nav: false,
        speed: 700,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayButtonOutput: false,
        controls: false,
        loop: true,
        slideIndex: '[data-current-index]',
        slideCount: '[data-current-count]',
        swipeAngle: true,
        rewind: false,
        preventScrollOnTouch: 'auto'
    });

    bindArrows('[data-offer-left]', '[data-offer-right]', slider, document, 100, 100, 1);

    const leftArrow = document.querySelector('[data-offer-left]');
    const rightArrow = document.querySelector('[data-offer-right]');
    showArrows(leftArrow, rightArrow);

    sliderCounter(
        offerSliderContainer,
        '[data-current-index]',
        '[data-current-count]',
        slider,
    );
}
if (document.querySelector('[data-product-slider]')) {
    const slider = tns({
        container: '[data-product-slider]',
        items: 1,
        slideBy: 1,
        nav: true,
        navPosition: "bottom",
        speed: 700,
        // autoplay: true,
        autoplayButtonOutput: false,
        controls: false,
        loop: true,
        swipeAngle: true,
        rewind: false,
        mouseDrag: true
    });
}

if (document.querySelector('[data-service-slider]')) {
    const serviceSlider = tns({
        container: '[data-service-slider]',
        items: 4,
        slideBy: 1,
        nav: false,
        speed: 700,
        autoplayButtonOutput: false,
        controls: false,
        loop: true,
        swipeAngle: true,
        rewind: false,
        responsive: {
            0: {
                disable: true
            },
            375: {
                disable: true
            },
            768: {
                items: 3,
                disable: false,
                gutter: 24
            },
            1280: {
                items: 4,
                gutter: 28,
            },
        }
    });

    bindArrows('[data-services-left]', '[data-services-right]', serviceSlider, document, 0, 3, 4);
}

const producersSliders = document.querySelectorAll('[data-producers-slider]');
if (producersSliders) {
    producersSliders.forEach((slider) => {
        const producersSlider = tns({
            container: slider,
            slideBy: 4,
            nav: false,
            speed: 700,
            autoplay: true,
            // Да 10 секунд
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            controls: false,
            loop: true,
            swipeAngle: true,
            rewind: false,
            responsive: {
                0: {
                    disable: true
                },
                375: {
                    disable: true
                },
                768: {
                    items: 3,
                    disable: false,
                },
                1280: {
                    items: 4,
                },
            }
        });

        const target = slider.closest('[data-slider-wrapper]');
        bindArrows('[data-producers-left]', '[data-producers-right]', producersSlider, target, 0, 3, 4);
    })
}

if (document.querySelector('[data-popular-slider]') && window.innerWidth < 1280) {
    const popularSlider = tns({
        container: '[data-popular-slider]',
        items: 4,
        slideBy: 1,
        nav: false,
        speed: 700,
        autoplayButtonOutput: false,
        controls: false,
        loop: true,
        swipeAngle: true,
        rewind: false,
        responsive: {
            0: {
                disable: true,
            },
            375: {
                disable: true,
            },
            768: {
                items: 3,
                disable: false,
                gutter: 24
            },
            1280: {
                disable: true,
            },
        }
    });
    bindArrows('[data-popular-left]', '[data-popular-right]', popularSlider);
}

if (document.querySelector('[data-action-slider]')) {
    const actionSlider = tns({
        container: '[data-action-slider]',
        items: 4,
        slideBy: 1,
        nav: false,
        speed: 700,
        autoplayButtonOutput: false,
        controls: false,
        loop: true,
        swipeAngle: true,
        rewind: false,
        responsive: {
            0: {
                disable: true
            },
            375: {
                disable: true
            },
            768: {
                disable: true,
            },
            1280: {
                disable: false,
            },
        }
    });

    bindArrows('[data-action-left]', '[data-action-right]', actionSlider, document, 0, 3, 4);
}


/**
 * - Другие модели в этом типоразмере
 */
window.addEventListener('load', () => {
    const otherProductsSliders = document.querySelectorAll('[data-other-products-slider]');
    if (otherProductsSliders) {
        otherProductsSliders.forEach((productSliderElement) => {
            const productSlider = tns({
                container: productSliderElement,
                items: 5,
                slideBy: 1,
                nav: false,
                speed: 700,
                autoplayButtonOutput: false,
                controls: false,
                loop: true,
                swipeAngle: true,
                rewind: false,
                responsive: {
                    0: {
                        disable: true
                    },
                    768: {
                        items: 3,
                        disable: false
                    },
                    1280: {
                        items: 5
                    },
                }
            });

            const target = productSliderElement.closest('[data-slider-wrapper]');

            bindArrows('[data-arrow-left]', '[data-arrow-right]', productSlider, target);
        })
    }
})
