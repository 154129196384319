<template>
  <div>
    <input
        :id="fieldId"
        type="text"
        :disabled="disabled"
        @input="change"
    >
    <label v-if="label" :for="fieldId">
      {{ label }}
    </label>

  </div>
</template>

<script>
export default {
  name: "BaseFieldInput",
  props: {
    // Уникальный id input
    fieldId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
  },
  methods: {
    change(event) {
      // При изменении инпута передаем событие 'input', что бы связанный объект по v-mode принял изменения
      this.$emit('input', event.target.value);
    }
  }
}
</script>

<style scoped>

</style>