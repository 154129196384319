<template>
  <TheStepsBaseContent class-name="steps-services-list">
    <template v-slot:title>
      Выберите услугу
    </template>

    <template v-slot:content>
      <ul class="steps-base-content__items">
        <li class="steps-base-content__item"
            :class="{ _active: isActive(service) }"
            v-for="service in services"
            :key="`#service-` + service.id"
        >
          <a :href="`#service-` + service.id"
             class="steps-base-content__item-link"
             @click="setActive(service)">
            {{ service.name }}
          </a>
        </li>
      </ul>
    </template>
  </TheStepsBaseContent>
</template>

<script>
import {mapActions, mapState} from "vuex";
import TheStepsBaseContent from "./TheStepsBaseContent.vue";

export default {
  name: "TheStepsServicesList",
  components: {TheStepsBaseContent},
  methods: {
    ...mapActions('schedule', {
      'setActive': 'setActiveService'
    }),
    isActive(service) {
      return this.activeService === service;
    },
    setActive(service) {
      if(service !== this.activeService) {
        this.$store.dispatch('schedule/setActiveService', service);
      }
    },
  },
  computed: {
    ...mapState('schedule', [
      'services',
      'activeService'
    ])
  }
}
</script>

<style lang="scss">

</style>