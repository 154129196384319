import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import TheCartWidget from './components/cart/TheCartWidget.vue';
import TheCartPage from './components/cart/TheCartPage.vue';

import TheFavoritesWidget from "./components/favorites/TheFavoritesWidget";
import TheFavoritesPage from "./components/favorites/TheFavoritesPage";

import TheFilterMainTyreAuto from './components/catalog/filters/main-filters/TheFilterMainTyreAuto';
import TheFilterMainTyreTruck from './components/catalog/filters/main-filters/TheFilterMainTyreTruck';
import TheFilterMainTyreMoto from './components/catalog/filters/main-filters/TheFilterMainTyreMoto';
import TheFilterMainWheelAuto from './components/catalog/filters/main-filters/TheFilterMainWheelAuto';
import TheFilterMainWheelTruck from './components/catalog/filters/main-filters/TheFilterMainWheelTruck';
import TheParametersCheckbox from './components/catalog/filters/main-filters/TheParametersCheckbox';

import TheFilterSidebarTyreAuto from './components/catalog/filters/sidebars/TheFilterSidebarTyreAuto';
import TheFilterSidebarTyreTruck from './components/catalog/filters/sidebars/TheFilterSidebarTyreTruck';
import TheFilterSidebarTyreMoto from './components/catalog/filters/sidebars/TheFilterSidebarTyreMoto';
import TheFilterSidebarWheelAuto from './components/catalog/filters/sidebars/TheFilterSidebarWheelAuto';
import TheFilterSidebarWheelTruck from './components/catalog/filters/sidebars/TheFilterSidebarWheelTruck';

import TheFilterPickCar from './components/catalog/filters/car-pickup/TheFilterCarPick';
import TheTypeSwitcher from "./components/catalog/filters/car-pickup/TheTypeSwitcher";
import TheSizesFilter from "./components/catalog/filters/car-pickup/TheSizesFilter";
import TheAutoMultiSidebarFilter from "./components/catalog/filters/car-pickup/TheAutoMultiSidebarFilter";

import TheScheduleUserService from "./components/ScheduleUserService/TheScheduleUserService";

import { PiniaVuePlugin, createPinia } from 'pinia';
import { initFilters } from "./filter/init";

import store from './store';
import TheFavoritesHeading from "./components/favorites/TheFavoritesHeading";
import Notifications from "./components/Notification/Notifications";

import ThePriceCompetitor from './components/PriceCompetitor/ThePriceCompetitor.vue';
import TheFilterTyreSort from "./components/catalog/filters/sort/TheFilterTyreSort";
import TheFilterWheelSort from "./components/catalog/filters/sort/TheFilterWheelSort";
import TheStepsScheduleUserService from "./components/ScheduleUserService/TheStepsScheduleUserService.vue";

// TODO: отключить после откладки в дев режиме
Vue.config.devtools = true;

Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);

export const pinia = createPinia();
initFilters();

Vue.filter('price', (value) => {
  if (!value && value !== 0) return '';
  value = parseInt(value, 10);
  return value.toString().split(/(?=(?:...)*$)/).join(' ');
});

// Два типа рутов для корзины - счетчик вверху и сама страница корзины
if (document.querySelector('#cart-widget')) {
  new Vue({
    store,
    el: '#cart-widget',
    render: h => h(TheCartWidget),
  });
}

if (document.querySelector('#cart-page')) {
  new Vue({
    store,
    el: '#cart-page',
    render: h => h(TheCartPage),
    methods: {
      formatPrice: function(rawValue) {
        const value = parseInt(rawValue, 10);
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      }
    }
  });
}

if (document.querySelector('#notifications')) {
  new Vue({
    store,
    el: '#notifications',
    render: h => h(Notifications),
  });
}

if (document.querySelector('#parameters')) {
  new Vue({
    store,
    el: '#parameters',
    render: h => h(TheParametersCheckbox),
  });
}

document.addEventListener('cartAdd', (e) => {
  store.dispatch('cart/add', e.detail);
});

// Два типа рутов для избранного - счетчик вверху и сама страница избранного
if (document.querySelector('#favorites-widget')) {
  new Vue({
    store,
    el: '#favorites-widget',
    render: h => h(TheFavoritesWidget),
  });
}
if (document.querySelector('#favorites-heading')) {
  new Vue({
    store,
    el: '#favorites-heading',
    render: h => h(TheFavoritesHeading),
  });
}
if (document.querySelector('#favorites-page')) {
  new Vue({
    store,
    el: '#favorites-page',
    render: h => h(TheFavoritesPage),
  });
}

document.addEventListener('favoritesAdd', (e) => {
  store.dispatch('favorites/add', e.detail);
});

document.addEventListener( 'favoritesRemove', (e) => {
  store.dispatch('favorites/remove', e.detail);
});
// Добавление товара в корзину осуществляется вот так
/*
document.dispatchEvent(new CustomEvent('cartAdd', {
  detail: {
    buyable: '123',
    id: 1,
    quantity: 4
  }
}));
 */


// ФИЛЬТРЫ В КАТАЛОГЕ

// По автомобилю
if (document.querySelector('#selection-by-car-filter')) {
  new Vue({
    store,
    el: '#selection-by-car-filter',
    render: h => h(TheFilterPickCar),
  });
}

// По параметрам
// if (document.querySelector('#auto-tyre-param-filter')) {
//   new Vue({
//     store,
//     el: '#auto-tyre-param-filter',
//     render: h => h(TheAutoTyreParamFilter),
//   });
// }
if (document.querySelector('#auto-tyre-param-filter')) {
  new Vue({
    store,
    el: '#auto-tyre-param-filter',
    render: h => h(TheFilterMainTyreAuto),
  });
}


if (document.querySelector('#auto-wheel-param-filter')) {
  new Vue({
    store,
    el: '#auto-wheel-param-filter',
    render: h => h(TheFilterMainWheelAuto),
  });
}

if (document.querySelector('#truck-tyre-param-filter')) {
  new Vue({
    store,
    el: '#truck-tyre-param-filter',
    render: h => h(TheFilterMainTyreTruck),
  });
}

if (document.querySelector('#moto-tyre-param-filter')) {
  new Vue({
    store,
    el: '#moto-tyre-param-filter',
    render: h => h(TheFilterMainTyreMoto),
  });
}

if (document.querySelector('#truck-wheel-param-filter')) {
  new Vue({
    store,
    el: '#truck-wheel-param-filter',
    render: h => h(TheFilterMainWheelTruck),
  });
}

// Сайдбары
if (document.querySelector('#auto-tyre-sidebar-filter')) {
  const vm = new Vue({
    store,
    el: '#auto-tyre-sidebar-filter',
    render: h => h(TheFilterSidebarTyreAuto),
  });
}


if (document.querySelector('#auto-wheel-sidebar-filter')) {
  new Vue({
    store,
    el: '#auto-wheel-sidebar-filter',
    render: h => h(TheFilterSidebarWheelAuto),
  });
}

if (document.querySelector('#truck-tyre-sidebar-filter')) {
  new Vue({
    store,
    el: '#truck-tyre-sidebar-filter',
    render: h => h(TheFilterSidebarTyreTruck),
  });
}

if (document.querySelector('#moto-tyre-sidebar-filter')) {
  new Vue({
    store,
    el: '#moto-tyre-sidebar-filter',
    render: h => h(TheFilterSidebarTyreMoto),
  });
}

if (document.querySelector('#truck-wheel-sidebar-filter')) {
  new Vue({
    store,
    el: '#truck-wheel-sidebar-filter',
    render: h => h(TheFilterSidebarWheelTruck),
  });
}

// Сортировка

if (document.querySelector('#auto-tyre-sort')) {
  const vm = new Vue({
    store,
    el: '#auto-tyre-sort',
    render: h => h(TheFilterTyreSort),
  });
}

if (document.querySelector('#auto-wheel-sort')) {
  const vm = new Vue({
    store,
    el: '#auto-wheel-sort',
    render: h => h(TheFilterWheelSort),
  });
}

// Переключалка типов

document.addEventListener('CatalogMainReplaced', (e) => {
  if (document.querySelector('#types-filter-switcher')) {
    const vm = new Vue({
      store,
      el: '#types-filter-switcher',
      render: h => h(TheTypeSwitcher),
    });
  }

  if (document.querySelector('#sizes-filter')) {
    const vm = new Vue({
      store,
      el: '#sizes-filter',
      render: h => h(TheSizesFilter),
    });
  }

  if (document.querySelector('#auto-multi-sidebar-filter')) {
    const vm = new Vue({
      store,
      el: '#auto-multi-sidebar-filter',
      render: h => h(TheAutoMultiSidebarFilter),
    });
  }
});

// Запись на сервис
const recordServiceEl = document.querySelector('#schedule-user-service');
if (recordServiceEl) {
  const currentId = parseInt(recordServiceEl.dataset.currentId, 10);
  new Vue({
    store,
    el: '#schedule-user-service',
    render: h => h(TheStepsScheduleUserService, {
      props: {
        currentId: currentId ? currentId : null
      }
    }),
  });
}

if (document.querySelector('#price-competitor')) {
  new Vue({
    store,
    el: '#price-competitor',
    render: h => h(ThePriceCompetitor),
  });
}