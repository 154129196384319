import PolicyFilePathService from "../../services/policyFilePath.service";

const policyFilePathModule = {
  namespaced: true,
  state: {
    policyFilePath: null,
  },
  getters: {
    getPolicyFilePath(state) {
      return state.policyFilePath;
    }
  },
  actions: {
    async fetch({ commit }) {
      try {
        const policyFilePath = await PolicyFilePathService.fetch();
        commit('setPolicyFilePath', policyFilePath.data);
      } catch (e) {
        console.log(e);
      }
    }
  },
  mutations: {
    setPolicyFilePath(state, value) {
      state.policyFilePath = value;
    },
    reset(state) {
      Object.assign(state, {
        policyFilePath: null,
      });
    }
  },
};

export default policyFilePathModule;