import axios from 'axios';

const ScheduleService = {
  async getServices() {
    return axios.get('/api/schedule/services')
  },

  async getCarTypes() {
    return axios.get('/api/schedule/car-types')
  },

  async getUserGraph(serviceId, date) {
    const url = this.buildUrlWithGetParams('/api/schedule/user-graph', {
      'serviceId': serviceId,
      'date': date
    });

    return axios.get(url);
  },

  async getDisableDates(serviceId, date) {
    const url = this.buildUrlWithGetParams('/api/schedule/disable-dates', {
      'serviceId': serviceId,
      'date': date
    });

    return axios.get(url);
  },

  async saveRecord(data) {
    return axios.post('/api/schedule/record/save', data);
  },

  buildUrlWithGetParams(link, params) {
    let paramsString = '';

    for (const param in params) {
      if(params.hasOwnProperty(param)) {
        paramsString += (paramsString === '') ? "?" : "&";
        paramsString += `${param}=${params[param]}`;
      }
    }

    return link + paramsString;
  }
};

export default ScheduleService;