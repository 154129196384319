<template>
  <div class="cart">
    <Notifications/>
    <div class="cart__container" v-if="(checkout && checkout.items && checkout.items.length > 0)">
      <div class="cart__wrapper">
        <!--<div class="cart__come-back">
            <TheCartComeBack/>
        </div>-->
        <div class="cart__products" data-start-tracking>
          <ul class="cart__products-list" v-if="checkout">
            <li class="cart__products-item" v-for="item in checkout.items">
              <CartPageItem
                  @increase="increase"
                  @decrease="decrease"
                  @setQuantity="setQuantity"
                  @remove="remove"
                  :item="item"
              />
            </li>
          </ul>
        </div>
        <div class="cart__result">
          <TheCartResult
              :productCounts="count"
              :total="total"
          />
        </div>
        <div class="cart__contacts-info">
          <TheCartContacts/>
        </div>
        <div class="cart__payment">
          <TheCartPay/>
        </div>
        <div class="cart__receiving">
          <TheCartReceiving/>
        </div>
        <div class="cart__order-comment">
          <TheCartOrderComment/>
        </div>
        <div class="cart__confirm" data-end-tracking>
          <TheCartConfirm/>
        </div>
      </div>
      <div class="cart__result-mini" data-result-mini>
        <TheCartResultMini
            :productCounts="count"
            :total="total"
        />
      </div>
    </div>
    <div class="cart__empty" v-else>
      <div class="cart__empty-text">
        Ваша корзина пуста
      </div>
      <a href="/catalog/auto/tyre" class="cart__empty-button button">
        Перейти в каталог
      </a>
    </div>
  </div>

  <!--<div class="cart">
      {{ checkout }}

      <div class="cart__container">
          <div class="cart__wrapper">
              <div class="cart__products">
                  <ul class="cart__products-list" v-if="checkout">
                      <li class="cart__products-item" v-for="item in checkout.items">
                          <CartPageItem
                              @increase="increase"
                              @decrease="decrease"
                              @setQuantity="setQuantity"
                              @remove="remove"
                              :item="item"
                          />
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>-->
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import CartPageItem from './CartPageItem.vue';
import TheCartResult from "./TheCartResult.vue";
import TheCartComeBack from "./TheCartComeBack.vue";
import TheCartContacts from "./TheCartContacts.vue";
import TheCartResultMini from "./TheCartResultMini";
import TheCartConfirm from "./TheCartConfirm";
import Notifications from "../Notification/Notifications";
import TheCartOrderComment from "./TheCartOrderComment";
import TheCartPay from "./TheCartPay";
import TheCartReceiving from "./TheCartReceiving";

export default {
  name: "TheCartPage",
  components: {
    TheCartReceiving,
    TheCartPay,
    TheCartOrderComment,
    Notifications,
    TheCartConfirm,
    TheCartResultMini,
    TheCartContacts,
    TheCartComeBack,
    TheCartResult,
    CartPageItem
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout',
      'increase': 'cart/increase',
      'decrease': 'cart/decrease',
      'remove': 'cart/remove',
      'setQuantity': 'cart/setQuantity',
    })
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout'
    }),
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'count'
    })
  },
  mounted() {
    this.loadCheckout();
  }
}

</script>

<style lang="scss">

</style>