<template>
    <a href="/favorites" title="Перейти в избранное" class="personal-area__link">
        <span class="personal-area__icon">
            <FavoritesIcon/>
            <span class="personal-area__counter" v-if="count > 0">
                {{ count }}
            </span>
        </span>
        <span class="personal-area__name">
            Избранное
        </span>
    </a>
</template>

<script>
    import FavoritesIcon from "../icons/FavoritesIcon";
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    export default {
        name: "TheFavoritesWidget",
        components: {
            FavoritesIcon
        },
        methods: {
            ...mapActions({
                'loadCheckout': 'favorites/loadCheckout'
            })
        },
        computed: {
            ...mapGetters('favorites', {
                'count': 'count'
            })
        },
        mounted() {
            this.loadCheckout();
        }
    }
</script>

<style lang="scss">

</style>