const PriceSliderModule = {
  namespaced: true,
  state: {
    min: 0,
    max: 999999,
    data: {
      value: [0, 1]
    },
    sidebarName: null
  },
  getters: {
    getMin: (state) => state.min,
    getMax: (state) => state.max,
    getValueFrom: (state) => state.data.value[0],
    getValueTo: (state) => state.data.value[1]
  },
  actions: {
    async setMinMax({state, commit}, values) {
      let { min, max } = values;
      const value = [
        state.data.value[0],
        state.data.value[1],
      ];

      if (value[0] < min || value[0] === 0) {
        value[0] = min;
      }
      if (value[1] > max || value[1] === 1) {
        value[1] = max;
      }
      if (min === max && min === 0 && max === 0) {
        max++;
      }
      if (value[0] === value[1]) {
        value[1]++;
      }

      commit('setValues', value);
      commit('setMinMax', {min, max});
    },
    async filter({ dispatch, state, rootState }) {
      if (state.sidebarName) {
        dispatch(`${state.sidebarName}/filterValues`, {}, {root: true});
      }
    }
  },
  mutations: {
    setValues: (state, value) => {
      state.data.value = value;
    },
    setMinMax: (state, minMax) => {
      console.log(minMax);
      state.min = minMax.min;
      state.max = minMax.max;
    },
    setSidebarName: (state, name) => {
      state.sidebarName = name;
    }
  }
};

export default PriceSliderModule;