<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="5" width="20" height="2" fill="#B6BCCA"/>
        <rect x="4" y="10" width="14" height="2" fill="#B6BCCA"/>
        <rect x="7" y="15" width="8" height="2" fill="#B6BCCA"/>
    </svg>
</template>

<script>
    export default {
        name: "SortSelectIcon"
    }
</script>