<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="17.5059" y="3.36401" width="2" height="20" transform="rotate(45 17.5059 3.36401)" fill="#B6BCCA"/>
        <rect x="3.36426" y="4.77808" width="2" height="20" transform="rotate(-45 3.36426 4.77808)" fill="#B6BCCA"/>
    </svg>
</template>

<script>
export default {
  name: "CartCloserIcon"
}
</script>