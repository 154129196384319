import SidebarMotoTyreService from '../../../services/sidebar/sidebarMotoTyre.service';
import Sidebar from './sidebar.module';
import filterFieldClass from "../../../classes/filterFieldClass";

const SidebarMotoTyreModule = Object.assign({}, Sidebar);

const fields = {
  studdeds: new filterFieldClass({
    label: 'Шипованность',
    type: 'bool-checkboxes',
    requestFieldName: 'studded',
  }),
  types: new filterFieldClass({
    label: 'Тип',
    type: 'checkboxes',
    requestFieldName: 'types'
  }),
  producers: new filterFieldClass({
    label: 'Производители',
    type: 'slug-checkboxes',
    requestFieldName: 'producers',
  }),
};

const state = {
  service: SidebarMotoTyreService,
  name: 'sidebarMotoTyreModule',
  topFilterModuleName: 'topParamMotoTyreModule',
  paramInfo: fields
};

SidebarMotoTyreModule.state = {...SidebarMotoTyreModule.state, ...state};

export default SidebarMotoTyreModule;