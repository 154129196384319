<template>
  <div class="cart-receiving-pickup">
    <ul class="cart-receiving-pickup__radio-list">
      <li v-for="(point) in points" class="cart-receiving-pickup__radio-item">
        <div class="cart-receiving-pickup__radio">
          <input class="cart-receiving-pickup__input" type="radio"
                 v-model.number="points" name="points" v-bind:id="'point-' + point.id" :value="point.id">
          <label v-bind:for="'point-' + point.id" class="cart-receiving-pickup__label">
            <span class="cart-receiving-pickup__name-container">
                <span class="cart-receiving-pickup__name-wrapper">
                    <span class="cart-receiving-pickup__name">
                      {{ point.name }}
                    </span>
                </span>
                <span class="cart-receiving-pickup__description">
                  {{ point.schedule }}
                </span>
            </span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors";
import { TheMask } from "vue-the-mask";
import {mapActions} from 'vuex';

export default {
  name: "TheCartComponentPickup",
  components: {ItemErrors, TheMask},
  props: {},
  computed: {
    points: {
      get () {
        return this.$store.state.pickupPoints.points
      },
      set (value) {
        this.$store.commit('order/SetPickupPoint', value)
      }
    }
  },
  methods: {
    ...mapActions('pickupPoints', {
      'fetch': 'fetch'
    })
  },
  mounted() {
    this.fetch();
  }
}
</script>

<style lang="scss">

</style>