document.querySelectorAll('[data-copy-on-click]').forEach((el) => {
    el.addEventListener('click', (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(el.innerText)
            .then(() => {
                console.log('Text copied to clipboard');
                el.classList.add('_copied');
                setTimeout(() => {
                    el.classList.remove('_copied');
                }, 1000);
            })
            .catch(err => {
                console.error('Error in copying text: ', err);
            });
    });
})

