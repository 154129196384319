<template>
    <component
        :is="this.fieldComponent"
        :filterKey="filterKey"
        :field="field"
        @input="input"
    />
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useFilterStore } from "../../store/modules/filter/filter";

export default {
  name: "Field",
  props: {
    fieldKey: String,
    filterKey: String
  },
  computed: {
    ...mapState(useFilterStore, ['findField']),
    field() {
      return this.findField(
        this.filterKey,
        this.fieldKey
      );
    },
    fieldComponent() {
      return this.field.type + `Field`;
    }
  },
  methods: {
    input(e) {
      this.$emit('input', e);
    }
  }
}
</script>

<style lang="scss">

</style>