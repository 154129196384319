<template>
    <div class="cart-order-comment">
        <label for="comment" class="cart-order-comment__label">
            <span class="cart-order-comment__title">
                Комментарий к заказу
            </span>
            <span class="cart-order-comment__description">
                На что стоит обратить внимание?
            </span>
        </label>
        <textarea name="comment" v-model="comment" id="comment" cols="30" rows="10" class="cart-order-comment__comment" placeholder="Комментарий..."></textarea>
    </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';

  export default {
    name: "TheCartOrderComment",
    computed: {
      comment: {
        get() {
          return this.$store.state.order.comment
        },
        set (value) {
          this.$store.commit('order/setComment', value)
        }
      },
    }
  }

</script>

<style lang="scss">

</style>