<template>
  <div class="sort-field">
    <div class="sort-field__icon">
      <SortIcon/>
    </div>
    <select :id="fieldId"
            @change="change"
            :value="currentValue"
    >
      <option v-for="value in values"
              :value="valueAsString(value.value)">
        {{ value.name }}
      </option>
    </select>
  </div>
</template>

<script>

import SortIcon from "../../../icons/SortIcon";
export default {
  name: "BaseFieldSort",
  components: {
    SortIcon

  },
  data: () => {
    return {
      up: false,
      down: false,
    };
  },
  props: {
    // Название группы радио кнопок
    fieldId: {
      type: String,
      required: true
    },
    currentValue: {
      type: String,
      required: false
    },
    values: {
      type: Array,
      required: true
    },
  },
  methods: {
    valueAsString(item) {
      // TODO позже нормально будет)
      if (item.slug !== undefined) {
        return item.slug;
      }
      if (item.id !== undefined) {
        return item.id;
      }
      if (item.value !== undefined) {
        return item.value;
      }

      return item;
    },
    change(e) {
      console.log(e.target.value);
      this.$emit('change', e.target.value);
    }
  },
}
</script>
