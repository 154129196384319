import DefaultFieldset from "./fieldset/DefaultFieldset.vue";
import DefaultField from "./field/DefaultField.vue";
import RangeField from "./field/RangeField.vue";
import DropdownField from "./field/DropdownField.vue";
import CheckboxListField from "./field/CheckboxListField.vue";
import SelectMultiField from "./field/SelectMultiField.vue";
import EnumCheckboxListField from "./field/EnumCheckboxListField.vue";
// import CheckboxListOpenableField from "./field/CheckboxListOpenableField.vue";
// import RangeOpenableField from "./field/RangeOpenableField";
// import CheckboxBlockListOpenableField from "./field/CheckboxBlockListOpenableField";
import CheckboxListMobileField from "./field/CheckboxListMobileField";
// import ChosenFieldset from "./fieldset/ChosenFieldset";
import SortField from "./field/SortField";
// import TopFieldset from "./fieldset/TopFieldset";
import SidebarFieldset from "./fieldset/SidebarFieldset";
import ChosenField from "./field/ChosenField";
import SelectField from "./field/SelectField";
import SidebarCheckboxListField from "./field/SidebarCheckboxListField.vue";

export const map = {
  // Fieldset
  DefaultFieldset,
  // ChosenFieldset,
  // TopFieldset,
  SidebarFieldset,

  // Field
  DefaultField,
  RangeField,
  // RangeOpenableField,
  CheckboxListField,
  // CheckboxListOpenableField,
  // CheckboxBlockListOpenableField,
  CheckboxListMobileField,
  EnumCheckboxListField,
  DropdownField,
  SortField,
  SelectField,
  SelectMultiField,
  ChosenField,
  SidebarCheckboxListField
};