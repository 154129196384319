import SidebarTruckWheelService from '../../../services/sidebar/sidebarTruckWheel.service';
import Sidebar from './sidebar.module';
import filterFieldClass from "../../../classes/filterFieldClass";

const SidebarTruckWheelModule = Object.assign({}, Sidebar);

const fields = {
  widths: new filterFieldClass({
    label: 'Ширина',
    type: 'checkboxes',
    requestFieldName: 'widths',
  }),
  producers: new filterFieldClass({
    label: 'Производители',
    type: 'slug-checkboxes',
    requestFieldName: 'producers',
  }),
};

const state = {
  service: SidebarTruckWheelService,
  name: 'sidebarTruckWheelModule',
  topFilterModuleName: 'topParamTruckWheelModule',
  paramInfo: fields
};

SidebarTruckWheelModule.state = {...SidebarTruckWheelModule.state, ...state};

export default SidebarTruckWheelModule;